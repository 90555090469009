import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './requestForProposal.css';
import { Article, Circle, MoreVert, Download, Assignment, DescriptionOutlined } from '@mui/icons-material';
import { Card, Button, Menu, MenuItem, IconButton } from '@mui/material';
import { dateForHumans, previewPaper, subString } from '../../utils';
import ApplyProposal from '../apply_proposal/ApplyProposal';


const RequestForProposal = ({ requestForProposal, openRfq, handleOpenRfq, handleCloseRfq }) => {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const [openApplyRfp, setOpenApplyRfp] = useState(false);
    const [selectedProposal, setSelectedProposal] = useState({});

    const handleClickOpenApplyRfp = () => {
        setOpenApplyRfp(true);
        setSelectedProposal(requestForProposal);
        handleClose();
    }

    const handleCloseApplyRfp = () => {
        setOpenApplyRfp(false);
    };


    return (
        <>
            <Card className='prequalification br-10'>
                <div className='row'>
                    <div className='col-sm-4 d-flex justify-content-start'>
                        <div className='preq-icon-wrapper'>
                            <DescriptionOutlined className='preq-icon' />
                        </div>
                        <div className='preq-text-wrapper'>
                            <h6 className='fs-13'>{requestForProposal.tenderNo}</h6>
                            
                            <p  onClick={() => previewPaper()} className='fs-12 doc'>{subString(requestForProposal.tenderDescription, 50)} </p>
                          
                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <div className='status d-flex justify-content-start'>
                            <Circle className={`status-icon ${requestForProposal.eligibility == 'Open' ? 'text-green' : 'text-purple'}`} />
                            <p className='fs-12 px-1'>{requestForProposal.eligibility}</p>
                        </div>

                    </div>
                    <div className='col-sm-1'>
                        <h6 className='fs-12 dates'>  </h6>
                    </div>
                    <div className='col-sm-2'>
                        <h6 className='fs-12 dates'>{dateForHumans(requestForProposal.documentDate)} at  {requestForProposal.tenderClosingTime} </h6>
                        {/* <h6 className='fs-12 dates'>{dateForHumans(tender.tenderClosingDate)}  at  {tender.tenderClosingTime}</h6> */}
                    </div>
                    <div className='col-sm-2'>
                        <Button
                            sx={{ p: 1, textTransform: 'capitalize', borderRadius: '.75rem', borderColor: '#e7e7e7' }}
                            className="fs-12" variant="outlined" color="inherit"
                            onClick={handleOpenRfq}
                        >
                           View More
                        </Button>

                        <IconButton
                            aria-label="more"
                            id="demo-positioned-button"
                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            sx={{ ml: 3 }}
                        >
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            className="br-10"
                        >

                                <MenuItem 
                                // onClick={()=> {handleClose(); previewPaper()}} 
                                onClick={()=> handleClose()} 
                                sx={{ minWidth: '10rem' }}>
                                    <Download sx={{ mr: 1 }} />Download Proposal</MenuItem>
                            <MenuItem onClick={handleClickOpenApplyRfp} sx={{ minWidth: '10rem' }}><Assignment sx={{ mr: 1 }} />Apply Proposal</MenuItem>
                        </Menu>

                    </div>
                </div>
            </Card>
            <ApplyProposal openProposal={openApplyRfp} handleOpenProposal={handleClickOpenApplyRfp} handleCloseProposal={handleCloseApplyRfp} requestForProposal={selectedProposal} handleClose={handleClose} handleCloseRfq={handleCloseRfq} />
        </>
    )
}

export default RequestForProposal