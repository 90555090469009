import axios from "axios"
import {newDate} from "../../utils";
 
const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;

// getRfp
  const getRfp = async () => {
    const response = await axios.get(`${API_URL}/supplier/fetchRfp.action`, {
      headers: {
        'Accept': 'application/json',
        }
    })
    // const result = newDate(response.data.jsonData);
    const result = response.data.jsonData;
    console.info('Check Rfp',result);
    response.data.jsonData = [];
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : result;
}

const getOpenRfp = async () => {
  const response = await axios.get(`${API_URL}/supplier/fetchRfp.action`, {
      headers: {
          'Accept': 'application/json',
      }
  })

  const data = response.data.jsonData.filter(res => res.eligibility === 'Open');
  return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : data
}


// saveRfpAttachment
const saveRfpAttachment =async(userDataProfile)=>{
  const config={
    headers: {
      'Accept': 'application/json',}
  }
  // console.log("userDataProfile",userDataProfile)
  const response=await axios.post(`${API_URL}/supplier/saveSupplierProfileAttachment.action`,userDataProfile,config)
  return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : response.data;
}

const getMyRfps = async (usrId) => {
  const response = await axios.get(`${API_URL}/supplier/fetchAllAppliedPrequalificationsList.action?supplierId=${usrId}&type=${2}`)
  const result = response.data.data.result;
  response.data.jsonData = [];
  return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : result;
}

// save rfpApplication
const saveRfpApplication = async (prequalification) => {
  const response = await  axios.post(`${API_URL}/supplier/saveRfpApplication.action`, prequalification, {
      headers: {
          'Accept': 'application/json',
      }
  })
  return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}



const rfpService={
  getRfp,
  saveRfpAttachment,
  getMyRfps,
  saveRfpApplication,
  getOpenRfp

}
export default rfpService