import React, { useEffect, useState } from "react";
import "./PurchaseQuote.css";
import { Card, CardContent, Box, Modal, Typography } from "@mui/material";
import { CssBaseline, Divider, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { purple, blue } from "@mui/material/colors";

import { useLocation } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import PurchaseHeader from "./Header/PurchaseHeader";
import PurchaseLines from "./Lines/PurchaseLines";
import { useDispatch, useSelector } from "react-redux";
import Attachments from "./Attachments/Attachments";
import {
  getPurchaseLines,
  savePurchaseLines,
} from "../../features/purchase/Lines/ViewPurchaseLinesSlice";
import { toast, ToastContainer } from "react-toastify";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import AttachemntsTable from "./Attachments/AttachemntsTable";
import { getSupplierAttachments } from "../../features/preqs/preqSlice";

const PurchaseQuote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { viewPurchaseLines, isLoading, isError, isSuccess, message } =
    useSelector((state) => state.viewPurchaseLines);

    const {
        isProcessing,
        suppPreqDocs,
        isLoadingAttachments,
      } = useSelector((state) => state.preq);
 
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [double, setDouble] = useState(false);

  const location = useLocation();
  let id = location.pathname.split("/")[2];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    elevation: 2,
    border: "0px",
    boxShadow: 30,
    p: 4,
  };

  // console.log(id, '---------ID ----- ')
  const theme = createTheme({
    palette: {
      primary: {
        main: blue[700],
      },
      secondary: {
        main: purple[700],
      },
    },
  });

  const handleSubmit = async (event) => {
    let error = event.preventDefault();
    const formdata1 = new FormData();
    formdata1.append("quoteNo", id);

    dispatch(savePurchaseLines(formdata1));

    if (error) {
      notNotify();
      setDouble(false);
    } else {
      notify();
      setDouble(true);
    }

    handleClose();

    dispatch(getPurchaseLines(id));

    navigate(`/purchase-quotes`);
  };

  const notify = () => {
    toast.success("Purchase Quote Saved!", {
      theme: "colored",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notNotify = () => {
    toast.error("Purchase Quote not Saved!", {
      theme: "colored",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    // dispatch(getPurchaseGeneral(id))
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getSupplierAttachments({
        no: id,
        type: 4,
      })
    );
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            pt: 1,
            borderTop: "3px solid #1976d2",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Card
            sx={{
              fontWeight: "bold",
              my: 0,
              mx: 2,
              boxShadow: 1,
              p: 2,
              pt: 0,
              borderRadius: "5px",
            }}
          >
            <CardContent>
              <PurchaseHeader id={id} />
            </CardContent>

            <Divider sx={{ my: 3 }} />

            <CardContent>
              <PurchaseLines id={id} />
            </CardContent>

            <Divider sx={{ my: 3 }} />

            <CardContent>
              <AttachemntsTable 
              id={id}
               isProcessin={isProcessing}
               suppPreqDocs={suppPreqDocs}
               isLoadingAttachments={isLoadingAttachments}
               />
            </CardContent>

            <Divider sx={{ my: 3 }} />
            <CardContent>
              <Attachments 
              id={id} 
              suppPreqDocs={suppPreqDocs} 
              isProcessing={isProcessing}
              isLoadingAttachments={isLoadingAttachments}
              />
            </CardContent>

            <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
              <Button sx={{ mt: 3, ml: 1, textTransform: "Capitalize" }}>
                <Link to={"/purchase-quotes"}>Back</Link>
              </Button>

              <Button
                variant="contained"
                onClick={handleOpen}
                sx={{ mt: 3, ml: 1, textTransform: "Capitalize" }}
                disabled={double}
              >
                Submit
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    Do you wish to submit your purchase quote?
                  </Typography>
                  <Box
                    id="modal-modal-description"
                    sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      sx={{ mt: 3, ml: 1, textTransform: "Capitalize" }}
                      onClick={handleClose}
                    >
                      No
                    </Button>
                    <Button
                      sx={{ mt: 3, ml: 1, textTransform: "Capitalize" }}
                      onClick={handleSubmit}
                      variant="contained"
                    >
                      <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                      Yes
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Card>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default PurchaseQuote;
