import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import axios from "axios"
import rfpService from './rfpService';



const initialState = {
    requestForProposals: [],
    filteredRfps: [],
    rfpApplication: {},
    openRfp: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    isSuccessSave: false,
    isErrorSave: false,
    isLoadingSave: false,
    isSuccessRfpApplication: false,
    isErrorRfpApplication: false,
    isLoadingRfpApplication: false,
    isSuccessOpenRfp: false,
    isErrorOpenRfp: false,
    isLoadingOpenRfp: false,
    message: '',
    suppRfpDocs: [],
    myRfps: [],
    isCleanupFormRfp: false,
}


// get Rfp
export const getRfp = createAsyncThunk('rfps/general', async (_, thunkApi) => {
    try {
        return await rfpService.getRfp()

    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

// saveRfpAttachment
export const saveRfpAttachment = createAsyncThunk('rfps/save', async (userDataProfile, thunkAPI) => {
    try {
        return await rfpService.saveRfpAttachment(userDataProfile)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

// get Rfp
export const getMyRfps = createAsyncThunk('rfps/mine', async (_, thunkApi) => {
    try {

        const usrId = thunkApi.getState().auth.user.usrId;
        return await rfpService.getMyRfps(usrId)

    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

// save rfpApplication
export const saveRfpApplication = createAsyncThunk(
    'rfpApplication/save',
    async (prequalification, thunkAPI) => {
        try {
            return await rfpService.saveRfpApplication(prequalification)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

// get Open Rfp
export const getOpenRfp = createAsyncThunk(
    'openRfp/getAll', async (
        _, thunkApi) => {
    try {
        // const token = thunkApi.getState().auth.user.token;
        return await rfpService.getOpenRfp()

    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})




export const rfpSlice = createSlice({
    name: 'rfp',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.isSuccessSave = false
            state.isErrorSave = false
            state.isLoadingSave = false
            state.isSuccessRfpApplication = false
            state.isErrorRfpApplication = false
            state.isLoadingRfpApplication = false
            state.isSuccessOpenRfp = false
            state.isErrorOpenRfp = false
            state.isLoadingOpenRfp = false
            state.message = ''
            state.isCleanupFormRfp = false
        },
        setFilteredRfps: (state, action) => {
            state.filteredRfps = action.payload
        },
        cleanupFormRfp: (state) => {
            state.requestForProposals = initialState.requestForProposals
            state.filteredRfps = initialState.filteredRfps
            state.rfpApplication = initialState.rfpApplication
            state.openRfp = initialState.openRfp
            state.suppRfpDocs = initialState.suppRfpDocs
            state.myRfps = initialState.myRfps
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRfp.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getRfp.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.requestForProposals = action.payload
                state.filteredRfps = action.payload

            })
            .addCase(getRfp.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(saveRfpAttachment.pending, (state) => {
                state.isLoadingSave = true;
            })
            .addCase(saveRfpAttachment.fulfilled, (state, action) => {
                state.isLoadingSave = false;
                state.isSuccessSave = true;
                state.suppRfpDocs = action.payload;
            })
            .addCase(saveRfpAttachment.rejected, (state) => {
                state.isLoadingSave = false
                state.isErrorSave = true

            })

            .addCase(getMyRfps.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMyRfps.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.myRfps = action.payload

            })
            .addCase(getMyRfps.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.myRfps = []
                state.message = action.payload
            })

            .addCase(
                saveRfpApplication.pending, (state) => {
                    state.isLoadingRfpApplication = true
                }
            )
            .addCase(
                saveRfpApplication.fulfilled, (state, action) => {
                    state.isLoadingRfpApplication = false
                    state.isSuccessRfpApplication = true
                    state.message = "Rfp Application Saved successfully"
                    state.rfpApplication = action.payload
                }
            )
            .addCase(
                saveRfpApplication.rejected, (state, action) => {
                    state.isLoadingRfpApplication = false
                    state.isErrorRfpApplication = true
                    state.message = action.payload
                }
            )

            .addCase(getOpenRfp.pending, (state) => {
                state.isLoadingOpenRfp = true
            })
            .addCase(getOpenRfp.fulfilled, (state, action) => {
                state.isLoadingOpenRfp = false
                state.isSuccessOpenRfp = true
                state.openRfp = action.payload
            })
            .addCase(getOpenRfp.rejected, (state, action) => {
                state.isLoadingOpenRfp = false
                state.isErrorOpenRfp = true
                state.message = action.payload
            })




    }
})

export const { reset, setFilteredRfps, cleanupFormRfp } = rfpSlice.actions
export default rfpSlice.reducer