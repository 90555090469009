import axios from 'axios';

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;

// get prequalifications
const getPrequalifications = async () => {
    const response = await axios.get(`${API_URL}/supplier/fetchTenderPrequalifications.action`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}

// save Prequalification
const savePrequalification = async (prequalification) => {
    const response = await  axios.post(`${API_URL}/supplier/saveTenderPrequalification.action`, prequalification, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}

// get supplier profile
const getSupplierProfile = async (usrId) => {
    const response = await axios.get(`${API_URL}/supplier/fetchSupplierProfileInfo.action?usrId=${usrId}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
   
    const response1 = {
        "usrpId": 1,
        "usrpCompanyName": "Phil-It productions Ltd",
        "usrpContactPerson": "Philip Karanja",
        "usrpMobileNumber": "0792882785",
        "usrpPhysicalAddress": "Blue-Post, Thika",
        "usrpPostalAddress": "15466 Nakuru",
        "usrpEmail": "philkaranja@gmail.com",
        "usrpWebsite": "www.philitproductions.co.ke",
        "usrpLegalStatus": "Legally Authorised",
        "usrpMaxBusinessValue": 10,
        "usrpBusinessNature": "Film Productions",
        "usrpKraPinCertNo": "A1016378388G",
        "usrpVatCertNo": "VAT190101GO",
        "usrpCompanyRegCertNo": "COMP62323KE",
        "usrpBankersName": "K-REP Ban",
        "usrpTradeTerms": "Trade only with accredited business men.",
        "usrpCountry": "Kenya",
        "usrpCounty": "Nairobi",
        "usrpDateCreated": 1618317223039,
        "usrpDateUpdated": null,
        "usrpUsrId": 1849
    }

    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result[0];
}

const getSupplierAttachments = async ({no, type}) => {
    const response = await axios.get(`${API_URL}/supplier/fetchSupplierProfileAttachment.action?no=${no}&type=${type}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}

const saveSupplierAttachment = async (attachment) => {
    const response = await  axios.post(`${API_URL}/supplier/saveSupplierProfileAttachment.action`, attachment, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}

const getBankers = async (no) => {
    const response = await axios.get(`${API_URL}/supplier/fetchTenderBankInfoList.action?no=${no}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}

const saveBanker = async (banker) => {
    const response = await  axios.post(`${API_URL}/supplier/saveTenderBankInfo.action`, banker, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return response.data.jsonData;
}

const getDirectors = async (no) => {
    const response = await axios.get(`${API_URL}/supplier/fetchDirectorsProfileList.action?no=${no}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}

const saveDirector = async (director) => {
    const response = await  axios.post(`${API_URL}/supplier/createDirectorsProfile.action`, director, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}

const getQuestions = async (no, ans) => {
    const response = await axios.get(`${API_URL}/supplier/fetchTenderQuestions.action?no=${no}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    if (!response.data.success) {
        return Promise.reject(response.data.messages.mainMessage);
    }
    return {
        questions: response.data.data.result,
        answers: ans
    }
}

const getLitigationQuestions = async (no, ans) => {
    const response = await axios.get(`${API_URL}/supplier/fetchTenderQuestions.action?no=${no}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    if (!response.data.success) {
        return Promise.reject(response.data.messages.mainMessage);
    }
    return {
        questions: response.data.data.result,
        answers: ans
    }
}


const saveQuestionAnswer = async (answer) => {
    const response = await  axios.post(`${API_URL}/supplier/createTenderAnswer.action`, answer, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}

const fetchTenderAnswers = async ({no,questionId}) => {
    const response = await axios.get(`${API_URL}/supplier/fetchTenderAnswers.action?no=SR-APP0002&questionId=1`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}

const getPersonnelList = async (no) => {
    const response = await axios.get(`${API_URL}/supplier/fetchTenderPersonnel.action?applicationNo=${no}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}

const savePersonnel = async (personnel) => {
    const response = await  axios.post(`${API_URL}/supplier/createTenderPersonnel.action`, personnel, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}

const getExperiences = async (no) => {
    const response = await axios.get(`${API_URL}/supplier/fetchTenderExperience.action?no=${no}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}

const saveExperience = async (experience) => {
    const response = await  axios.post(`${API_URL}/supplier/createTenderExperience.action`, experience, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}

const getFinancialInfoList = async (no) => {
    const response = await axios.get(`${API_URL}/supplier/fetchTenderFinancialCapability.action?no=${no}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}

const saveFinancialInfo = async (finacialInfo) => {
    const response = await  axios.post(`${API_URL}/supplier/createTenderFinancialCapability.action`, finacialInfo, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}

const getLitigationHistory = async (no) => {
    const response = await axios.get(`${API_URL}/supplier/fetchTenderLitigation.action?no=${no}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}

const saveLitigationHistory = async (litigation) => {
    const response = await  axios.post(`${API_URL}/supplier/createTenderLitigation.action`, litigation, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}

const getMyPrequalifications = async (usrId) => {
    const response = await axios.get(`${API_URL}/supplier/fetchAllAppliedPrequalificationsList.action?supplierId=${usrId}&type=${0}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}

// getApplicationRequiredDocuments
const getApplicationRequiredDocuments = async ({documentNo}) => {
    const response = await axios.get(`${API_URL}/supplier/fetchApplicationRequiredDocuments.action?documentNo=${documentNo}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}

// deleteSupplierProfileAttachment
const deleteSupplierProfileAttachment = async ({entryNo}) => {
    const response = await axios.delete(`${API_URL}/supplier/deleteSupplierProfileAttachment.action?entryNo=${entryNo}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}

// deleteRegDetails
const deleteRegDetails = async ({lineNo,type}) => {
    const response = await axios.delete(`${API_URL}/supplier/deleteRegistrationDetails.action?lineNo=${lineNo}&type=${type}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.data.result;
}


const preqService = { 
    getPrequalifications,
    savePrequalification,
    getSupplierProfile,
    getSupplierAttachments,
    saveSupplierAttachment,
    getBankers,
    saveBanker,
    getDirectors,
    saveDirector,
    getQuestions,
    getLitigationQuestions,
    saveQuestionAnswer,
    fetchTenderAnswers,
    getPersonnelList,
    savePersonnel,
    getExperiences,
    saveExperience,
    getFinancialInfoList,
    saveFinancialInfo,
    getLitigationHistory,
    saveLitigationHistory,
    getMyPrequalifications,
    getApplicationRequiredDocuments,
    deleteSupplierProfileAttachment,
    deleteRegDetails

}

export default preqService;