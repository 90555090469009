import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleFailure } from "../../utils";

import ViewPurchaseService from "./ViewPurchaseService";

const initialState = {
  viewPurchase: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  isCleanupFormPurchaseHeader: false,
  quoteDetails: {},
};

//get View Purchase
export const getViewPurchase = createAsyncThunk(
  "view_purchase/getAll",
  async (header_id, thunkApi) => {
    try {
      // const token = thunkApi.getState().auth.user.token;
      return await ViewPurchaseService.getViewPurchase(header_id);
    } catch (error) {
      const message = handleFailure(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const ViewPurchaseSlice = createSlice({
  name: "view_purchase",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false
      state.isLoading = false
      state.isSuccess = false
      state.message = ''
      state.isCleanupFormPurchaseHeader = false
    },
    setQuoteDetails: (state, action) => {
      state.quoteDetails = action.payload;
    },
    cleanupFormPurchaseHeader: (state) => {
      state.viewPurchase = initialState.viewPurchase;
      state.quoteDetails = initialState.quoteDetails;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getViewPurchase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getViewPurchase.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.viewPurchase = action.payload;
      })
      .addCase(getViewPurchase.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { 
    reset, 
    setQuoteDetails,
    cleanupFormPurchaseHeader
 } =
  ViewPurchaseSlice.actions;
export default ViewPurchaseSlice.reducer;
