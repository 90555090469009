import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import authService from './authService';
// get user from localstorge
const user =JSON.parse(localStorage.getItem('user'))
const initialState={
    user:user ? user:null,
    isError:false,
    isLoading:false,
    isSuccess:false,
    message:'',
    company: {
        usrpCompanyName: '',
        usrpCountry: '',
        usrpCounty: '',
        usrpKraPinCertNo: '',
    },
    userDetails: {
        usrpContactPerson:'',
        usrMobileNumber:'',
        usrEmail:'',
        usrEncryptedPassword:'',
        usrEncryptedPasswordAlt:'',
        usrpUsrId:'',
    },
    isCleanupForm: false,

}

//register
export const register =createAsyncThunk('auth/register',async(user,thunkAPI)=>{
    try{
        return await authService.register(user)
    }catch(error){
        const message = handleFailure(error)  
        return thunkAPI.rejectWithValue(message)
        }
});

// login user 
export const login =createAsyncThunk('auth/login',async(user,thunkAPI)=>{
    try{
        return await authService.login(user)
    }catch(error){
        const message = handleFailure(error)  
        return thunkAPI.rejectWithValue(message)
        }
});
 
// logout
export const logout =createAsyncThunk('auth/logout',async () =>{
    await authService.logout()
})
 
export const authSlice=createSlice({
     name:'auth',
     initialState,
     reducers:{
         reset:(state)=>{
            state.isLoading=false
            state.isError=false
            state.isSuccess=false
            state.message = ''
            state.isCleanupForm = false
         },
         setCompanyDetails:(state, action) => {
            state.company = action.payload
        },
        setUserDetails: (state, action) => {
            state.userDetails = action.payload
        },
        cleanupForm: (state) => {
            state.company = initialState.company
            state.userDetails = initialState.userDetails
        },
    
     },
     extraReducers:(builder)=>{
         builder
        //  login
         .addCase(login.pending, (state)=>{
             state.isLoading=true
         })
         .addCase(login.fulfilled, (state,action) =>{
             state.isLoading=false
             state.isSuccess=true
             state.user=action.payload
         })
         .addCase(login.rejected,(state,action)=>{
             state.isLoading=false
             state.isError=true
             state.message=action.payload
             state.user=null
         })

        //  register
        .addCase(register.pending, (state)=>{
            state.isLoading=true
        })

        .addCase(register.fulfilled, (state,action) =>{
            state.isLoading=false
            state.isSuccess=true
            state.message="Signup sucessfully. You may now login."
            state.user=action.payload
            state.isCleanupForm = true
        })

        .addCase(register.rejected,(state,action)=>{
            state.isLoading=false
            state.isError=true
            state.message=action.payload
            state.user=null
            state.isCleanupForm = false
        })

        .addCase(logout.fulfilled, (state)=>{
            state.user=null
            state.isCleanupForm = true
        })
     }
 })

export const {
    reset, 
    setCompanyDetails, 
    setUserDetails,
    cleanupForm
} = authSlice.actions
export default authSlice.reducer