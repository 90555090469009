import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import Moment from 'moment';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {Article, AssignmentOutlined, Circle} from "@mui/icons-material";
import { Button, Card, Grid, IconButton } from "@mui/material";
import { useNavigate} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./quotationRequest.css";
import {useDispatch, useSelector} from "react-redux";
import {getPurchase,reset} from "../../features/purchase/purchaseSlice";
import {dateForHumans, subString} from "../../utils";
import { setQuoteDetails } from '../../features/purchase/Header/ViewPurchaseSlice';



const QuotationRequest = () => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const {user}=  useSelector((state)=>state.auth)
     
    const documenType = 0;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {purchase,isLoading, isError, isSuccess, message} = useSelector((state)=> state.purchase)

   
    useEffect(() => {
        if (isError === true) {
        }
        dispatch(getPurchase({
            documenType:documenType,
            supplierProfileID: user.usrId, 
        }))

        // return () => {
        //     dispatch(reset())
        // }
    }, [isError, message, dispatch])



    const viewRFQ = (id) => {
        navigate(`/view-rfq/${id}`);
    };
    const viewPurchaseQuote = (id) => {
        navigate(`/view-purchase-quote/${id}`);
    };
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - purchase.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const dates = (value) => {
        let date = Moment(value).format("MMM Do YY");
        return date;
    }

    const number = (id) => {
        return id;
    }
  
    return (
        <>

            <TableContainer sx={{ padding: 2}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    {/*<TableHead sx={{ mb: 1}}>*/}
                    {/*    <h6>Quotations</h6>*/}
                    {/*</TableHead>*/}
                        {isLoading ? (
                            <Skeleton
                                className="my-1"
                                count={7}
                                height={60}
                                inline={false}
                            />
                        ) : (
                            <>
                            {
                                purchase?.length > 0 ? (
                                    <>
                                    <Grid container spacing={2} sx={{ color: '#1976D2' }} >
                                    <Grid item xs={4}><h6 style={{ paddingLeft: '20px' }}>Title </h6> </Grid>
                                    <Grid item xs={2}><h6>Status</h6> </Grid>
                                    <Grid item xs={2}><h6>Document Date</h6></Grid>
                                    <Grid item xs={2}><h6>Order Date</h6></Grid>
                                    <Grid item xs={1}><h6>Quote</h6></Grid>
                                    <Grid item xs={1}><h6>RFQ</h6></Grid>
                                  </Grid>
                                    <TableBody>
                                        {(rowsPerPage > 0
                                                ? purchase?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : purchase
                                        )?.map((quo, index) => {
                                            return (
                                                <TableRow
                                                    key={quo.index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <Card className='prequalification br-10 '>
                                                        <div className='row'>
                                                            <div className='col-sm-4 d-flex justify-content-start'>
                                                                <div className='preq-icon-wrapper'>
                                                                    <AssignmentOutlined className='preq-icon'/>
                                                                </div>
                                                                <div className='preq-text-wrapper'>
                                                                    <h6 className='fs-13'>{quo.no}</h6>
                                                                    <p className='fs-12'>{subString(quo.quoteDesc, 50) }</p>
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-2'>
                                                                <div className='status d-flex justify-content-start'>
                                                                    <Circle className={`status-icon ${quo.quoteStatus === 'Open' ? 'text-green' : 'Released' ? 'text-purple' : 'text-danger'}`} />
                                                                    <p className='fs-12 px-1'>{quo.quoteStatus === 'Approved' ? 'Submitted' : 'Open'}</p>
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-2'>
                                                                <h6 className='fs-12 dates' >
                                                                    <moment format="LL">{dateForHumans(quo.documentDate)}</moment>
                                                                </h6>
                                                            </div>
                                                            <div className='col-sm-2'>
                                                                <h6 className='fs-12 dates'>
                                                                    {dateForHumans(quo.orderDate)}
                                                                </h6>
                                                            </div>
                                                            <div className='col-sm-1' 
                                                            onClick={()=>dispatch(setQuoteDetails(quo))}
                                                            >
                                                                <Button
                                                                    sx={{ p: 1, textTransform: 'capitalize', borderRadius: '.75rem', borderColor: '#e7e7e7', flexDirection: 'row' }}
                                                                    className="fs-12" variant="outlined" color="inherit"
                                                                    onClick={() => viewPurchaseQuote(number(quo.no))}
                                                                    disabled={quo.quoteStatus === 'Approved'}
                                                                >
                                                                    Quote
                                                                </Button>
                                                            </div>
                                                            <div className='col-sm-1'>
                                                                <Button
                                                                    sx={{ p: 1, textTransform: 'capitalize', borderRadius: '.75rem', borderColor: '#e7e7e7' }}
                                                                    className="fs-12" variant="outlined" color="inherit"
                                                                    onClick={() => viewRFQ(number(quo.rfqCode))
                                                                    }
                                                                >
                                                                    RFQ
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </TableRow>
                                            );
                                        })}
        
        
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    </>
                                ):(<h2>No data available</h2>)
                            }
                           </>
                            )
                        }

                    <TableFooter>
                        {
                             purchase?.length > 0 && (
                                <TableRow>
                                {isLoading ? (
                                    <Skeleton
                                        className="my-1"
                                        inline={false}
                                    />
                                ) : (
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                                        colSpan={3}
                                        count={purchase?.length || 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                )
                                }
                            </TableRow>
                             )
                        }                    
                    </TableFooter>
                </Table>
            </TableContainer>
        </>

    )
}

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


export default QuotationRequest



