import React from 'react';
import {
    InputBase,
    styled,
    FormControl, FormHelperText,
    FormLabel,
    NativeSelect,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { orange, purple, blue } from "@mui/material/colors";
import Form from 'react-bootstrap/Form';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const BootstrapSelectNav = ({ name, label, selected, options, handleChange, isLoading }) => {
    const theme = createTheme({
        palette: {
            primary: {
                main: blue[700],
            },
            secondary: {
                main: purple[700],
            },
        },
    });

    const Input = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(0),
            fontSize: '10px !important'
        },
        '& .MuiInputBase-input': {
            borderRadius: 2,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '5px 20px 5px 10px',
            height: '1.5675rem',
            borderRadius: 5,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderColor: '#1976d2',
                // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    }));

    return (
        <>
            <Form.Group className="bootstrap-input mb-1" controlId="formPlaintextPassword">

                {isLoading ? (<Skeleton className="" height={10} width={175} />) : (
                    <Form.Label className="mb-0">
                        {label}
                    </Form.Label>
                )}

                {isLoading ? (<Skeleton height={35} />) : (
                    <Form.Select
                        name={name}
                        onChange={handleChange}
                        value={selected}
                    >
                        {options?.length && (
                            <option value="" disabled>Select an option</option>
                        )}
                        {options?.length && options?.map(option => (

                            <option value={option.documentName} key={option.lineNo} >{option.documentName}</option>
                        ))}
                    </Form.Select>
                )}

            </Form.Group>

        </>
    )
}

export default BootstrapSelectNav