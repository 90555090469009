import { Button, Card, CardContent } from '@mui/material';
import React from 'react'
import SideMenu from '../../container/SideMenu';
import './orders.css';
import { Keyboard } from '@mui/icons-material';

function Orders() {
    return (
        <div className="container mt-5">
            <div className="row">
                <SideMenu />
                <div className="col-lg-8 pb-5">
                    <div className="card">
                        <div className="card-body">
                            <Card className="tender my-2 br-10">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="icon">
                                            <Keyboard className="icon-" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='description'>
                                            Computers
                                        </div>
                                    </div>

                                    <div className='col-sm-2'>
                                        <div className='order'>34VB5540K83</div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <h6 className='dates-tender'>March 21,2022</h6>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='status-tender'>

                                            <Button
                                                sx={{textTransform: 'capitalize', borderRadius: '.70rem', borderColor: '#e7e7e7' }}
                                                variant="contained"
                                            >
                                                In Progress
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='total'>$315.20</div>
                                    </div>
                                </div>
                            </Card>
                            <Card className="tender my-2 br-10">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="icon">
                                            <Keyboard className="icon-" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='description'>
                                            Computers
                                        </div>
                                    </div>

                                    <div className='col-sm-2'>
                                        <div className='order'>34VB5540K83</div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <h6 className='dates-tender'>March 21,2022</h6>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='status-tender'>

                                            <Button
                                                sx={{textTransform: 'capitalize', borderRadius: '.70rem', borderColor: '#e7e7e7' }}
                                                variant="contained" color="success"
                                            >
                                                In Progress
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='total'>$315.20</div>
                                    </div>
                                </div>
                            </Card>
                            <Card className="tender my-2 br-10">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="icon">
                                            <Keyboard className="icon-" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='description'>
                                            Computers
                                        </div>
                                    </div>

                                    <div className='col-sm-2'>
                                        <div className='order'>34VB5540K83</div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <h6 className='dates-tender'>March 21,2022</h6>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='status-tender'>

                                            <Button
                                                sx={{textTransform: 'capitalize', borderRadius: '.70rem', borderColor: '#e7e7e7' }}
                                                variant="contained" color="secondary"
                                            >
                                                In Progress
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='total'>$315.20</div>
                                    </div>
                                </div>
                            </Card>
                            <Card className="tender my-2 br-10">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="icon">
                                            <Keyboard className="icon-" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='description'>
                                            Computers
                                        </div>
                                    </div>

                                    <div className='col-sm-2'>
                                        <div className='order'>34VB5540K83</div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <h6 className='dates-tender'>March 21,2022</h6>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='status-tender'>

                                            <Button
                                                sx={{textTransform: 'capitalize', borderRadius: '.70rem', borderColor: '#e7e7e7' }}
                                                variant="contained" color="error"
                                            >
                                                In Progress
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='total'>$315.20</div>
                                    </div>
                                </div>
                            </Card>
                            <Card className="tender my-2 br-10">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="icon">
                                            <Keyboard className="icon-" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='description'>
                                            Computers
                                        </div>
                                    </div>

                                    <div className='col-sm-2'>
                                        <div className='order'>34VB5540K83</div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <h6 className='dates-tender'>March 21,2022</h6>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='status-tender'>

                                            <Button
                                                sx={{textTransform: 'capitalize', borderRadius: '.70rem', borderColor: '#e7e7e7' }}
                                                variant="contained"
                                            >
                                                In Progress
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='total'>$315.20</div>
                                    </div>
                                </div>
                            </Card>
                            <Card className="tender my-2 br-10">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="icon">
                                            <Keyboard className="icon-" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='description'>
                                            Computers
                                        </div>
                                    </div>

                                    <div className='col-sm-2'>
                                        <div className='order'>34VB5540K83</div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <h6 className='dates-tender'>March 21,2022</h6>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='status-tender'>

                                            <Button
                                                sx={{textTransform: 'capitalize', borderRadius: '.70rem', borderColor: '#e7e7e7' }}
                                                variant="contained"
                                            >
                                                In Progress
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='total'>$315.20</div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        // <div class="container mt-5">
        //     <div class="row">
        //         <SideMenu />
        //         {/* <!-- Orders Table--> */}
        //         <div class="col-lg-8 pb-5">
        //             <div class="card">
        //                 <div class="card-body">

        //                     <div class="d-flex justify-content-end pb-3">
        //                         <div class="form-inline">
        //                             <label class="text-muted mr-3" for="order-sort">Sort Orders</label>
        //                             <select class="form-control" id="order-sort">
        //                                 <option>All</option>
        //                                 <option>Delivered</option>
        //                                 <option>In Progress</option>
        //                                 <option>Delayed</option>
        //                                 <option>Canceled</option>
        //                             </select>
        //                         </div>
        //                     </div>
        //                     <div class="table-responsive">
        //                         <table class="table table-hover mb-0">
        //                             <thead>
        //                                 <tr>
        //                                     <th>Order #</th>
        //                                     <th>Date Purchased</th>
        //                                     <th>Status</th>
        //                                     <th>Total</th>
        //                                 </tr>
        //                             </thead>
        //                             <tbody>
        //                                 <Card>
        //                                 <CardContent>
        //                                 <tr>
        //                                     <td><a class="navi-link" href="#order-details" data-toggle="modal">78A643CD409</a></td>
        //                                     <td>August 08, 2017</td>
        //                                     <td><span class="badge bg-danger m-0">Canceled</span></td>
        //                                     <td><span>$760.50</span></td>
        //                                 </tr>
        //                                 </CardContent>
        //                                 </Card>
        //                                 <tr>
        //                                     <td><a class="navi-link" href="#order-details" data-toggle="modal">34VB5540K83</a></td>
        //                                     <td>July 21, 2017</td>

        //                                     <td><span class="badge bg-info m-0 ">In Progress</span></td>
        //                                     <td>$315.20</td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td><a class="navi-link" href="#order-details" data-toggle="modal">112P45A90V2</a></td>
        //                                     <td>June 15, 2017</td>
        //                                     <td><span class="badge bg-warning m-0">Delayed</span></td>
        //                                     <td>$1,264.00</td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td><a class="navi-link" href="#order-details" data-toggle="modal">28BA67U0981</a></td>
        //                                     <td>May 19, 2017</td>
        //                                     <td><span class="badge bg-success m-0">Delivered</span></td>
        //                                     <td>$198.35</td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td><a class="navi-link" href="#order-details" data-toggle="modal">502TR872W2</a></td>
        //                                     <td>April 04, 2017</td>
        //                                     <td><span class="badge bg-success m-0">Delivered</span></td>
        //                                     <td>$2,133.90</td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td><a class="navi-link" href="#order-details" data-toggle="modal">47H76G09F33</a></td>
        //                                     <td>March 30, 2017</td>
        //                                     <td><span class="badge bg-success m-0">Delivered</span></td>
        //                                     <td>$86.40</td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

    )
}

export default Orders