import React from 'react';
import {Card, CardContent, Typography} from "@mui/material";
import TableLines from "./tableLines"



const FillQuotation = () => {
    return (
        <Card sx={{ minWidth: 275 }}>
            <Typography my={1} padding={1} variant="h6">
                Request for Quotation Lines
            </Typography>
            <CardContent>
                <div style={{ height: 400, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <TableLines />
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}

export default FillQuotation;
