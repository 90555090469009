import React, { useEffect, useRef, useState } from 'react';
import MuiAlert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Divider, Stack, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { Check } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BusinessProfile from "./BusinessProfile";
import Banker from "./Banker"
import PropTypes from "prop-types";
import Directors from './Directors';
import Competence from './Competence';
import Personnel from './Personnel';
import Experience from './Experience';
import Financial from './Financial';
import LitigationHistory from './LitigationHistory';
import Swal from 'sweetalert2';
import './applyPrequalification.css'
import { useDispatch, useSelector } from 'react-redux';
import { preSaveQuestionAnswers, savePrequalification, saveQuestionAnswer } from '../../features/preqs/preqSlice';
import { formDataFromObject } from '../../utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { getProfile, updateProfile } from '../../features/profile/profileSlice';

const steps = ['Business Profile', 'Banker', 'Directors', 'Competence', 'Personnel', 'Experience', 'Financial'];


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const ActiveStepContent = ({ step, loading, submitSupplierCompetency }) => {
    switch (step) {
        case 0:
            return <BusinessProfile step={step} loading={loading} />
        case 1:
            return <Banker />
        case 2:
            return <Directors />
        case 3:
            return <Competence submitSupplierCompetency={submitSupplierCompetency} />
        case 4:
            return <Personnel />
        case 5:
            return <Experience />
        case 6:
            return <Financial />
        // case 7:
        //     return <LitigationHistory />
        default:
            throw new Error('Unknown step');
    }
}


// const ActiveStepContent = ({ step, loading, submitSupplierCompetency }) => {
//     switch (step) {

//         case 0:
//             return <Banker />
//         case 1:
//             return <Directors />
//         case 2:
//             return <Competence submitSupplierCompetency={submitSupplierCompetency} />
//         case 3:
//             return <Personnel />
//         case 4:
//             return <Experience />
//         case 5:
//             return <Financial />
//         case 6:
//             return <LitigationHistory />
//         default:
//             throw new Error('Unknown step');
//     }
// }

const ApplyPrequalification = () => {

    const { appPrequalification, questions, isLoading, isProcessing, isError, message } = useSelector(state => state.preq);
    
    console.log("questionsreceived",questions)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const previousStep = useRef(null);
    const [open, setOpen] = useState(false);
    // const [loading, isLoading] =  useState(true);

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#1976d2',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#1976d2',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#1976d2',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#1976d2',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };

    const { supplierProfile } = useSelector((state) => state.preq)
    const { user } = useSelector((state) => state.auth)
    const { profile } = useSelector((state) => state.profile)

    

    const handleNext = () => {

        setActiveStep(activeStep + 1);
        let prev = previousStep.current;
        let cur = activeStep + 1;


        if (prev == 0 && cur == 1) {
            const newObj = {
                "usrpId": profile?.usrpId,
                "usrpCompanyName": supplierProfile.usrpCompanyName ? supplierProfile.usrpCompanyName :profile?.usrpCompanyName,
                "usrpContactPerson": supplierProfile.usrpContactPerson ? supplierProfile.usrpContactPerson : profile?.usrpContactPerson,
                "usrpMobileNumber": supplierProfile.usrpMobileNumber ? supplierProfile.usrpMobileNumber : profile?.usrpMobileNumber,
                "usrpPhysicalAddress": supplierProfile.usrpPhysicalAddress ? supplierProfile.usrpPhysicalAddress : profile?.usrpPhysicalAddress,
                "usrpPostalAddress": supplierProfile.usrpPostalAddress ?  supplierProfile.usrpPostalAddress : profile?.usrpPostalAddress,
                "usrpEmail": supplierProfile.usrpEmail ?  supplierProfile.usrpEmail : profile?.usrpEmail,
                "usrpWebsite": supplierProfile.usrpWebsite ? supplierProfile.usrpWebsite : profile?.usrpWebsite,
                // "usrpLegalStatus": supplierProfile.usrpLegalStatus ?   supplierProfile.usrpLegalStatus : profile?.usrpLegalStatus,
                "usrpMaxBusinessValue": supplierProfile.usrpMaxBusinessValue ?  supplierProfile.usrpMaxBusinessValue : profile?.usrpMaxBusinessValue,
                "usrpBusinessNature": supplierProfile.usrpBusinessNature ? supplierProfile.usrpBusinessNature : profile?.usrpBusinessNature,
                "usrpKraPinCertNo": supplierProfile.usrpKraPinCertNo ?  supplierProfile.usrpKraPinCertNo : profile?.usrpKraPinCertNo,
                "usrpVatCertNo": supplierProfile.usrpVatCertNo ?  supplierProfile.usrpVatCertNo : profile?.usrpVatCertNo,
                // "usrpCompanyRegCertNo": "B10112927",
                // "usrpBankersName": supplierProfile.usrpBankersName ? supplierProfile.usrpBankersName: profile?.usrpBankersName,
                "usrpTradeTerms": supplierProfile.usrpTradeTerms ? supplierProfile.usrpTradeTerms : profile?.usrpTradeTerms,
                "usrpCountry": supplierProfile.usrpCountry ?  supplierProfile.usrpCountry : profile?.usrpCountry,
                "usrpCounty": supplierProfile.usrpCounty ?  supplierProfile.usrpCounty : profile?.usrpCounty,
                "usrpUsrId": profile?.usrpUsrId
            }
            dispatch(updateProfile(
                formDataFromObject(newObj)

            ));
            console.log('Updating supplier profile');
        } else if (prev == 3 && cur == 4) {
            console.log('Updating supplier competency');
            submitSupplierCompetency();
        } else {

        }

        console.log('prev', prev);
        console.log('cur', cur);
        setOpen(true);
    };

    const handleConfirm = () => {

        Swal.fire({
            title: 'Confirm you wish to submit supplier registration details?',
            text: "You won't be able to revert this!",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                updatePreqApplication();
                notify();
                navigate(`/home`);
            }
        })
    }

    const updatePreqApplication = () => {
        const preq = {
            applicationNo: appPrequalification.applicationNo,
            tenderNo: appPrequalification.tenderNo,
            createdBy: appPrequalification.createdBy,
            updatedBy: appPrequalification.updatedBy,
            status: 'SUBMITTED',
            description: appPrequalification.description,
            supplierProfileID: appPrequalification.supplierProfileID,
        }

        const _preq = formDataFromObject(preq);

        dispatch(savePrequalification(_preq));
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const submitSupplierCompetency = () => {
        let answer = {}
        let tempAnswers = [];

        for (let i = 0; i < questions.length; i++) {
            if (questions[i].value != "") {
                answer = {
                    lineNo: 0,
                    // lineNo: questions[i].lineNo,
                    questionId: questions[i].lineNo,
                    answer: questions[i].value,
                    applicationNo: appPrequalification.applicationNo,
                };

                tempAnswers.unshift(answer)

                let _answer = formDataFromObject(answer);
                dispatch(saveQuestionAnswer(_answer))

            }
        }


        dispatch(preSaveQuestionAnswers({
            quizs: questions,
            ans: tempAnswers
        }))
    }

    const notify = () => {
        toast.success('Supplier registration details submitted successfully', {
            theme: "colored",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        previousStep.current = activeStep;
    }, [activeStep]);
    useEffect(() => {
        dispatch(getProfile())
    }, [dispatch])

    const theme = createTheme(
        {
            components: {
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            marginBottom: '1rem',
                        }
                    }
                }
            }
        }
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container component="main" maxWidth="xl" sx={{ mb: 0 }}>

                <Paper elevation={0}>

                    <Box sx={{ pt: 1, borderTop: '3px solid #1976d2', borderRadius: '5px 5px 0 0' }}>
                        <Typography variant={"p"} sx={{ p: 2.25, fontSize: '16px', }}>
                            Fill all fields to go to next step
                        </Typography>
                        <Divider sx={{ my: 1, color: '#eaeaf0' }} />
                    </Box>

                    <Stack sx={{ width: '100%' }} spacing={8}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />} sx={{ p: 2 }}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>

                    <Paper sx={{ my: { xs: 0, md: 0 }, p: { xs: 2, md: 2 } }}>
                        <React.Fragment>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                            This is a success message!
                                        </Alert>
                                    </Snackbar>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Box className='preq-steps'>
                                        <ActiveStepContent
                                            step={activeStep}
                                            loading={isLoading}
                                            submitSupplierCompetency={submitSupplierCompetency}
                                        />
                                        {/* {getStepContent(activeStep)} */}
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        {activeStep !== 0 && (
                                            <Button onClick={handleBack} sx={{ mt: 3, ml: 1, textTransform: 'Capitalize' }}>
                                                Previous
                                            </Button>
                                        )}

                                        {activeStep === steps.length - 1 ? (

                                            <Button
                                                variant="contained"
                                                onClick={handleConfirm}
                                                sx={{ mt: 3, ml: 1, textTransform: 'Capitalize' }}
                                            >
                                                Confirm
                                            </Button>

                                        ) : (
                                            <Button
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{ mt: 3, ml: 1, textTransform: 'Capitalize' }}
                                            >
                                                Next Step
                                            </Button>
                                        )}



                                    </Box>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Paper>

                </Paper>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </Container>
        </ThemeProvider>
    );
}
export default ApplyPrequalification;

