import { Box, Card, CardContent, Divider, Grid, Paper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './EditPersonalDetails.css'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProfile, reset, setProfile, updateProfile } from '../../../../features/profile/profileSlice';
import Button from '@mui/material/Button';
import { formDataFromObject } from '../../../../utils';
import { toast, ToastContainer } from 'react-toastify';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function EditPersonalDetails() {

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { profile, isLoading, isLoadingUpdate, isError, isErrorUpdate, isSuccess, isSuccessUpdate, message }
        = useSelector((state) => state.profile)


    const [formData1, setFormData1] = useState(profile);
    const [isUpdating, setIsUpdating] = useState(false);

    const { usrpUsrId, usrpId, usrpCompanyName, usrpContactPerson, usrpMobileNumber, usrpPhysicalAddress, usrpPostalAddress,
        usrpEmail, usrpWebsite, usrpLegalStatus, usrpBusinessNature, usrpPinCertNo, usrpKraPinCertNo, usrpVatCertNo, usrpTaxComplianceCertNo,
        usrpCompanyRegCertNo, usrpMaxBusinessValue, usrpBankersName, usrpTradeTerms, usrpCounty, usrpCountry
    } = formData1;

    console.log("formData1", formData1)

    useEffect(() => {
        if (isError) {
            console.log(message)
        }

        dispatch(getProfile())
        setLoading(false);

    }, [navigate, isError, message, dispatch])

    const handleChange = (e) => {
        setFormData1((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
        setIsUpdating(true)
    }
    console.log("isUpdating", isUpdating)
    const notifySuccess = () => toast.success(' Successfully Updated Profile!', {
        theme: "colored",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notifyError = () => toast.error('Not Uploaded profile!', {
        theme: "colored",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const onSubmit = () => {

        let supplierInfo = formDataFromObject(formData1)

        if (isUpdating) {
            dispatch(updateProfile(supplierInfo));
        }
       
    }

    useEffect(() => {
        if (isSuccessUpdate && isUpdating) {
            notifySuccess()
            setIsUpdating(false)
        }
        dispatch(reset());
    }, [isSuccessUpdate, isUpdating])

    return (

        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card className="cardProf br-10">
                        <div className="allProfile" style={{ padding: '13px' }}>
                            <div className="fw-500">
                                Personal Information
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    onClick={onSubmit}
                                    variant="contained"
                                    sx={{ borderRadius: '10px', textTransform: 'none' }}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                        <Divider />

                        <CardContent>

                            <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpEmail"
                                            label="E-mail"
                                            name="usrpEmail"
                                            autoComplete="email"
                                            value={usrpEmail ? usrpEmail : profile.usrpEmail}
                                            onChange={handleChange}
                                            type="email"

                                        />
                                    )}
                                </Grid>

                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpMobileNumber"
                                            label="Telephone No"
                                            name="usrpMobileNumber"
                                            autoComplete="phone"
                                            value={usrpMobileNumber ? usrpMobileNumber : profile.usrpMobileNumber}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>
                            </Grid>

                            <Grid container spacing={2} >
                                <Grid item xs={12} md={12}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpContactPerson"
                                            label="Contact Person"
                                            name="usrpContactPerson"
                                            autoComplete="contact person"
                                            value={usrpContactPerson ? usrpContactPerson : profile.usrpContactPerson}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>





                <Grid item xs={12} md={6}>
                    <Card className="cardProf br-10">
                        <div className="allProfile" style={{ padding: '13px' }}>
                            <div className="fw-500">
                                Company Location Information
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    onClick={onSubmit}
                                    variant="contained"
                                    sx={{ borderRadius: '10px', textTransform: 'none' }}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                        <Divider />

                        <CardContent>

                            <Grid container spacing={2}>
                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpCountry"
                                            label="Country"
                                            name="usrpCountry"
                                            autoComplete="country"
                                            value={usrpCountry ? usrpCountry : profile.usrpCountry}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>
                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpCounty"
                                            label="County"
                                            name="usrpCounty"
                                            autoComplete="county"
                                            value={usrpCounty ? usrpCounty : profile.usrpCounty}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>

                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpPostalAddress"
                                            label="Postal Address"
                                            name="usrpPostalAddress"
                                            autoComplete="Postal Address"
                                            value={usrpPostalAddress ? usrpPostalAddress : profile.usrpPostalAddress}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>
                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpPhysicalAddress"
                                            label="Physical Address"
                                            name="usrpPhysicalAddress"
                                            autoComplete="PhysicalAddress"
                                            value={usrpPhysicalAddress ? usrpPhysicalAddress : profile.usrpPhysicalAddress}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>



                <Grid item xs={12} md={6}>
                    <Card className="cardProf br-10">
                        <div className="allProfile" style={{ padding: '13px' }}>
                            <div className="fw-500">
                                Other Information
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    onClick={onSubmit}
                                    variant="contained"
                                    sx={{ borderRadius: '10px', textTransform: 'none' }}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                        <Divider />

                        <CardContent>

                            <Grid container spacing={2}>


                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpBankersName"
                                            label="Bankers Name"
                                            name="usrpBankersName"
                                            autoComplete="BankersName"
                                            value={usrpBankersName ? usrpBankersName : profile.usrpBankersName}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>

                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpMaxBusinessValue"
                                            label="Maximum value Your Business can hold"
                                            name="usrpMaxBusinessValue"
                                            autoComplete="MaxBusinessValue"
                                            value={usrpMaxBusinessValue ? usrpMaxBusinessValue : profile.usrpMaxBusinessValue}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>

                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpLegalStatus"
                                            label="Legal Status"
                                            name="usrpLegalStatus"
                                            autoComplete="LegalStatus"
                                            value={usrpLegalStatus ? usrpLegalStatus : profile.usrpLegalStatus}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>

                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpWebsite"
                                            label="Website"
                                            name="usrpWebsite"
                                            autoComplete="Website"
                                            value={usrpWebsite ? usrpWebsite : profile.usrpWebsite}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>



                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>


                <Grid item xs={12} md={6}>
                    <Card className="cardProf br-10">
                        <div className="allProfile" style={{ padding: '13px' }}>
                            <div className="fw-500">
                                Company Registration Information
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    onClick={onSubmit}
                                    variant="contained"
                                    sx={{ borderRadius: '10px', textTransform: 'none' }}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                        <Divider />

                        <CardContent>

                            <Grid container spacing={2}>
                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpCompanyName"
                                            label="Company Name"
                                            name="usrpCompanyName"
                                            autoComplete="company name"
                                            value={usrpCompanyName ? usrpCompanyName : profile.usrpCompanyName}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>

                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpCompanyRegCertNo"
                                            label="Company Registration Cert No#"
                                            name="usrpCompanyRegCertNo"
                                            autoComplete="Company Registration Cert No"
                                            value={usrpCompanyRegCertNo ? usrpCompanyRegCertNo : profile.usrpCompanyRegCertNo}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>

                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpVatCertNo"
                                            label="VAT Cert No#"
                                            name="usrpVatCertNo"
                                            autoComplete="Vat PIN Cert No"
                                            value={usrpVatCertNo ? usrpVatCertNo : profile.usrpVatCertNo}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>

                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        // <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                        //     margin="normal"
                                        //     required
                                        //     fullWidth
                                        //     id="usrpPinCertNo"
                                        //     label="KRA PIN Cert No#"
                                        //     name="usrpPinCertNo"
                                        //     autoComplete="KRA PIN Cert No"
                                        //     value={usrpPinCertNo ? usrpPinCertNo : profile.usrpPinCertNo}
                                        //     onChange={handleChange}
                                        //     type="text"

                                        // />
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpKraPinCertNo"
                                            label="KRA PIN Cert No#"
                                            name="usrpKraPinCertNo"
                                            autoComplete="KRA PIN Cert No"
                                            value={usrpKraPinCertNo ? usrpKraPinCertNo : profile.usrpKraPinCertNo}
                                            onChange={handleChange}
                                            type="text"

                                        />

                                    )}

                                </Grid>

                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpBusinessNature"
                                            label="Business Nature"
                                            name="usrpBusinessNature"
                                            autoComplete="BusinessNature"
                                            value={usrpBusinessNature ? usrpBusinessNature : profile.usrpBusinessNature}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>

                                <Grid item xs={6} md={6}>
                                    {loading ? (<Skeleton height={35} />) : (
                                        <TextField style={{ background: 'rgb(250, 250, 250)', borderRadius: '8px' }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="usrpTradeTerms"
                                            label="Trade Terms"
                                            name="usrpTradeTerms"
                                            autoComplete="TradeTerms"
                                            value={usrpTradeTerms ? usrpTradeTerms : profile.usrpTradeTerms}
                                            onChange={handleChange}
                                            type="text"

                                        />
                                    )}

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>

    )
}

export default EditPersonalDetails