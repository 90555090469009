import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../../utils';
import ViewRequestQuotationLinesService from "./ViewRequestQuotationLinesService";


const initialState = {
    viewRequestQuotationLines: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: '',
    isCleanupFormRfqLine: false,
    rfqLineSpecifications:[],

}

//get View Request Quotation Lines
export const getQuotationLines = createAsyncThunk(
    'viewRequestQuotationLines/getAll',
    async (lines_id, thunkApi) =>{
    try{
        // const token = thunkApi.getState().auth.user.token;
        return await ViewRequestQuotationLinesService.getQuotationLines(lines_id)

    }catch(error){
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})
//getRfqLineSpecification
export const getRfqLineSpecification = createAsyncThunk(
    'getRfqLineSpecification/getAll',
    async (formData, thunkApi) =>{
    try{
        // const token = thunkApi.getState().auth.user.token;
        return await ViewRequestQuotationLinesService.getRfqLineSpecification(formData)

    }catch(error){
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})


export const ViewRequestQuotationLinesSlice = createSlice({
    name: 'viewRequestQuotationLines',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
            state.isCleanupFormRfqLine = false
        },
        cleanupFormRfqLine: (state) => {
            state.viewRequestQuotationLines = initialState.viewRequestQuotationLines
        }
    },
    extraReducers:(builder)=>{
        builder
            .addCase(getQuotationLines.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(getQuotationLines.fulfilled, (state,action) =>{
                state.isLoading=false
                state.isSuccess=true
                state.viewRequestQuotationLines = action.payload
            })
            .addCase(getQuotationLines.rejected, (state,action) =>{
                state.isLoading = false
                state.isError=true
                state.message = action.payload
            })
                
            // getRfqLineSpecification
            .addCase(getRfqLineSpecification.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(getRfqLineSpecification.fulfilled, (state,action) =>{
                state.isLoading=false
                state.isSuccess=true
                state.rfqLineSpecifications = action.payload
            })
            .addCase(getRfqLineSpecification.rejected, (state,action) =>{
                state.isLoading = false
                state.isError=true
                state.message = action.payload
            })

    }
})

export const { reset,cleanupFormRfqLine } = ViewRequestQuotationLinesSlice.actions
export default ViewRequestQuotationLinesSlice.reducer