import React, { useState } from 'react'
import {
    Card,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    IconButton,
    CircularProgress
} from '@mui/material';
import {
    Article,
    Circle,
    Today,
    InsertInvitation,
    Download,
    Assignment,
    ArrowForwardIosSharp,
    NewspaperOutlined,
    CloudUpload,
    Delete,
    PictureAsPdf
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { dateForHumansWithoutTime } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { reset, saveTenderAttachment } from '../../features/tender/tenderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { useEffect } from 'react';

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const ApplyTender = ({ openTender, handleOpenTender, handleCloseTender, tenderer, handleClose, handleCloseTenderAll }) => {
    const navigate = useNavigate();

    const [imageAsset, setImageAsset] = useState(null)
    const [applicationNo, setApplicationNo] = useState('1906');
    const [description, setDescription] = useState('Tender Doc');
    const [type, setType] = useState('2');
    const [tenderDoc, setTenderDoc] = useState('')
    const handleTenderDoc = (e) => {
        console.log(e.target.files[0]);
        setTenderDoc(e.target.files[0]);

    }


    const removeSelectedImage = () => {
        setTenderDoc('');
    }
    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const date = `${month} ${day},${year}`;

    const notifySuccess = () => toast.success(' Successfully Uploaded!', {
        theme: "colored",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notifyError = () => toast.error(' Not Uploaded!', {
        theme: "colored",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });


    const dispatch = useDispatch()
    const { isLoadingSaveTender, isErrorSaveTender, isSuccessSaveTender, message, tenderLine } = useSelector((state) => state.tender)
    const handleSubmit = async (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("file1", tenderDoc);
        formData.append("applicationNo", tenderLine);
        formData.append("description", tenderer.tenderDescription);
        formData.append("type", type)

        dispatch(saveTenderAttachment(formData))
    }
    useEffect(() => {
        if (isErrorSaveTender) {
            notifyError()
        }
        if (isSuccessSaveTender) {
            notifySuccess()
            handleCloseTender()
            handleCloseTenderAll()
        }
        dispatch(reset())
        setTenderDoc('');

    }, [isErrorSaveTender, isSuccessSaveTender, message, navigate, dispatch])


    return (
        <>
            <Dialog
                open={openTender}
                onClose={handleCloseTender}
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ borderRadius: 5, }}
            >

                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Apply Tender  <span style={{ fontSize: '15px' }}>{tenderer.no}</span>
                </BootstrapDialogTitle>

                <DialogContent dividers>
                    <div className='fw-700 mb-2'>Tender Document</div>

                    <div>Be sure to include updated Tender Document</div>


                    {
                        !tenderDoc ? (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderWidth: '2px', borderStyle: 'dotted', width: '100%', height: '225px' }}>
                                <label className='' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', width: '100%', height: '100%' }}>
                                    <div className='mb-2' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                        <CloudUpload className='' />
                                        <p className=''>
                                            Click here to upload
                                        </p>
                                    </div>
                                    <input
                                        type="file"
                                        name='uploadimage'
                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={handleTenderDoc}
                                        style={{ width: '0px', height: '0px' }}
                                    />

                                </label>
                            </div>
                        ) : (
                            <>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between', height: '6.4rem', width: '370px', border: 'thin solid gray', borderRadius: '12px 10px 10px 12px', margin: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: '10px 0px 0px 10px' }}>
                                        <span style={{ margin: "0px 15px" }}>
                                            {
                                                tenderDoc.type === "application/pdf" ? (
                                                    <PictureAsPdf sx={{ fontSize: '70px', color: 'red' }} />
                                                ) : (
                                                    <Article sx={{ fontSize: '70px', color: '#1976d2' }} />
                                                )
                                            }

                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='fw-700'>
                                            {tenderDoc.name}
                                        </div>

                                        <div className='' style={{ fontWeight: 'lighter' }}>
                                            uploaded on  {date}
                                        </div>
                                    </div>

                                    <div>
                                        <IconButton
                                            aria-label="close"
                                            onClick={removeSelectedImage}
                                            sx={{
                                                color: (theme) => theme.palette.grey[500], mr: '6px'
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </div>

                            </>
                        )
                    }

                </DialogContent>
                <DialogActions sx={{ mx: 3, mb: 2 }}>

                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        onClick={handleCloseTender}
                        variant="contained"
                        disableElevation
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    {
                        isLoadingSaveTender ? (
                            <Button disabled variant="contained" sx={{ textTransform: 'none' }}>
                                <CircularProgress size={17} color="inherit" />&nbsp;Submitting...
                            </Button>
                        ) : (
                            <Button
                                // disabled={!tenderDoc ? true : false}
                                sx={{ textTransform: 'capitalize' }}
                                variant="contained"
                                onClick={handleSubmit}
                                disableElevation
                                endIcon={<ArrowForwardIosSharp sx={{ fontSize: "14px !important" }} />}
                            >

                                Apply
                            </Button>
                        )
                    }
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ApplyTender