import React, { useState } from "react";
import {
  Card,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import {
  Article,
  Circle,
  Today,
  InsertInvitation,
  Download,
  Assignment,
  ArrowForwardIosSharp,
  NewspaperOutlined,
  ArrowForwardIos,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { dateForHumans, formDataFromObject, previewPaper } from "../../utils";
import ApplyTender from "../apply_tender/ApplyTender";
import { useDispatch, useSelector } from "react-redux";
import { saveTenderLineApplication } from "../../features/tender/tenderSlice";
import {
  setAppNo,
  setDocNo,
  setViewMode,
} from "../../features/preqs/preqSlice";

const ViewMyPrequalification = ({ openPreq, handleClosePreq, preq }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleViewDraft = () => {
    navigate("/apply-prequalification");
    dispatch(setAppNo(preq.applicationNo));
    dispatch(setDocNo(preq.documentNo));
    dispatch(setViewMode(true));
  };
  return (
    <>
      <Dialog
        open={openPreq}
        onClose={handleClosePreq}
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: 5 }}
      >
        <DialogContent>
          <div className="container my-2">
            <div className="d-flex justify-content-start">
              <div className="preq-icon-wrapper">
                <NewspaperOutlined className="preq-icon" />
              </div>
              <div className="view-preq-text-wrapper w-100">
                <h6 className="fs-15">{preq.applicationNo}</h6>
                <h6 className="fs-15">Disqualified Status</h6>
                <div className="d-flex justify-content-start">
                  <Circle
                    className={`status-icon ${preq.disqualified == "No" ? "text-green" : "text-purple"
                      }`}
                  />
                  <p className="fs-14 mb-2 px-1">{preq.disqualified}</p>
                </div>

                {
                  preq.disqualified == "Yes" && (
                    <div className="mb-2">
                      <h6 className="fs-15">Disqualification Reason</h6>
                      <p className="fs-15">{preq.reasonForDisqualification}</p>
                    </div>
                  )
                }

                <div className="mb-2">
                  <h6 className="fs-15">Disqualified Points</h6>
                  <p className="fs-15">{preq.disqualifiedPoint}</p>
                </div>


                <div className="mb-2">
                  <h6 className="fs-15">preq description</h6>
                  <p className="fs-15">{preq.description}</p>
                </div>

                <div className="mb-3">
                  <h6 className="fs-15">Bid Amount</h6>
                  <p className="fs-15">{preq.bidAmount}</p>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ mx: 3, mb: 2 }}>
          {/* <Button 
                    sx={{  textTransform: 'capitalize', position: 'absolute', left: '6.75rem',backgroundColor: '#E6FAFD', color: '#00BABA'  }} 
                    onClick={()=>previewPaper()}
                    color="inherit"
                    startIcon={<Download />}
                >
                    Download {preq.applicationNo}
                </Button> */}

          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#E6FAFD",
              color: "#00BABA",
            }}
            onClick={handleViewDraft}
            disabled={
              preq?.status == "SUBMITTED" ||
                preq?.status == "REGISTERED" ||
                preq?.status == "NOT REGISTERED"
                ? true
                : false
            }
            color="inherit"
            endIcon={<ArrowForwardIos />}
          >
            {preq?.status == "SUBMITTED" ||
              preq?.status == "REGISTERED" ||
              preq?.status == "NOT REGISTERED"
              ? "Already Submitted"
              : "View Draft"}
          </Button>

          <Button
            sx={{
              textTransform: "capitalize",
              position: "absolute",
              left: "6.75rem",
              backgroundColor: "#673ab7 !important",
              color: "#fff !important",
            }}
            onClick={handleClosePreq}
            variant="contained"
            disableElevation
            color="inherit"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewMyPrequalification;
