import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, CssBaseline, Divider, FormControl, FormLabel, Grid, NativeSelect, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, CircularProgress, Chip } from '@mui/material';
import BootstrapInput from '../../components/bootstrap/BootstrapInput';
import BootstrapSelect from '../../components/bootstrap/BootstrapSelect';
import { useDispatch, useSelector } from 'react-redux';
import { formatPath, formDataFromObject } from '../../utils';
import { getExperiences, reset, saveExperience } from '../../features/preqs/preqSlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AttachFile, Create, Delete } from '@mui/icons-material';
import { Form } from 'react-bootstrap';
import PreqDetailsDeleteModal from '../../components/prequalification/PreqDetailsDeleteModal';
import { toast } from 'react-toastify';

const Experience = () => {
    const [option, setOption] = useState("");
    const [options, setOptions] = useState([
        // { id: 'Choose what to attach',disabled: true, value: 'Choose what to attach' },
        { id: 'Copies of LPOs', value: 'Copies of LPOs' },
        { id: 'Letters of Award', value: 'Letters of Award' },
        { id: 'Completion Certificate', value: 'Completion Certificate' },
        { id: 'Contract Documents', value: 'Contract Documents' },
        { id: 'Others', value: 'Others' },
    ])
    // const  handleChange = (event) => {
    //     setOption(event.target.value);
    // }

    const { appPrequalification, experiences, isLoading, isProcessing,
        appNo,viewMode,
        isSuccessDeleteAtt, isError, message } = useSelector(state => state.preq);
    const dispatch = useDispatch()
    const fileRef = useRef(null);
    const [formData, setFormData] = useState({
        lineNo: 0,
        type: '',
        description: '',
        applicationNo: '',
        file: null,
    });
    const [selectedFile, setSelectedFile] = useState();

    const { lineNo, type, description, applicationNo, file } = formData;

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleFile = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const handleSubmit = () => {
        const experience = {
            ...formData,
            file: selectedFile,
            applicationNo: !viewMode ? appPrequalification.applicationNo  : appNo,
        }
        const _experience = formDataFromObject(experience);
        if(!experience.type || !experience.description || !experience.file){
            toast.warning("Please fill all fields required")
        }
        else{
            dispatch(saveExperience(_experience));
            setFormData({ lineNo: 0, type: '', description: '', applicationNo: '', file: null });
            fileRef.current.value = null;
        }
       
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [prodd, setProdd] = useState()



    useEffect(() => {
        dispatch(getExperiences());

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

 
    return (
        <>
        <CssBaseline/>
        <Box sx={{fontWeight: 'bold', my: 0, mx: 2, boxShadow: 1, p: 2, pt: 0, borderRadius: '5px',}}>
            <Typography variant={"h6"} sx={{ mt:2, mb: 1, fontSize: '0.875rem' }}>
                Experience (Applicant's three (3) reputable clients in the last three (3) years)
            </Typography>
            <Typography variant={"h6"} sx={{ mt: 0, fontSize: '0.775rem'}} color="error.main" >
                NOTE: Applicants attach proof of experience relevant to the category they choose to apply for by attaching any of the following documents.
            </Typography>

            <Grid container rowSpacing={1.25} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item md={6} xs={12}>
                    <BootstrapSelect
                        id="type" 
                        name="type"
                        selected={type}
                        handleChange={handleChange}
                        label="Choose what to attach*"
                        options={options}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <BootstrapInput
                        type="text"
                        id="description" 
                        name="description"
                        value={description}
                        handleChange={handleChange}
                        label="Information about file selected*"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Form.Group className='bootstrap-input  mb-1' controlId="formFile">
                        <Form.Label className="mb-0">Attach file*</Form.Label>
                        <Form.Control 
                            type="file"
                            ref={fileRef}
                            onChange={handleFile}
                        />
                    </Form.Group>
                </Grid>
                
                <Grid item md={6} xs={12} sx={{ mt: .65 }}>
                    <FormControl fullWidth variant={"standard"}>
                        <FormLabel htmlFor="my-input" variant={"subtitle"}>NOTE: You can add various proof of experience*</FormLabel>
                        { !isProcessing ? (
                            <Button 
                                fullWidth
                                variant="outlined" 
                                color="primary" 
                                sx={{ textTransform: 'capitalize' }}
                                onClick={handleSubmit}
                            >
                                Add Experience
                            </Button>
                        ) : (
                            <Button fullWidth disabled variant="contained" sx={{ textTransform: 'none' }}>
                                <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                            </Button>
                        )}
                    </FormControl>
                </Grid>
            </Grid>

            {isLoading ? (
                    <Skeleton className="my-1" count={3} height={50}/>
                ) : (
                    <>
                    {experiences?.length ? (
                        <>
                            <Divider sx={{my: 2}}/>
                            <TableContainer >
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell align="left">Description</TableCell>
                                            <TableCell align="left">Attached file</TableCell>
                                            <TableCell align="left">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {experiences?.map((experience) => (
                                        <TableRow
                                            key={experience.lineNo}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {experience.type}
                                        </TableCell>
                                        <TableCell align="left">{experience.description}</TableCell>
                                        <TableCell align="left">
                                            <Chip 
                                                label={formatPath(experience.attachUrl)}
                                                onClick={()=> {}}
                                                color="primary" 
                                                className="file-chip-primary"
                                                size="small" 
                                                variant="outlined"
                                                icon={<AttachFile />} />
                                        </TableCell>
                                        <TableCell align="left">
                                        
                                            <IconButton aria-label="delete" size="small" onClick={()=>setProdd(experience)} >
                                                <Delete fontSize="small" onClick={()=>handleClickOpen()} />
                                            </IconButton>
                                        </TableCell>
                                
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (<></>)
                    }
                    </> 
                )}
        </Box>
        <PreqDetailsDeleteModal 
        open={open} 
        handleClickOpen={handleClickOpen} 
        handleClose={handleClose} 
        prodd={prodd} 
        type="4"
        />
    </>
    )
}

export default Experience