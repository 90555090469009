import React from 'react';
import './requestForQuotation.css';
import { Card, CardContent, Box } from '@mui/material';
import { CssBaseline, Divider, ThemeProvider
} from "@mui/material";
import {createTheme, } from "@mui/material/styles";
import { purple, blue } from "@mui/material/colors";
import { useLocation } from 'react-router-dom';
import RfqHeader from "./Header/RfqHeader";
import RfqLines from "./Lines/RfqLines";


const RequestForQuotation = () => {

    const location = useLocation();
    const name = location.pathname.split("/", 4);
    const id1 = name[2];
    const id2 = name[3];
    const no = [id1, id2];

    const id = no.join("/");
    
    
    
    const theme = createTheme({
        palette: {
            primary: {
                main: blue[700],
            },
            secondary: {
                main: purple[700],
            },
        },
    });


    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Box>
                    <Card sx={{fontWeight: 'bold', my: 0, mx: 2, boxShadow: 1, p: 2, pt: 0, borderRadius: '5px',}}>
                        <CardContent>
                            <RfqHeader id={id} />
                        </CardContent>

                        <Divider sx={{ my: 3}}/>

                        <CardContent>
                            <RfqLines id={id} />
                        </CardContent>
                    </Card>
                </Box>
            </ThemeProvider>

        </>

    )
}

export default RequestForQuotation



