import axios from 'axios'
import "react-toastify/dist/ReactToastify.css";
import { toast } from 'react-toastify';

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;

// substring -truncates a string given number of characters
export const subString = (str='', limit=0) => {
    if (!str) return;
    return (str.length > limit) ? `${str.substring(0, limit)}...`  : str;
  }
//get date suffix
export const getSuperscriptSuffix = (day) => {
    const lastDigit = day % 10;
    const secondLastDigit = Math.floor(day / 10) % 10;
  
    if (secondLastDigit === 1) {
      return 'th';
    }
  
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

// convert timestamp to human readable format
export const dateForHumans = (timestamp) => {
    if (!timestamp) return;
    let date = new Date(timestamp);

    let year = date.getFullYear();
    // let month = ("0" + (date.getMonth() + 1)).slice(-2);

    // Getting short month name (e.g. "Oct")
    let month = date.toLocaleString('default', { month: 'short' });
    let day = ("0" + date.getDate()).slice(-2);

     
    let hour = ("0" + date.getHours()).slice(-2);
    let min = ("0" + date.getMinutes()).slice(-2);
    let sec = ("0" + date.getSeconds()).slice(-2);

    // ${timeAmPm(timestamp)}
    return `${day}${getSuperscriptSuffix(day)} ${month} ${year}`;
}
 

export const timeAmPm = (timestamp) => {
    if (!timestamp) return;

    let time = new Date(timestamp).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    })
    return time;
}


// filter tenders by date and time
export const filterByDate = () => {
    let timestamp = "12/21/22";
    const date = new Date(timestamp);
    const currentDate = new Date();
    

    return currentDate

    // if ( date < currentDate ) {
    //     console.log('Remove It')
    // }
    // else {
    //     console.log('Hello World')
    // }
}

// formatPreqQuestions
export const formatPreqQuestions = ({questions, answers}) => {
    console.log("utilsquestions",questions)
    console.log("utilsanswers",answers)
    // if (questions.length) {
        for (let i = 0; i < questions.length; i++) {
            // questions[i]['value'] = "";
            if (answers) {
                for (let j = 0; j < answers.length; j++) {
                   if (questions[i]['lineNo'] == answers[j]['questionId']) {
                       questions[i]['value'] = answers[j]['answer'];
                   }
                }
            }
        }
    // }
    return questions;
} 


// formDataFromObject(obj)
export const formDataFromObject = (obj) => {
    let formData = new FormData();
    for (let key in obj) {
        formData.append(key, obj[key]);
    }
    return formData;
}

// formatGridRows(rows)
export const formatGridRows = (rows) => {
    let _rows = [];
    if (rows.length) {
        for (let i = 0; i < rows.length; i++) {
            let _row = { ...rows[i], id: rows[i]['lineNo'] }
            _rows.push(_row)
        }
    }
    console.log("formatGridRows _rows", _rows)
    return _rows;
}

// microsoft dynamics nav arr/list cleanup
export const arrCleanUp = (arr, key) => {
    // if (arr.length) {
    //     arr.filter(item => item[key] != 0)
    // }
    return arr.filter(item => item[key] != 0);
}

// get last segment of a path
export const formatPath = (path) => {
    if (!path) return '';
    return path.substring(path.lastIndexOf('/') + 1);
}

export const newDate = (tenders) => {
    if (!tenders.length) return;
    return tenders.filter(tender => new Date(`${tender.tenderClosingDate} ${tender.tenderClosingTime}`).getTime() >= new Date().getTime())
}

const getFileType = () => {
    let file = "http://eproc.mawasco.co.ke/myimages/MaWSC-01-2023-2024-PROVISION-OF-CLEANING-SERVICES.pdf"
    return file.substring(file.lastIndexOf('.') + 1);
}

 
export const previewPaper = (doc) => {
    let link = formatImgPath(doc);
    if (getFileType(doc) == "docx" || getFileType(doc) == "doc" || getFileType(doc) == "pdf") {
        window.open(`${link}`, '_blank')
    } else {
        window.open(link, '_blank')
    }

}

export const formatImgPath = () => {
    let path = "http://eproc.mawasco.co.ke/myimages/MaWSC-01-2023-2024-PROVISION-OF-CLEANING-SERVICES.pdf"
    if (path === null|| path ===""|| path === undefined || typeof path === 'object') {
        return path;
    }
    
    if (!path.startsWith('./')) { return path; }
    const host = window.location.host;
    const protocol = window.location.protocol;
    // const domain = 'supplier.nyewasco.co.ke';
    // const domain = 'mombasawater.co.ke';
    const domain = 'eproc.mawasco.co.ke';

 
 
    if (host === 'localhost:3000') {
        return `${protocol}//${domain}${path.replace('./', '/')}`;
    }

    if (host === `${domain}:8080`) {
        return `${protocol}//${domain}${path.replace('./', '/')}`;
    }

    return path; // Return the path parameter if none of the conditions apply
}

export const generateAttachment=(filename)=>{
    axios.get(`${API_URL}/supplier/getDocLink.action?fileName=${filename}`)
        .then(res=>{
            if (res.data.data.result !=null){
                const uri = res.data.data.result;
                const url =  encodeURI(uri);
                toast.success("Document generated successfully")
                window.open(url, '_blank');
            }else{
                toast.error("something went wrong, please try again later!");
            }
            // window.open(res.data.data.result, '_blank');
        }).catch(err=>{
            console.log(err);
        })
}

 
 
