import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './header.css'

import { Link, useNavigate, useLocation } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { cleanupForm, logout, reset } from '../../features/auth/authSlice';
import { string } from "yup";
import Grid from "@mui/material/Grid";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { cleanupFormPreq } from '../../features/preqs/preqSlice';
import { cleanupFormProfile } from '../../features/profile/profileSlice';
import { cleanupFormPurchaseAtt } from '../../features/purchase/Attachment/ViewPurchaseAttachmentSlice';
import { cleanupFormPurchaseHeader } from '../../features/purchase/Header/ViewPurchaseSlice';
import { cleanupFormPurchaseLine } from '../../features/purchase/Lines/ViewPurchaseLinesSlice';
import { cleanupFormPurchase } from '../../features/purchase/purchaseSlice';
import { cleanupFormRfp } from '../../features/requestForProposal/rfpSlice';
import { cleanupFormRfqHeader } from '../../features/requestForQuotation/Header/ViewRequestQuotationSlice';
import { cleanupFormRfqLine } from '../../features/requestForQuotation/Lines/ViewRequestQuotationLinesSlice';
import { cleanupFormTender } from '../../features/tender/tenderSlice';
import storage from 'redux-persist/lib/storage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Logout, NavigateNext, NotificationsOutlined, SearchOutlined,
  ExitToAppOutlined, SupportOutlined,
  People, ArrowRight, Settings, NewspaperOutlined, RequestQuoteOutlined, TextSnippetOutlined, DescriptionOutlined, AssignmentOutlined, HomeOutlined
} from '@mui/icons-material';
import { Avatar, ListItemIcon, Menu, MenuItem, Stack } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import Sidebar from '../sidebar/Sidebar';

const drawerWidth = 360;
const navItems = ['Home', 'About', 'Contact'];

function Header2(props) {
  const isMobile = useMediaQuery({ query: "(max-width:480px)" });
  const { user } = useSelector((state) => state.auth)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(cleanupFormPreq());
    dispatch(cleanupFormProfile());
    dispatch(cleanupFormPurchaseAtt());
    dispatch(cleanupFormPurchaseHeader());
    dispatch(cleanupFormPurchaseLine());
    dispatch(cleanupFormPurchase());
    dispatch(cleanupFormRfp());
    dispatch(cleanupFormRfqHeader());
    dispatch(cleanupFormRfqLine());
    dispatch(cleanupFormTender());
    storage.removeItem('persist:root');
    dispatch(logout());
    dispatch(reset());
    dispatch(cleanupForm())
    navigate('/login');
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const setHeaderTitles = (path) => {
    const _path = path.split("/")[1];
    switch (_path) {
      case "prequalifications":
        setTitle('Supplier Registration')
        setSubtitle('All Supplier Registrations')
        break;
      case "apply-prequalification":
        setTitle('Supplier Registration')
        setSubtitle('Supplier Registration')
        break;
      case "tenders":
        setTitle('Tenders')
        setSubtitle('All Tenders')
        break;
      case "rfp":
        setTitle('Request for Proposal')
        setSubtitle('All RFPs')
        break;
      case "purchase-quotes":
        setTitle('Quotations')
        setSubtitle('My Quotations')
        break;
      case "view-purchase-quote":
        setTitle('Quotations')
        setSubtitle('Purchase Quote')
        break;
      case "view-rfq":
        setTitle('Quotations')
        setSubtitle('View RFQ')
        break;
      case "profile":
        setTitle('Profile')
        setSubtitle('Profile')
        break;
      case "my-prequalifications":
        setTitle('Supplier Registrations')
        setSubtitle('My Supplier Registrations')
        break;
      case "my-tenders":
        setTitle('Tenders')
        setSubtitle('My Tenders')
        break;
      case "my-rfps":
        setTitle('Request for Proposal')
        setSubtitle('My RFPs')
        break;
      default:
        setTitle('Dashboard')
        setSubtitle('Dashboard')
        break;
    }
  }


  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  
  const drawer = (
    <Box  sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2, }} className='text-primary'>
        e-procurement
      </Typography>

      <Divider />

      <List>
        <Sidebar handleDrawerToggle={handleDrawerToggle}/>
      </List>
       
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    setHeaderTitles(location.pathname);
  }, [location])

  return (

    <>
      <CssBaseline />
      <AppBar component="nav" class="navbar navbar-expand-lg navbar-light bg-light">

        <Toolbar className='head container-fluid'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <div class="collapse navbar-collapse" id="navbarSupportedContent" >
            <ul class="nav navbar-nav ml-auto">
              <li class="nav-item active">
                <h4 class="data-sources sidebar-title fw-700">{title}</h4>
                <div class="data-nav">
                  <div class="">
                    <span className="fs-12 fw-500">Home</span>
                    <NavigateNext sx={{ fontSize: '18px' }} />
                    <a class="data-sources1 fs-12 fw-500 text-primary">{subtitle}</a>
                  </div>
                </div>
              </li>

            </ul>
            <form class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
              <div class="input-group search-box">
                <input class="form-control shadow-none" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                <Button variant="outlined" class="input-group-text">
                  <SearchOutlined />
                </Button>
              </div>
            </form>

            <div
              aria-label="more"
              id="demo-positioned-button"
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              className="d-flex justify-content-start user-profile-wrapper">

              <Avatar sx={{ width: 5, height: 5 }} {...stringAvatar(`${user.usrFirstName} ${user.usrLastName}`)} />

            </div>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                role: 'listbox',

                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  width: 240,
                  maxWidth: '100%',
                  '& .MuiAvatar-root': {
                    width: 38,
                    height: 38,
                    ml: 1,
                    mr: 2,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >


              <Typography
                sx={{ color: '#5E6C84', fontSize: '10px', mt: 1, mb: 2, mx: 1 }}
              >
                ACCOUNT
              </Typography>

              <Box sx={{ mb: 2, display: "flex", justifyContent: 'centre', alignItems: 'center' }}>
                <Stack direction="row" spacing={0}>
                  <Avatar  {...stringAvatar(`${user.usrFirstName} ${user.usrLastName}`)} />

                  <Stack spacing={0}>
                    <Typography
                      sx={{ fontSize: '16px', fontWeight: 'bold' }}
                    >
                      {user ? `${user.usrFirstName} ${user.usrLastName}` : 'Ikea Store'}
                    </Typography>
                    <Typography
                      sx={{ fontSize: "13px" }}
                    >
                      {user.usrEmail}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>

              <Link to="/profile">
                <MenuItem
                  sx={{ mb: 2 }}
                >
                  <Stack
                    direction={{ sm: 'row' }}
                    spacing={{ xs: 5, sm: 6, md: 7 }}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Typography
                      sx={{ fontSize: '15px' }}
                    >
                      Manage account
                    </Typography>
                    <OpenInNewOutlinedIcon sx={{ fontSize: "medium", justifyContent: 'end' }} />
                  </Stack>
                </MenuItem>
              </Link>

              <Divider variant="middle" />

              <Typography
                sx={{ color: '#5E6C84', fontSize: '10px', my: 2, mx: 1 }}
              >
                PROCUREMENT
              </Typography>

              <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>

              <Divider variant="middle" />

              <MenuItem onClick={onLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>

          </div>
        </Toolbar>

      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, marginTop: '20px!important' },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

    </>


  );
}


export default Header2;

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      fontSize: '16px',
      width: 34,
      height: 34,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}