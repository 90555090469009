import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';

import purchaseService from './purchaseService';

const initialState = {
    purchase: [],
    openQuotes:[],
    purchaseHeader: undefined,
    purchaseLines: undefined,
    isError: false,
    isLoading: false,
    isSuccess: false,
    isErrorOpenQuotes: false,
    isLoadingOpenQuotes: false,
    isSuccessOpenQuotes: false,
    message: '',
    isCleanupFormPurchase: false,
}

//get Purchase
export const getPurchase = createAsyncThunk('purchase/getAll',async (formData, thunkApi) =>{
    try{
        // const token = thunkApi.getState().auth.user.token;
        return await purchaseService.getPurchase(formData)

    }catch(error){
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

export const getPurchaseGeneral = createAsyncThunk('purchase/getGeneral',async (headerId, thunkApi) =>{
    try{
        return await purchaseService.getPurchaseGeneral(headerId)

    }catch(error){
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

// get Open  Quotations
export const getOpenQuotations = createAsyncThunk(
    'OpenQuotations/getAll',async (
        _, thunkApi) => {
    try{
        // const token = thunkApi.getState().auth.user.token;
        return await purchaseService.getOpenQuotations()

    }catch(error){
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})



export const purchaseSlice = createSlice({
    name: 'purchase',
    initialState,
    reducers: {
        reset: (state) => initialState,
        cleanupFormPurchase: (state) => {
            state.purchase = initialState.purchase
            state.openQuotes = initialState.openQuotes
        }
    },
    extraReducers:(builder)=>{
        builder
            .addCase(getPurchase.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(getPurchase.fulfilled, (state,action) =>{
                state.isLoading=false
                state.isSuccess=true
                state.purchase = action.payload
            })
            .addCase(getPurchase.rejected, (state,action) =>{
                state.isLoading = false
                state.isError=true
                state.message = action.payload
            })
            .addCase(getPurchaseGeneral.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(getPurchaseGeneral.fulfilled, (state, action) =>{
                state.isLoading=false
                state.isSuccess=true
                state.purchaseHeader = action.payload.header
                state.purchaseLines = action.payload.lines
            })
            .addCase(getPurchaseGeneral.rejected, (state,action) =>{
                state.isLoading = false
                state.isError=true
                state.message = action.payload
            })

            .addCase(getOpenQuotations.pending, (state) =>{
                state.isLoadingOpenQuotes = true
            })
            .addCase(getOpenQuotations.fulfilled, (state,action) =>{
                state.isLoadingOpenQuotes=false
                state.isSuccessOpenQuotes=true
                state.openQuotes = action.payload
            })
            .addCase(getOpenQuotations.rejected, (state,action) =>{
                state.isLoadingOpenQuotes = false
                state.isErrorOpenQuotes=true
                state.message = action.payload
            })

    }
})

export const { reset,cleanupFormPurchase } = purchaseSlice.actions
export default purchaseSlice.reducer