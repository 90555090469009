import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 
const register =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/postSignUpSupplier.action`, formData, {
        
        headers: {'content-type': 'application/x-www-form-urlencoded'}
                
    })

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    
    if (response.data) {
        localStorage.setItem('user',JSON.stringify(response.data))
    }

    return response.data
}

const login =async(userData)=>{
    const response=await axios.post(`${API_URL}/j_spring_security_check`, userData, {
        headers: {'content-type': 'application/x-www-form-urlencoded'}  
    })

    if (!response.data.success) {
        return Promise.reject(response.data.messages.mainMessage) 
    }
    
    if (response.data){
        localStorage.setItem('user',JSON.stringify(response.data))
    }
    return response.data.jsonData
}

// logout user 
const logout =()=>{
    localStorage.removeItem('user')
}

const authService={
   register,
   logout,
    login,
}
export default authService
