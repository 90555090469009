import axios from "axios";

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;

// get Purchase Lines
const getPurchaseLines = async (Lines_id) => {
    const response = await axios.get(`${API_URL}/supplier/fetchPurchaseQuoteLineList.action?no=${Lines_id}`)
    const result = response.data.data.result;
    response.data.data.result = [];
    console.log('Info -----> lIst', result);
    
    return result;
}

//Update UnitCost

const updatePurchaseLines = async (purchaseLine) => {
    const response = await  axios.post(`${API_URL}/supplier/modifyPurchaseQuoteLineList.action`, purchaseLine, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}

// Save Quotation using ID(QuoteNo)
const savePurchaseLines = async (quoteNo) => {
    const response = await  axios.post(`${API_URL}/supplier/savePurchaseQuoteLineList.action`, quoteNo, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}

const purchaseLinesService={
    getPurchaseLines,
    updatePurchaseLines,
    savePurchaseLines
}
export default purchaseLinesService