import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, CssBaseline, FormControl, FormLabel, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Divider, CircularProgress, Chip } from '@mui/material';
import BootstrapInput from '../../components/bootstrap/BootstrapInput';
import { useDispatch, useSelector } from 'react-redux';
import { formatPath, formDataFromObject } from '../../utils';
import { getPersonnelList, reset, savePersonnel } from '../../features/preqs/preqSlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AttachFile, Create, Delete } from '@mui/icons-material';
import { Form } from 'react-bootstrap';
import PreqDetailsDeleteModal from '../../components/prequalification/PreqDetailsDeleteModal';
import BootstrapSelect from '../../components/bootstrap/BootstrapSelect';
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';

const Personnel = () => {
    const [options, setOptions] = useState([
        // { id: 'Choose what to attach', disabled: true, value: 'Choose gender' },
        { id: 'Male', value: 'Male' },
        { id: 'Female', value: 'Female' },
        { id: 'Others', value: 'Others' },
    ])
    const { appPrequalification, personnelList, isLoading, isProcessing, 
        appNo,viewMode,
        isSuccessDeleteAtt, isError, message } = useSelector(state => state.preq);
    const dispatch = useDispatch()
    const fileRef = useRef(null);
    const [formData, setFormData] = useState({
        lineNo: 0,
        name: '',
        age: '',
        gender: '',
        description: '',
        academicQualification: '',
        professionalQualification: '',
        applicationNo: '',
        file: null,
    });
    const [selectedFile, setSelectedFile] = useState();

    const { lineNo, name, age, gender, description, academicQualification, professionalQualification, applicationNo, file } = formData;

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleFile = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const handleSubmit = async() => {
        const personnel = {
            ...formData,
            file: selectedFile,
            applicationNo:!viewMode ? appPrequalification.applicationNo  : appNo,
        };
        const _personnel = formDataFromObject(personnel)
        if (!personnel.name || !personnel.gender || !personnel.description || !personnel.file) {
            toast.warning("Fill all mandatory fields")
        } else {
            const res = await dispatch(savePersonnel(_personnel));
            setFormData({ lineNo: 0, name: '', age: '', gender: '', description: '', academicQualification: '', professionalQualification: '', applicationNo: '', file: null, });
            fileRef.current.value = null;
            console.log("res",res)
            if(res?.payload?.lineNo){
                dispatch(getPersonnelList())
            }
        }
    }
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [prodd, setProdd] = useState()

    useEffect(() => {
        dispatch(getPersonnelList())
        return () => {
            dispatch(reset())
        }
    }, [dispatch])

 


    return (
        <>
            <CssBaseline />
            <Box sx={{ fontWeight: 'bold', my: 0, mx: 2, boxShadow: 1, p: 2, pt: 0, borderRadius: '5px', }}>
                <Typography variant={"h6"} sx={{ my: 2, fontSize: '0.875rem' }}>
                    Supervisory Personnel (Applicable for consultancy services only)
                </Typography>
                <Grid container rowSpacing={1.25} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item md={6} xs={12}>
                        <input type="hidden" name="lineNo" value={lineNo} />
                        <BootstrapInput
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            handleChange={handleChange}
                            label="Full Name*"
                        />
                    </Grid>
                    {/* <Grid item md={6} xs={12}>
                        <BootstrapInput
                            type="number"
                            id="age" 
                            name="age"
                            value={age}
                            handleChange={handleChange}
                            label="Age*"
                        />
                    </Grid> */}
                    <Grid item md={6} xs={12}>
                        <BootstrapSelect
                            id="gender"
                            name="gender"
                            selected={gender}
                            handleChange={handleChange}
                            label="Choose gender*"
                            options={options}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput
                            type="text"
                            id="description"
                            name="description"
                            value={description}
                            handleChange={handleChange}
                            label="Position/Job title*"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput
                            type="text"
                            id="academicQualification"
                            name="academicQualification"
                            value={academicQualification}
                            handleChange={handleChange}
                            multiline
                            rows={2}
                            maxRows={2}
                            label="Academic Qualification"
                            sub="true"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput
                            type="text"
                            id="professionalQualification"
                            name="professionalQualification"
                            value={professionalQualification}
                            handleChange={handleChange}
                            multiline
                            rows={2}
                            maxRows={2}
                            label="Professional Qualification"
                            sub="true"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {/* <BootstrapInput 
                             id="file" 
                             name="file"
                            //  value={file}
                             handleChange={handleFile}
                            type='file'
                            label="Attach personnel documents* [TIP: merge files in single pdf/word document]" 
                        /> */}

                        <Form.Group className='bootstrap-input  mb-1' controlId="formFile">
                            <Form.Label className="mb-0">Attach personnel documents* [TIP: merge files in single pdf/word document]</Form.Label>
                            <Form.Control
                                type="file"
                                ref={fileRef}
                                onChange={handleFile}
                            />
                        </Form.Group>
                    </Grid>

                    <Grid item md={6} xs={12} sx={{ mt: .65 }}>
                        <FormControl fullWidth variant={"standard"}>
                            <FormLabel htmlFor="my-input" variant={"subtitle"}>NOTE: You can add as many Personnel as possible*</FormLabel>

                            {!isProcessing ? (
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    sx={{ textTransform: 'capitalize' }}
                                    onClick={handleSubmit}
                                >
                                    Add personnel
                                </Button>
                            ) : (
                                <Button fullWidth disabled variant="contained" sx={{ textTransform: 'none' }}>
                                    <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                                </Button>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>

                {isLoading ? (
                    <Skeleton className="my-1" count={3} height={50} />
                ) : (
                    <>
                        {personnelList?.length ? (
                            <>
                                <Divider sx={{ my: 2 }} />
                                <TableContainer >
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Full Name</TableCell>
                                                {/* <TableCell align="left">Age</TableCell> */}
                                                <TableCell align="left">Gender</TableCell>
                                                <TableCell align="left">Position/Job title</TableCell>
                                                <TableCell align="left">Academic Qualification</TableCell>
                                                <TableCell align="left">Professional Qualification</TableCell>
                                                <TableCell align="left">Attachments</TableCell>
                                                <TableCell align="left">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {personnelList?.length && personnelList?.map((personnel) => (
                                                <TableRow
                                                    key={personnel.lineNo}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {personnel.name}
                                                    </TableCell>
                                                    {/* <TableCell align="left">{personnel.age}</TableCell> */}
                                                    <TableCell align="left">{personnel.gender}</TableCell>
                                                    <TableCell align="left">{personnel.description}</TableCell>
                                                    <TableCell align="left">{personnel.academicQualification}</TableCell>
                                                    <TableCell align="left">{personnel.professionalQualification}</TableCell>
                                                    <TableCell align="left">
                                                        <Chip
                                                            label={formatPath(personnel.attachUrl)}
                                                            onClick={() => { }}
                                                            color="primary"
                                                            className="file-chip-primary"
                                                            size="small"
                                                            variant="outlined"
                                                            icon={<AttachFile />} />
                                                    </TableCell>
                                                    <TableCell align="left">

                                                        <IconButton aria-label="delete" size="small" onClick={() => setProdd(personnel)} >
                                                            <Delete fontSize="small" onClick={() => handleClickOpen()} />
                                                        </IconButton>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        ) : (<></>)
                        }
                    </>
                )}

            </Box>
            <PreqDetailsDeleteModal
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                prodd={prodd}
                type="3"
            />
        </>
    )
}

export default Personnel