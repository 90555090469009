import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './prequalification.css';
import { Article, Circle, MoreVert, Download,  Assignment, AssignmentOutlined } from '@mui/icons-material';
import { Card, Button, Menu, MenuItem, IconButton, Grid, Chip } from '@mui/material';
import ViewPrequalification from '../ view_prequalification/ViewPrequalification';
import { dateForHumans, generateAttachment, getSuperscriptSuffix, subString } from '../../utils';
import { setViewMode } from '../../features/preqs/preqSlice';
import { useDispatch } from 'react-redux';


const Prequalification = ({ prequalification, openPreq, handleOpenPreq, handleClosePreq  }) => {
 
  const  navigate = useNavigate();
  const dispatch = useDispatch(); 
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRemoveVieModel = () =>{
    dispatch(setViewMode(false));
     navigate('/apply-prequalification')
  }

  return (
      <>
     
        <Card className='prequalification br-10'>
            <div className='row'>
                <div className='col-sm-3 d-flex justify-content-start'>
                    <div className='preq-icon-wrapper'>
                        <AssignmentOutlined className='preq-icon'/>
                    </div>
                    <div className='preq-text-wrapper'>
                        <h6 className='fs-13'>{prequalification?.tenderNo}</h6>
                        <p className='fs-12'>{subString(prequalification?.tenderDescription, 50) }</p>
                    </div>
                </div>
                <div className='col-sm-3'>
                    <div className='status d-flex justify-content-start'>
                        <Circle className={`status-icon ${prequalification?.eligibility == 'Open' ? 'text-green' : 'text-purple'}`} />
                        <p className='fs-12 px-1'>{prequalification?.eligibility}</p>
                    </div>
                </div>
                <div className='col-sm-2'>
                    <h6 className='fs-12 dates'>{prequalification?.registrationStatus == 'OPEN' ? 
                    <Chip label='OPEN' sx={{background:'#0ff00d',color:'#fff',minWidth:'100px', }}/>   : 
                     prequalification?.registrationStatus == 'DRAFT' ? 
                     <Chip label='DRAFT' sx={{background:'#673ab7',color:'#fff',minWidth:'100px'}}/> :
                     prequalification?.registrationStatus == 'SUBMITTED' ? 
                     <Chip label='SUBMITTED' sx={{background:'#3d6b3d',color:'#fff'}}/> :
                     prequalification?.registrationStatus == 'REGISTERED' ? 
                     <Chip label='REGISTERED' sx={{background:'#3d6b3d',color:'#fff'}}/> :
                     <Chip label='NOT REGISTERED' sx={{background:'#3d6b3d',color:'#fff'}}/> 
                     }
                     </h6>
                </div>
                <div className='col-sm-2'>
                <h6 className='fs-12 dates'>{dateForHumans(prequalification?.tenderOpeningDate)} 
                <span className="fs-12"> - </span>{dateForHumans(prequalification?.tenderClosingDate)}</h6>
                    {/* <h6 className='fs-12 dates'>{dateForHumans(`${prequalification.documentDate} ${prequalification.tenderOpeningTime}`)}</h6> */}
                </div>
               
                <div className='col-sm-2'>
                   <Button 
                        sx={{ p: 1, textTransform: 'capitalize', borderRadius: '.75rem', borderColor: '#e7e7e7' }} 
                        className="fs-12" variant="outlined" color="inherit"
                        onClick={handleOpenPreq}
                    >
                        View More
                    </Button>

                    <IconButton
                        aria-label="more"
                        id="demo-positioned-button"
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx = {{ ml: 3 }}
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        className="br-10"
                    >
                        <MenuItem onClick={()=> {handleClose();generateAttachment('REGISTRATION.doc')}} sx={{ minWidth: '10rem' }}><Download sx={{ mr: 1 }}/>Download</MenuItem>
                       
                        {
                            prequalification?.registrationStatus == 'OPEN'  || prequalification?.registrationStatus == 'DRAFT'? (
                                <MenuItem onClick={handleRemoveVieModel} 
                                sx={{  minWidth: '10rem' }}><Assignment sx={{ mr: 1 }}/>Supplier Registrations</MenuItem>
                            ):(
                                <MenuItem  disabled
                                sx={{  minWidth: '10rem' }}><Assignment sx={{ mr: 1 }}/>Already Applied</MenuItem>
                            )
                        }
                    </Menu>
        
                </div>
            </div>
        </Card>
    </>
  )
}

export default Prequalification
























