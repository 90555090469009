import React, { Component, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import { CountyData } from '../../CountyData'
import { setCompanyDetails, getCompanyDetails } from '../../features/auth/authSlice';
const CompanyDetails = () => {

  const dispatch = useDispatch();
  const { company } = useSelector(state => state.auth)

  const [generalData, setGeneralData] = useState({})
  const [isUpdating, setIsUpdating] = useState(false);

  const [formData, setFormData] = useState(company)

  const { usrpCompanyName, usrpContactPerson, usrpCountry, usrpCounty, usrpKraPinCertNo} = formData

   const handleChange = (e) => {
     setFormData((prevState) =>({
       ...prevState,
       [e.target.name]: e.target.value
     }))
     setIsUpdating(true);
  };

  useEffect(() => {
    if(!isUpdating) {
      // TODO::
    } else {
      dispatch(setCompanyDetails(formData))
    }
  }, [dispatch, isUpdating, formData]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Company Details
      </Typography>
      <Box component="form" Validate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="usrpCompanyName"
          label="Company Name"
          name="usrpCompanyName"
          value={usrpCompanyName}
          autoComplete="name"
          onChange={handleChange}
          // onChange={(e) => {
          //   setFormData((prevState) => ({
          //     ...prevState,
          //     [e.target.name]: e.target.value,
          //   }))
          //   generalData.usrpCompanyName = e.target.value
          // }}
          // onBlur={() => {
          //   localStorage.setItem(`generalinfo`, JSON.stringify(generalData))
          // }}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="usrpCountry"
          label="Country"
          type="text"
          id="usrpCountry"
          autoComplete="usrpCountry"
          value={usrpCountry}
          onChange={handleChange}
          // onChange={(e) => {
          //   setFormData((prevState) => ({
          //     ...prevState,
          //     [e.target.name]: e.target.value,
          //   }))
          //   generalData.usrpCountry = e.target.value
          // }}
          // onBlur={() => {
          //   localStorage.setItem(`generalinfo`, JSON.stringify(generalData))
          // }}
        />


        <TextField
          id="outlined-select-county"
          select
          fullWidth
          name="usrpCounty"
          label="County"
          value={usrpCounty}
          onChange={handleChange}
          // onChange={(e) => {
          //   setFormData((prevState) => ({
          //     ...prevState,
          //     [e.target.name]: e.target.value,
          //   }))
          //   generalData.usrpCounty = e.target.value
          // }}
          // onBlur={() => {
          //   localStorage.setItem(`generalinfo`, JSON.stringify(generalData))
          // }}
          helperText="Please select your county"
        >
          {CountyData.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          margin="normal"
          required
          fullWidth
          name="usrpKraPinCertNo"
          value={usrpKraPinCertNo}
          label="KRA Pin / Tax Registration"
          type="text"
          id="usrpKraPinCertNo"
          autoComplete="text"
          onChange={handleChange}
          // onChange={(e) => {
          //   setFormData((prevState) => ({
          //     ...prevState,
          //     [e.target.name]: e.target.value,
          //   }))
          //   generalData.usrpKraPinCertNo = e.target.value
          // }}
          // onBlur={() => {
          //   localStorage.setItem(`generalinfo`, JSON.stringify(generalData))
          // }}
        />

      </Box>
    </React.Fragment>
  );

}

export default CompanyDetails;