import './viewRequestForProposal.css';
import {
    Card,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText
} from '@mui/material';
import {
    Article,
    Circle,
    Today,
    InsertInvitation,
    Download,
    Assignment,
    ArrowForwardIosSharp,
    DescriptionOutlined
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { dateForHumans, formDataFromObject, previewPaper } from '../../utils';
import ApplyTender from "../../components/apply_tender/ApplyTender";
import React, { useState } from "react";
import ApplyProposal from "../../components/apply_proposal/ApplyProposal";
import { useDispatch, useSelector } from 'react-redux';
import { saveRfpApplication } from '../../features/requestForProposal/rfpSlice';


const ViewRequestForProposal = ({ openRfq, handleOpenRfq, handleCloseRfq, requestForProposal }) => {

    const { user } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [close, setClose] = useState(false);
    const [selectedProposal, setSelectedProposal] = useState({});

    const handleClickOpen = () => {
        setOpen(true);
        setSelectedProposal(requestForProposal);

        const rfp = {
            createdBy: user.usrId,
            updatedBy: user.usrId,
            status: requestForProposal.status,
            description: requestForProposal.tenderDescription,
            tenderNo: requestForProposal.no,
            supplierProfileID: user.usrId,
        }
        const _rfp = formDataFromObject(rfp)
        dispatch(saveRfpApplication(_rfp));
    };



    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={openRfq}
                onClose={handleCloseRfq}
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ borderRadius: 5 }}
            >
                {/* <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
            </DialogTitle> */}

                <DialogContent>

                    <div className='container my-2'>
                        <div className='d-flex justify-content-start'>
                            <div className='preq-icon-wrapper'>
                                <DescriptionOutlined className='preq-icon' />
                            </div>
                            <div className='view-preq-text-wrapper w-100'>
                                <h6 className='fs-15'>{requestForProposal.no}</h6>
                                {/* <div className='d-flex justify-content-start'>
                                    <Circle className={`status-icon ${requestForProposal.eligibility == 'Open' ? 'text-green' : 'text-purple'}`} />
                                    <p className='fs-14 mb-2 px-1'>{requestForProposal.eligibility}</p>
                                </div> */}

                                <div className='row mb-2' style={{ marginTop: '1rem' }}>
                                    <div className='col-md-6'>
                                        <h6 className='fs-15'>Eligibility</h6>
                                        <div className='d-flex justify-content-start'>
                                            <Circle className={`status-icon ${requestForProposal.eligibility == 'Open' ? 'text-green' : 'text-purple'}`} />
                                            <p className='fs-14 mb-2 px-1'>{requestForProposal.eligibility}</p>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <h6 className='fs-15'>Closing Date</h6>
                                        <div className='d-flex justify-content-start'>
                                            <Today className='fs-20' color="primary" />
                                            <p className='fs-14 mb-1 px-1'>{dateForHumans(requestForProposal.tenderClosingDate)}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mb-2'>
                                    <h6 className='fs-15'>Tender description</h6>
                                    <p className='fs-15'>{requestForProposal.tenderDescription}</p>
                                </div>

                                <div className='mb-3'>
                                    <h6 className='fs-15'>Special condition</h6>
                                    <p className='fs-15'>{requestForProposal.specialCondition}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </DialogContent>
                <DialogActions sx={{ mx: 3, mb: 2 }}>
                   
                        <Button
                            sx={{ textTransform: 'capitalize', position: 'absolute', left: '6.75rem', backgroundColor: '#E6FAFD', color: '#00BABA' }}
                            onClick={()=>previewPaper()}
                            color="inherit"
                            startIcon={<Download />}
                        >
                            Download {requestForProposal.no}
                        </Button>
                    
                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        onClick={handleCloseRfq}
                        variant="contained"
                        disableElevation
                        color="inherit"
                    >
                        Cancel
                    </Button>

                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        variant="contained"
                        onClick={handleClickOpen}
                        disableElevation
                        endIcon={<ArrowForwardIosSharp sx={{ fontSize: "14px !important" }} />}
                    >
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>


            <ApplyProposal openProposal={open} handleOpenProposal={handleClickOpen} handleCloseProposal={handleClose} requestForProposal={selectedProposal} handleClose={handleClose} handleCloseRfq={handleCloseRfq} />
        </>
    )
}

export default ViewRequestForProposal