import React, { useEffect } from "react";
import { Card, CardContent, Skeleton, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { NewspaperOutlined } from "@mui/icons-material";
import { getTenders, reset } from "../../features/tender/tenderSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const TenderCard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tenders, isLoading, isError, message, openTenders } = useSelector(state => state.tender);
    const data = tenders.length > 0 && tenders.filter(res => res.eligibility === 'Open');
    const firstData = data[0];
    const handleTender = () => {
        navigate('/tenders')
    }

    useEffect(() => {
        if (isError) {
            console.log(message)
        }

        // if (!tenders.length){
        //     return 0;
        // }
        dispatch(getTenders());

        // return () => {
        //     dispatch(reset())
        // }
    }, [dispatch, isError, message]);

    return (
        <>
            {
                isLoading ? (
                    <Skeleton variant="rectangular" sx={{ display: 'flex', borderRadius: '16px', height: 110 }} />
                ) : (
                    <Card elevation={2}
                        sx={{ display: 'flex', borderRadius: '16px', maxHeight: 110, background: '#1a92cc', color: '#fff' }}
                        onClick={handleTender}
                    >
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Stack direction="row" spacing={2}>
                                <Box sx={{ flex: '1 0 auto' }}>
                                    <Typography variant="subtitle1" component="div">
                                        Open Tenders
                                    </Typography>
                                    {
                                        data === false || data === undefined || data === null || firstData?.tenderNo?.trim() == '' ? (
                                            <>
                                                <Typography component="div" variant="h3">
                                                    0
                                                </Typography>
                                            </>
                                        ) : (
                                            <><Typography component="div" variant="h3">
                                                {data.length}
                                            </Typography></>
                                        )
                                    }
                                    {/* <Typography component="div" variant="h3">
                                        {data.length}
                                    </Typography> */}
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <NewspaperOutlined sx={{ fontSize: 60, }} />
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                )
            }
        </>
    )
}

export default TenderCard;