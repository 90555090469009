import React, { useState } from 'react'
import { 
    Card,
    Button,
    Dialog, 
    DialogContent,
    DialogActions, 
    DialogTitle, 
    DialogContentText } from '@mui/material';
import { 
    Article, 
    Circle, 
    Today, 
    InsertInvitation, 
    Download, 
    Assignment, 
    ArrowForwardIosSharp, 
    NewspaperOutlined} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { dateForHumans, formDataFromObject,previewPaper } from '../../utils';
import ApplyTender from '../apply_tender/ApplyTender';
import { useDispatch, useSelector } from 'react-redux';
import { saveTenderLineApplication } from '../../features/tender/tenderSlice';



const ViewMyTender = ({ openTender,  handleCloseTender, tender }) => {
    const {user} = useSelector(state=>state.auth);
    const  navigate = useNavigate();
    const dispatch =  useDispatch();
    const [open, setOpen] = useState(false);
    const [close, setClose] = useState(false);
    const [selectedTender, setSelectedTender] = useState({});
    
     
      const handleClose = () => {
        setOpen(false);
      };
    
    return (
        <>
        <Dialog
            open={openTender}
            onClose={handleCloseTender}
            fullWidth={true}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ borderRadius: 5 }}
        >
           
            <DialogContent>

                <div className='container my-2'>
                    <div className='d-flex justify-content-start'>
                        <div className='preq-icon-wrapper'>
                            <NewspaperOutlined className='preq-icon'/>
                        </div>
                        <div className='view-preq-text-wrapper w-100'>
                            <h6 className='fs-15'>{tender.applicationNo}</h6>
                            <h6 className='fs-15'>Disqualified Status</h6>
                            <div className='d-flex justify-content-start'>
                            <Circle className={`status-icon ${tender.disqualified == 'No' ? 'text-green' : 'text-purple'}`} />
                                <p className='fs-14 mb-2 px-1'>{tender.disqualified}</p>
                            </div>

                            <div className='mb-2'>
                                <h6 className='fs-15'>Disqualified Points</h6>
                                <p className='fs-15'>{ tender.disqualifiedPoint }</p>
                            </div>

                            <div className='mb-2'>
                                <h6 className='fs-15'>Tender description</h6>
                                <p className='fs-15'>{ tender.description }</p>
                            </div>

                            <div className='mb-3'>
                                <h6 className='fs-15'>Bid Amount</h6>
                                <p className='fs-15'>{tender.bidAmount}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </DialogContent>
            <DialogActions sx={{ mx:3, mb:2 }}>
              
                <Button 
                    sx={{  textTransform: 'capitalize', position: 'absolute', left: '6.75rem',backgroundColor: '#E6FAFD', color: '#00BABA'  }} 
                    // onClick={()=>previewPaper()}
                    color="inherit"
                    startIcon={<Download />}
                >
                    Download {tender.applicationNo}
                </Button>
               
                <Button 
                    sx={{  textTransform: 'capitalize' }}
                    onClick={handleCloseTender} 
                    variant="contained" 
                    disableElevation
                    color="inherit"
                >
                    Cancel
                </Button>

               
            </DialogActions>
        </Dialog>


           {/* applytender */}
      
        </>
    )
}

export default ViewMyTender