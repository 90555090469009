import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import {
    Article,
    Circle,
    MoreVert,
    Download,
    Assignment,
    AssignmentOutlined,
    NewspaperOutlined
} from '@mui/icons-material';
import {
    Card,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Grid,
    Typography
} from '@mui/material';
import {PreviewPdf, dateForHumans, previewPaper, subString } from '../../utils';
 
const Tender = ({ tender, handleOpenTender }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <Card className='prequalification br-10'>
                <div className='row'>

                    <div className='col-sm-4 d-flex justify-content-start'>
                        <div className='preq-icon-wrapper'>
                            <NewspaperOutlined className='preq-icon' />
                        </div>
                        <div className='preq-text-wrapper'>
                            <h6 className='fs-13'>{tender.tenderNo}</h6>
                         
                            <p  onClick={() => previewPaper()} className='fs-12 doc'>{subString(tender.tenderDescription, 50)}</p>
                        
                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <div className='status d-flex justify-content-start'>
                            <Circle className={`status-icon ${tender.eligibility == 'Open' ? 'text-green' : 'text-purple'}`} />
                         
                            <p className='fs-12 px-1'>{tender.eligibility}</p>
                            
                        </div>

                    </div>
                    <div className='col-sm-1'>
                        <h6 className='fs-12 dates'>
                            {/* {dateForHumans(`${tender.tenderClosingDate} ${tender.tenderClosingTime}` )} */}
                        </h6>
                    </div>
                    <div className='col-sm-2'>
                        {/* <h6 className='fs-12 dates'>{dateForHumans(`${tender.tenderClosingDate} ${tender.tenderClosingTime}`)}</h6> */}
                        <h6 className='fs-12 dates'>{dateForHumans(tender.tenderClosingDate)}  at  {tender.tenderClosingTime}</h6>
                    </div>
                    <div className='col-sm-2'>
                        <Button
                            sx={{ p: 1, textTransform: 'capitalize', borderRadius: '.75rem', borderColor: '#e7e7e7' }}
                            className="fs-12" variant="outlined" color="inherit"
                            onClick={handleOpenTender}
                        >
                          View More
                        </Button>

                        <IconButton
                            aria-label="more"
                            id="demo-positioned-button"
                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            sx={{ ml: 3 }}
                        >
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            className="br-10"
                        >
                            
                                <MenuItem  sx={{ minWidth: '10rem' }}
                                // onClick={() => { previewPaper(); handleClose(); }}
                                onClick={() =>  handleClose() }
                                >
                                    <Download sx={{ mr: 1 }} />
                                    Download Tender
                                </MenuItem>
                        
                            <MenuItem onClick={handleOpenTender}
                             sx={{ minWidth: '10rem' }}><Assignment sx={{ mr: 1 }} />Apply Tender
                             </MenuItem>
                        </Menu>

                    </div>
                </div>
            </Card>
        </>
    )
}

export default Tender