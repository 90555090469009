import React from 'react';
import Header from '../header/Header';
import './sidebar.css';
import {
    HomeOutlined, AutoStoriesOutlined, ExitToAppOutlined, SupportOutlined,
    People, ArrowRight, NotificationsOutlined, Settings, Lock, NoteAlt, NoteAltOutlined, NewspaperOutlined, RequestQuoteOutlined, TextSnippetOutlined, DescriptionOutlined, AssignmentOutlined
} from '@mui/icons-material';
import Profile from '../../components/profile/Profile';
import { Button, Toolbar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, reset } from '../../features/auth/authSlice';
import { cleanupFormPreq } from '../../features/preqs/preqSlice';
import storage from 'redux-persist/lib/storage';
import { cleanupFormPurchaseHeader } from '../../features/purchase/Header/ViewPurchaseSlice';

function Sidebar({handleDrawerToggle}) {
    const { user } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const onLogout = () => {
        dispatch(logout())
        dispatch(reset())
        dispatch(cleanupFormPreq())
        dispatch(cleanupFormPurchaseHeader())
        storage.removeItem('persist:root');
        navigate('/')
    }
    return (

        <div className="box-wrapper">
            <div class="scroll">
                <nav className="sidebar" >
                    
                    <div class="sidebar-header">
                        <h4 class="sidebar-title text-primary fw-700">E-Procurement
                            <NotificationsOutlined
                                sx={{ ml: 6 }} />
                        </h4>
                    </div>
                      <div className="sidebar-body">  
                         <ul class="list-unstyled components items" id="menu">

                            <li onClick={handleDrawerToggle}>
                                <Link to="/home">
                                    <HomeOutlined
                                        sx={{ mb: .75, mr: 1, fontSize: '21px' }}
                                    />
                                    Home</Link>
                            </li>  

                          <li class="ssl">
                                <Link to="#srSubmenu" data-bs-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                                    <AssignmentOutlined
                                        sx={{ mb: .75, mr: 1, fontSize: '21px' }}
                                    />
                                    Supplier Registration</Link>

                                <ul class="collapse list-unstyled dropdown-content" id="srSubmenu" data-bs-parent="#menu">
                                    <li onClick={handleDrawerToggle}>
                                        <Link to="./prequalifications">All Supplier Regs...</Link>
                                    </li>
                                    <li onClick={handleDrawerToggle}>
                                        <Link to="/my-prequalifications">My Supplier Regs...</Link>
                                    </li>
                                </ul>
                            </li>  


                            <li class="ssl">
                                <Link to="#tendersSubmenu" data-bs-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                                    <NewspaperOutlined
                                        sx={{ mb: .75, mr: 1, fontSize: '21px' }}
                                    />
                                    Tenders</Link>
                                <ul class="collapse list-unstyled dropdown-content" id="tendersSubmenu" data-bs-parent="#menu">
                                    <li onClick={handleDrawerToggle}>
                                        <Link to="/tenders">All Tenders</Link>
                                    </li>
                                    <li onClick={handleDrawerToggle}>
                                        <Link to="/my-tenders">My Tenders</Link>
                                    </li>
                                </ul>
                            </li>

                            <li class="ssl">
                                <Link to="#rfpSubmenu" data-bs-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                                    <DescriptionOutlined
                                        sx={{ mb: .75, mr: 1, fontSize: '21px' }}
                                    />
                                    Request for Proposal</Link>
                                <ul class="collapse list-unstyled dropdown-content" id="rfpSubmenu" data-bs-parent="#menu">
                                    <li onClick={handleDrawerToggle}>
                                        <Link to="/rfp">All RFPs</Link>
                                    </li>
                                    <li onClick={handleDrawerToggle}>
                                        <Link to="/my-rfps">My RFPs</Link>
                                    </li>
                                </ul>
                            </li>

                            <li onClick={handleDrawerToggle}>
                                <Link to="/purchase-quotes">
                                    <RequestQuoteOutlined
                                        sx={{ mb: .75, mr: 1, fontSize: '21px' }}
                                    />
                                    Quotations</Link>
                            </li>
                            <li class="" onClick={handleDrawerToggle}>
                                <Link to="/profile">
                                    <Settings
                                        sx={{ mb: .75, mr: 1, fontSize: '21px' }} />
                                    Manage account</Link>
                            </li>
                            <li >
                                <Link to="#">
                                    <SupportOutlined
                                        sx={{ mb: .75, mr: 1.2, fontSize: '21px' }}
                                    />
                                    Help</Link>
                            </li>
                            <li onClick={handleDrawerToggle}>
                                <Link to="/" onClick={onLogout}>
                                    <ExitToAppOutlined
                                        sx={{ mb: .75, mr: 1.2, fontSize: '21px' }}
                                    />
                                    Signout
                                </Link>
                            </li>  
                         </ul>
                    </div>  
                </nav>
            </div>
        </div>
    )
}
export default Sidebar