import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyTenders, reset } from "../../features/tender/tenderSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MyTender from "../../components/tender/MyTender";
import TablePaginationActions from "../../components/pagination/TablePaginationActions";
import ViewMyTender from "../../components/view_myTender/ViewMyTender";
import { Grid } from "@mui/material";

const MyTenders = () => {
  const { myTenders, isLoading } = useSelector((state) => state.tender);
  const newTenders =myTenders[0]
  const [open, setOpen] = useState(false);
  const [selectedTender, setSelectedTender] = useState({});
  const [value, setValue] = useState(0);
  const handleClickOpen = (tender) => {
    setOpen(true);
    setSelectedTender(tender);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  /**
   * :: Initialize pagination - starts here
   */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - myTenders.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /** ::  Initialize pagination - ends here */

  useEffect(() => {
    dispatch(getMyTenders());
    // return () => {
    //   dispatch(reset());
    // };
  }, [dispatch]);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          {isLoading ? (
            <Skeleton className="my-2" count={6} height={70} inline={false} />
          ) : (
            <>
              {newTenders?.tenderNo?.trim() == '' ? (
                <h2>No data available</h2>
              ) : (
                <>
                  <Grid container spacing={2} sx={{ color: "#1976D2" }}>
                    <Grid item xs={5}>
                      <h6 style={{ paddingLeft: "20px" }}>Title </h6>{" "}
                    </Grid>
                    <Grid item xs={4}>
                      <h6>Status</h6>{" "}
                    </Grid>
                    <Grid item xs={2}>
                      <h6>Actions</h6>
                    </Grid>
                  </Grid>

                  <TableBody>
                    {myTenders &&
                      (rowsPerPage > 0
                        ? Array.from(myTenders).slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : myTenders
                      ).map((tender) => (
                        /*  tender - 1 unit */
                        <MyTender
                          tender={tender}
                          handleOpenTender={() => handleClickOpen(tender)}
                        />
                      ))}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  <TableFooter>
                    {Object.keys(myTenders).length > 0 && (
                      <TableRow>
                        {isLoading ? (
                          <Skeleton
                            className="my-2"
                            height={25}
                            inline={false}
                          />
                        ) : (
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              15,
                              { label: "All", value: -1 },
                            ]}
                            colSpan={3}
                            count={myTenders.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            // SelectProps={{ inputProps: { 'aria-label': 'rows per page', }, native: true, }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        )}
                      </TableRow>
                    )}
                  </TableFooter>
                </>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      <ViewMyTender
        openTender={open}
        handleOpenTender={handleClickOpen}
        handleCloseTender={handleClose}
        tender={selectedTender}
      />
    </>
  );
};

export default MyTenders;
