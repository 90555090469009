import { ThemeProvider } from '@emotion/react';
import { AssignmentOutlined, Circle } from '@mui/icons-material';
import { Box, Button, Card, Chip, createTheme } from '@mui/material';
import React from 'react'
import { dateForHumans, subString } from '../../utils';

const MyPrequalification = ({prequalification,handleOpenPreq}) => {


    return (
        <>
            <Card className='prequalification br-10'>
                <div className='row'>
                    <div className='col-sm-5 d-flex justify-content-start'>
                        <div className='preq-icon-wrapper'>
                            <AssignmentOutlined className='preq-icon'/>
                        </div>
                        <div className='preq-text-wrapper'>
                            <h6 className='fs-13'>{prequalification.applicationNo}</h6>
                            <p className='fs-12'>{subString(prequalification.description, 60) }</p>
                        </div>
                    </div>

                    <div className='col-sm-4'>
                    <h6 className='fs-12 dates'>{
                          prequalification?.status == 'OPEN' ? 
                         <Chip label='OPEN' sx={{background:'#0ff00d',color:'#fff',minWidth:'100px', }}/>   : 
                         prequalification?.status == 'DRAFT' ? 
                         <Chip label='DRAFT' sx={{background:'#673ab7',color:'#fff',minWidth:'100px'}}/> :
                         prequalification?.status == 'SUBMITTED' ? 
                         <Chip label='SUBMITTED' sx={{background:'#3d6b3d',color:'#fff'}}/> :
                         prequalification?.status == 'REGISTERED' ? 
                         <Chip label='REGISTERED' sx={{background:'#3d6b3d',color:'#fff'}}/> :
                         <Chip label='NOT REGISTERED' sx={{background:'#3d6b3d',color:'#fff'}}/> 
                  }
                  </h6>
                    </div>
                    <div className='col-sm-2'>
                        <Button 
                            sx={{ p: 1, textTransform: 'capitalize', borderRadius: '.75rem', borderColor: '#e7e7e7' }} 
                            className="fs-12" variant="outlined" color="inherit"
                            onClick={handleOpenPreq}
                        >
                         View More
                        </Button>
                    </div>
                </div>
            </Card>

        </>
    )
}

export default MyPrequalification