import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import { useMediaQuery } from 'react-responsive';
import Header2 from '../../components/header/Header2';

function WithNav() {
    const isMobile = useMediaQuery({ query: "(max-width:480px)" });
    return (

        <div className="cont">
            <div style={{display:isMobile ? "none" : "flex"}}>
            <Sidebar />
            </div>
            <div className="box">
                <div className="content">   

                    <Header2 />

                    <div className='main-content'>
                    
                        <Outlet />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default WithNav