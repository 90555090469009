import axios from "axios";

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;

// save View Purchase Attachement
const savePurchaseAttachment = async (userDataProfile) => {
    const config={
        headers: {
            'Accept': 'application/json',}
    }
    const response=await axios.post(`${API_URL}/supplier/saveSupplierProfileAttachment.action`,userDataProfile, config)
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : response.data;
}

const purchaseAttachmentService={
    savePurchaseAttachment,
}
export default purchaseAttachmentService