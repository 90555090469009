import { Avatar, Box, Card, CardContent, Divider, Grid, Paper } from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import './allProfileInfo.css'
import { Edit, EmailTwoTone, Info, MailOutline, PhonelinkRingTwoTone, PinDropTwoTone } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { reset,getProfile } from '../../../../features/profile/profileSlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
 
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const AllProfileInfo = ({ handleChange,value}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const editPersonalDetails=()=>{
        navigate("/profile/step2")
    }

    const {user}=useSelector((state)=>state.auth)
    const {profile,isLoading, isError, isSuccess, message} = useSelector((state)=> state.profile)

  useEffect(() => {
    if (isError) {

    }
    if (!user) {
      navigate('/login')
    }
    dispatch(getProfile())
    if(!isError){
      dispatch(reset())
    }
    // return () => {
    //   dispatch(reset())
    // }

  }, [user, navigate, isError, message, dispatch])

 
    return (
        <>
        <div>
        {
            isLoading ? (
                <Skeleton
              className="my-0"
              height={50}
              inline={false}
            />
            ):(
                <Box sx={{ flexGrow: 1 ,p:0}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Card className="cardProf br-10">
                            <div className="allProfile" style={{ padding: '13px' }}>
                                <div className="allProfileInfo">
                                    {/* <img
                                        src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                                        alt=""
                                        className="allShowImg"
                                    /> */}
                                    
                                    <Avatar  {...stringAvatar(`${user.usrFirstName} ${user.usrLastName}`)} />
                                    <div className="allShowTopTitle">
                                        {/* <span className="allShowUsername">{profile?.usrpContactPerson}</span> */}
                                        <span className="allShowUserTitle">
                                        { user ? `${user.usrFirstName} ${user.usrLastName}` : 'Ikea Store' }
                                        </span>
                                    </div>
                                </div>
    
                                <div className="infoDesc" style={{ backgroundColor: 'rgb(33, 150, 243)', borderRadius: '9px', padding: '4px 15px', color: '#fff' }}>
                                    Supplier
                                </div>
                            </div>
                            <Divider />
    
                            <CardContent>
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 0px'}}>
                                    <Grid item xs={12} md={7}>
                                        <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                                            <EmailTwoTone />
                                            <span className="allProfileTitle">Email</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5} classname="gridItem">
                                        <div className="emailDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpEmail}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <hr style={{ margin: '2px 0px', color: 'rgb(175, 170, 170)', fontSize: '14px', fontWeight: '600' }} />
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 0px' }}>
                                    <Grid item xs={12} md={7}>
                                        <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                                            <PhonelinkRingTwoTone />
                                            <span className="allProfileTitle">Phone</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <div className="emailDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span> {profile?.usrpMobileNumber} </span>
                                        </div>
                                    </Grid>
                                </Grid>
                                <hr style={{ margin: '2px 0px', color: 'rgb(175, 170, 170)', fontSize: '14px', fontWeight: '600' }} />
    
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 0px' }}>
                                    <Grid item xs={12} md={7}>
                                        <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                                            <PinDropTwoTone />
                                            <span className="allProfileTitle">Location</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <div className="emailDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpCountry} </span>
                                        </div>
                                    </Grid>
                                </Grid>
                                <hr style={{ margin: '2px 0px', color: 'rgb(175, 170, 170)', fontSize: '14px', fontWeight: '600' }} />
                            </CardContent>
                        </Card>
                    </Grid>
    
    
    
                    <Grid item xs={12} md={8}>
                        <Card className="cardProf br-10">
                            <div className="allProfile" style={{ padding: '13px' }}>
                                <div className="fw-500">
                                Personal Details
                                </div>
    
                                <div className="infoDesc">
                                    <Edit onClick={editPersonalDetails}/>
                                </div>
                            </div>
                            <Divider />
    
                            <CardContent>
                                
                                {/* <hr style={{ margin: '2px 0px', color: 'rgb(175, 170, 170)', fontSize: '14px', fontWeight: '600' }} /> */}
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                                    <Grid item xs={11} md={3}>
                                        <div className="" >
                                            Contact Person
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span> {profile?.usrpContactPerson} </span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={3}>
                                        <div className="" >
                                            Phone
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpMobileNumber}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={3}>
                                        <div className="" >
                                            Email
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpEmail}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                            </CardContent>
                        </Card>
    
                        <Card sx={{marginTop:'10px'}} className="cardProf br-10"> 
                            <div className="allProfile" style={{ padding: '13px' }}>
                                <div className="fw-500">
                                   Company Details
                                </div>
    
                                <div className="infoDesc">
                                    <Edit  onClick={editPersonalDetails} />
                                </div>
                            </div>
                            <Divider />
    
                            <CardContent>
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                        Company Name
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpCompanyName}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
    
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                        Postal Address
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpPostalAddress}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                        Website
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpWebsite}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                       KRA PIN Cert No#
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpKraPinCertNo}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                          VAT Cert No#
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpVatCertNo}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                           Company Registration Cert No#
                                        </div>
                                    </Grid>
     
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpCompanyRegCertNo}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                           Physical Address
                                        </div>
                                    </Grid>
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpPhysicalAddress}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                           Country
                                        </div>
                                    </Grid>
     
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpCountry}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                           County
                                        </div>
                                    </Grid>
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpCounty}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                          Bussiness Nature
                                        </div>
                                    </Grid>
     
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpBusinessNature}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                         Legal Status
                                        </div>
                                    </Grid>
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpLegalStatus}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                        Name of your Bankers
                                        </div>
                                    </Grid>
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpBankersName}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                        Maximum Value Your Bussiness can hold
                                        </div>
                                    </Grid>
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpMaxBusinessValue}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
    
                                <Grid container sx={{ display: 'flex', alignItems: 'center', margin: '15px 10px' }}>
                                    <Grid item xs={11} md={5}>
                                        <div className="" >
                                       Trade Terms
                                        </div>
                                    </Grid>
     
                                    <Grid item xs={1} md={1}>
                                        <div className="" >
                                            :
                                        </div>
                                    </Grid>
    
                                    <Grid item xs={12} md={4}>
                                        <div className="aboutDesc" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{profile?.usrpTradeTerms}</span>
                                        </div>
                                    </Grid>
                                </Grid>
    
                                
                               
    
                            </CardContent>
                        </Card>
    
                    </Grid>
    
              
    
                </Grid>
            </Box>
            )
        }
  
    
  </div>
  </>
          
    
    )
}

export default AllProfileInfo


function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            fontSize: '16px',
            width: 34,
            height: 34,
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
