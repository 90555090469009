import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getMyRfps, reset } from '../../features/requestForProposal/rfpSlice';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import MyRequestForProposal from '../../components/requestForProposal/MyRequestForProposal';
import { Grid } from '@mui/material';
import TablePaginationActions from '../../components/pagination/TablePaginationActions';
import ViewMyRequestForProposal from '../../components/viewMyRequestForProposal/ViewMyRequestForProposal';



const MyRequestForProposals = () => {
  const { myRfps, isLoading, } = useSelector(state => state.rfp);
  const dispatch = useDispatch();

  const newRfps =  myRfps[0];
 

  const [open, setOpen] = useState(false);
  const [selectedRfp, setSelectedRfp] = useState({});
  const handleClickOpen = (rfp) => {
    setOpen(true);
    setSelectedRfp(rfp);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
 * :: Initialize pagination - starts here
 */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - myRfps.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  useEffect(() => {
    // console.log('filteredRfps', filteredRfps);
    dispatch(getMyRfps())

    // return () => {
    //   dispatch(reset())
    // }
  }, []);
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          {isLoading ? (
            <Skeleton
              className="my-2"
              count={6}
              height={70}
              inline={false}
            />
          ) : (
            <>
              {
                newRfps?.applicationNo?.trim() == '' ? (
                  <h2>No data available</h2>
                ) : (
                  <>
                    <Grid container spacing={2} sx={{ color: '#1976D2' }} >
                      <Grid item xs={5}><h6 style={{ paddingLeft: '20px' }}>Title </h6> </Grid>
                      <Grid item xs={4}><h6 >Status</h6> </Grid>
                      <Grid item xs={2}><h6 >Actions</h6></Grid>
                    </Grid>



                    <TableBody>
                      {myRfps && (rowsPerPage > 0
                        ? Array.from(myRfps).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : myRfps
                      ).map((rfp) => (
                        /*  rfp - 1 unit */
                        <MyRequestForProposal
                          rfp={rfp}
                          handleOpenRfp={() => handleClickOpen(rfp)}
                        />
                      ))}

                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    <TableFooter>
                      {
                        Object.keys(myRfps).length > 0 && (
                          <TableRow>
                            {isLoading ? (
                              <Skeleton className="my-2" height={25} inline={false} />
                            ) : (
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 15, { label: 'All', value: -1 }]}
                                colSpan={3}
                                count={myRfps.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                // SelectProps={{ inputProps: { 'aria-label': 'rows per page', }, native: true, }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                              />
                            )}
                          </TableRow>
                        )
                      }
                    </TableFooter>
                  </>
                )
              }



            </>
          )}
        </Table>
      </TableContainer>
      <ViewMyRequestForProposal openRfp={open} handleCloseRfp={handleClose} rfp={selectedRfp} />
    </>
  )
}

export default MyRequestForProposals