import React, { useState, useEffect, useMemo } from "react";
import { Box, Tab, Typography, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { AccountCircle, ArticleRounded, Edit } from "@mui/icons-material";
import EditPersonalDetails from "./EditPersonalDetails/EditPersonalDetails";
import AllProfileInfo from "./AllProfileInfo/AllProfileInfo";
import './page.css'
import Attachments from "./Attachments/Attachments";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Page = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const paths = useMemo(() => ["/profile/step1", "/profile/step2", "/profile/step3"], []);

  useEffect(() => {
    const value = paths.indexOf(pathname);
    setValue(value !== -1 ? value : 0);
  }, [pathname, paths]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(paths[newValue]);
  };
 

  return (
    <div className=' container my-2'>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className="simple-tab"
          aria-label="basic tabs example"
        >
          <Tab
            label="Profile"
            icon={<AccountCircle/>} iconPosition="start"
            {...a11yProps(0)}
            component={Link}
            to={`/profile/step1`}
          />
          <Tab
            icon={<Edit/>} 
            iconPosition="start" 
            label="Edit Details" 
            {...a11yProps(1)}
            component={Link}
            to={`/profile/step2`}
          />
            {/* <Tab
            icon={<ArticleRounded/>} 
            iconPosition="start" 
            label="Attachments" 
            {...a11yProps(2)}
            component={Link}
             to={`/profile/step3`}
          /> */}
        </Tabs>
      </Box>


      <TabPanel value={value} index={0} className="simple-tab-panel">
        <AllProfileInfo />
      </TabPanel>
      <TabPanel value={value} index={1} className="simple-tab-panel" >
        <EditPersonalDetails/>
      </TabPanel>
      {/* <TabPanel value={value} index={2} className="simple-tab-panel" >
        <Attachments/>
      </TabPanel> */}


      
    </div>
  );
};

export default Page;
