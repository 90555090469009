import * as React from 'react';
import { useState } from 'react';
import './requestForProposals.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TablePaginationActions from '../../components/pagination/TablePaginationActions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import 'react-loading-skeleton/dist/skeleton.css'
import TabPanel from '../../components/tab/TabPanel';
import { getRfp, reset, setFilteredRfps } from '../../features/requestForProposal/rfpSlice';
import RequestForProposal from '../../components/requestForProposal/RequestForProposal';
import ViewRequestForProposal from '../ viewRequestForProposal/ViewRequestForProposal';
import { Grid } from '@mui/material';

const RequestForProposals = () => {
  const { requestForProposals, filteredRfps, isLoading, isError, message } = useSelector(state => state.rfp);
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false);
  const [selectedRfp, setSelectedRfp] = useState({});
  const [value, setValue] = useState(0);
 
const firstRfp = filteredRfps[0];
 
 

  const handleClickOpen = (requestForProposal) => {
    setOpen(true);
    setSelectedRfp(requestForProposal);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e, val) => {
    setValue(val);
    switch (val) {
      case 1:
        dispatch(setFilteredRfps(requestForProposals.filter(rfp => rfp.typeOfSupply == "Supply of Goods")));
        break;
      case 2:
        dispatch(setFilteredRfps(requestForProposals.filter(rfp => rfp.typeOfSupply == " Provision of Services")));
        break;
      case 3:
        dispatch(setFilteredRfps(requestForProposals.filter(rfp => rfp.typeOfSupply == "Provisions of Works")));
        break;
      default:
        dispatch(setFilteredRfps(requestForProposals));
        break;
    }
  };

  /**
   * :: Initialize pagination - starts here
   */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRfps.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    // console.log('filteredRfps', filteredRfps);
    dispatch(getRfp())

    // return () => {
    //   dispatch(reset())
    // }
  }, []);

  /** ::  Initialize pagination - ends here */

  return (
    <>

      <div className='container my-2'>


        {isLoading ? (
          <Skeleton
            className="my-0"
            height={50}
            inline={false}
          />
        ) : (
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} className="tab-header" aria-label="basic tabs example">
              <Tab label="All" />
              <Tab label="Supply of Goods" />
              <Tab label="Provision of Services" />
              <Tab label="Provisions of Works" />
            </Tabs>
          </Box>
        )}
        <TabPanel value={value} index={value}>

          <TableContainer>

            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              {isLoading ? (
                <Skeleton
                  className="my-2"
                  count={6}
                  height={70}
                  inline={false}
                />
              ) : (
                <>
                {
                  Object.keys(filteredRfps).length > 0 && firstRfp?.tenderNo?.trim() != '' ? ( 
                    <Grid container spacing={2}  sx={{color:'#1976D2'}}>
                    <Grid item xs={4}><h6 style={{ paddingLeft: '20px' }}>Title </h6> </Grid>
                    <Grid item xs={3}><h6 >Eligibility</h6> </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}><h6 >Closing Date</h6></Grid>
                    <Grid item xs={2}><h6 >Actions</h6></Grid>
                  </Grid>
                  ) :(
                    <h2>No data available</h2>
                  )
                }
                 
                  <TableBody>
                    {(filteredRfps && firstRfp?.tenderNo?.trim() != '') && (
                      rowsPerPage > 0 ? Array.from(filteredRfps).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRfps).map((requestForProposal) => (
                        <RequestForProposal
                          requestForProposal={requestForProposal}
                          openRfq={open}
                          handleOpenRfq={() => handleClickOpen(requestForProposal)}
                          handleCloseRfq={handleClose}
                        />
                      ))}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </>
              )}

              <TableFooter>
                {
                  (Object.keys(filteredRfps).length > 0 && firstRfp?.tenderNo?.trim() != '') && (
                    <TableRow>
                    {isLoading ? (
                      <Skeleton className="my-2" height={25} inline={false} />
                    ) : (
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 15, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={filteredRfps.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        // SelectProps={{ inputProps: { 'aria-label': 'rows per page', }, native: true, }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    )}
                  </TableRow>
                  )
                }
               
              </TableFooter>
            </Table>
          </TableContainer>

        </TabPanel>
      </div>

      {/* view requestForProposal */}
      <ViewRequestForProposal openRfq={open} handleOpenRfq={handleClickOpen} handleCloseRfq={handleClose} requestForProposal={selectedRfp} />


    </>
  )
}

export default RequestForProposals