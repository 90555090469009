import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { getRfqLineSpecification } from '../../../features/requestForQuotation/Lines/ViewRequestQuotationLinesSlice';
import { useDispatch, useSelector } from 'react-redux';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));




function SpecificationsModal({ open, handleClose, prodd }) {
    const { rfqLineSpecifications, isLoading } = useSelector((state) => state.viewRequestQuotationLines)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRfqLineSpecification({
            documentNo: prodd?.docNo,
            itemNo: prodd?.no
        }))
    }, [prodd])

    return (
        <div>
            {
                isLoading ? (
                    <Skeleton className="my-1" count={3} height={50} />
                ) : (
                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        fullWidth={true}
                        maxWidth="md"
                    >
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            {prodd?.rfqDesc} ({prodd?.docNo})
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Close />
                        </IconButton>
                        <DialogContent dividers>
                            <TableContainer component={Paper} elevation={0}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No</TableCell>
                                            <TableCell align="left">Specifications</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rfqLineSpecifications?.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" >
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell align="left">{row?.specification}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                onClick={handleClose}
                                variant="contained"
                                disableElevation
                                color="inherit"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>
                )
            }

        </div>
    )
}

export default SpecificationsModal