import React, { useEffect, useRef, useState } from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
    Button,
    CssBaseline, Divider,
    FormControl,
    FormLabel,
    ThemeProvider,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, CircularProgress, Chip, Avatar
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import BootstrapInput from '../../components/bootstrap/BootstrapInput';
import BootstrapSelect from '../../components/bootstrap/BootstrapSelect';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AttachFile, Create, Delete, Face, FilePresent, FilePresentOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSupplierProfileAttachment, getApplicationRequiredDocuments, getSupplierAttachments, getSupplierProfile, reset, saveSupplierAttachment, setSupplierProfile } from '../../features/preqs/preqSlice';
import { formDataFromObject, generateAttachment } from '../../utils';
import Form from 'react-bootstrap/Form';
import { getProfile } from '../../features/profile/profileSlice';
import BootstrapSelectNav from '../../components/bootstrap/BootstrapSelectNav';

import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
import PreqAttachDeleteModal from '../../components/prequalification/PreqAttachDeleteModal';


const countriesData = [
    { id: 'Uganda', value: 'Uganda' },
    { id: 'Tanzania', value: 'Tanzania' },
    { id: 'Rwanda', value: 'Rwanda' },
    { id: 'Kenya', value: 'Kenya' },
    { id: 'Burundi', value: 'Burundi' },
    { id: 'South Sudan', value: 'South Sudan' },
    { id: 'D.R. Congo', value: 'D.R. Congo' },
];

const countiesData = [
    { id: 'Nairobi', value: 'Nairobi' },
    { id: 'Mombasa', value: 'Mombasa' },
    { id: 'Kisumu', value: 'Kisumu' },
    { id: 'Nakuru', value: 'Nakuru' },
    { id: "Murang'a", value: "Murang'a" },
    { id: 'Embu', value: 'Embu' },
    { id: 'Nyeri', value: 'Nyeri' },
];



const BusinessProfile = ({ step, loading }) => {

    const theme = createTheme();

    const { supplierProfile, appPrequalification, suppPreqDocs, preqRequiredDoc,
        isLoading, isProcessing, isError, message, isSuccessDeleteAtt, isLoadingAttachments,appNo,viewMode,docNo } =
        useSelector(state => state.preq);
    const dispatch = useDispatch()
    const { profile, }
        = useSelector((state) => state.profile)

        console.log("appNo redux",appNo)
        console.log("viewMode redux",viewMode)
        console.log("docNo redux",docNo)
        console.log("appPrequalification.applicationNo",appPrequalification.applicationNo)
        

    const [countries, setCountries] = useState(countriesData)
    const [counties, setCounties] = useState(countiesData);
    const [formData, setFormData] = useState(supplierProfile);
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedFile, setSelectedFile] = useState();

    const [docFormData, setDocFormData] = useState({
        entryNo: 0,
        description: '',
        applicationNo: '',
        file1: null,
    });

    const file1Ref = useRef(null);

    const { usrpId, usrpCompanyName, usrpContactPerson, usrpMobileNumber, usrpPhysicalAddress, usrpPostalAddress,
        usrpEmail, usrpWebsite, usrpLegalStatus, usrpBusinessNature, usrpKraPinCertNo, usrpPinCertNo, usrpVatCertNo, usrpTaxComplianceCertNo,
        usrpCompanyRegCertNo, usrpMaxBusinessValue, usrpBankersName, usrpTradeTerms, usrpCounty, usrpCountry,
    } = formData;


    const { entryNo, description, applicationNo, file1 } = docFormData;




    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
        setIsUpdating(true)
    }

    const handleDocChange = (e) => {
        setDocFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleFile = (e) => {
        // console.log(e.target.files[0]);
        setSelectedFile(e.target.files[0]);
    }

    const previewFile = () => {
        // TODO::
    }

    const handleDocSubmit = async() => {
        const document = {
            ...docFormData,
            file1: selectedFile,
            applicationNo:!viewMode? appPrequalification.applicationNo : appNo,
            type: 1,

        };
        const _document = formDataFromObject(document);


        if (!document.description) {
            toast.warning("select what to attach")
        } else {
            const res =  await dispatch(saveSupplierAttachment(_document));
            console.log("res",res)
            if (res.type == "prequalification/attachment/save/rejected"){
                toast.warning("Record already exists")
            }
        }


        setDocFormData({
            entryNo: 0,
            description: '',
            applicationNo: '',
            file1: null,

        })
        file1Ref.current.value = null;
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [prodd, setProdd] = useState()




    useEffect(() => {
        if (!isUpdating) {
            dispatch(getSupplierProfile());
            dispatch(getApplicationRequiredDocuments({
                documentNo:!viewMode ?  appPrequalification.tenderNo : docNo  
            }));
            dispatch(getSupplierAttachments({
                no:!viewMode ?  appPrequalification.applicationNo  : appNo,  
                type: 1
            }));
        } else {
            dispatch(setSupplierProfile(formData));
        }
        return () => {
            dispatch(reset())
        }
    }, [isUpdating, formData, dispatch])

    useEffect(() => {
        dispatch(getProfile())
    }, [])

 

    return (
        <React.Fragment>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <Box sx={{ fontWeight: 'bold', my: 0, mx: 2, boxShadow: 1, p: 2, pt: 0, borderRadius: '5px', }}>
                    <Typography variant={"h6"} sx={{ my: 2, fontSize: '0.875rem' }}>
                        Confidential Registration of Business
                    </Typography>

                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Grid container rowSpacing={1.25} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item md={6} xs={12}>
                                {/* { isLoading ? ( <Skeleton height={35} />) :( */}
                                <BootstrapInput
                                    type="text"
                                    id="usrpCompanyName"
                                    name="usrpCompanyName"
                                    value={usrpCompanyName ? usrpCompanyName : supplierProfile.usrpCompanyName}
                                    handleChange={handleChange}
                                    label="Company Name*"
                                    isLoading={isLoading}
                                />
                                {/* )} */}
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpPhysicalAddress"
                                    name="usrpPhysicalAddress"
                                    value={usrpPhysicalAddress ? usrpPhysicalAddress : supplierProfile.usrpPhysicalAddress}
                                    handleChange={handleChange}
                                    label="Physical address*"
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpMobileNumber"
                                    name="usrpMobileNumber"
                                    value={usrpMobileNumber ? usrpMobileNumber : supplierProfile.usrpMobileNumber}
                                    handleChange={handleChange}
                                    label="Telephone Number*"
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <BootstrapSelect
                                    id="usrpCountry"
                                    name="usrpCountry"
                                    selected={usrpCountry ? usrpCountry : supplierProfile.usrpCountry}
                                    handleChange={handleChange}
                                    label="Country*"
                                    options={countries}
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpContactPerson"
                                    name="usrpContactPerson"
                                    value={usrpContactPerson ? usrpContactPerson : supplierProfile.usrpContactPerson}
                                    handleChange={handleChange}
                                    label="Contact Person"
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <BootstrapSelect
                                    id="usrpCounty"
                                    name="usrpCounty"
                                    selected={usrpCounty ? usrpCounty : supplierProfile.usrpCounty}
                                    handleChange={handleChange}
                                    label="County*"
                                    options={counties}
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpEmail"
                                    name="usrpEmail"
                                    value={usrpEmail ? usrpEmail : supplierProfile.usrpEmail}
                                    handleChange={handleChange}
                                    label="Email*"
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpBusinessNature"
                                    name="usrpBusinessNature"
                                    value={usrpBusinessNature ? usrpBusinessNature : supplierProfile.usrpBusinessNature}
                                    handleChange={handleChange}
                                    label="Nature of Business*"
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpPostalAddress"
                                    name="usrpPostalAddress"
                                    value={usrpPostalAddress ? usrpPostalAddress : supplierProfile.usrpPostalAddress}
                                    handleChange={handleChange}
                                    label="Postal Address"
                                    isLoading={isLoading}
                                />
                            </Grid>
                            {/* <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpLegalStatus"
                                    name="usrpLegalStatus"
                                    value={usrpLegalStatus ? usrpLegalStatus : supplierProfile.usrpLegalStatus}
                                    handleChange={handleChange}
                                    label="Legal Status"
                                    isLoading={isLoading}
                                />
                            </Grid> */}
                            <Grid item md={6} xs={12} >
                                <BootstrapInput
                                    type="text"
                                    id="usrpWebsite"
                                    name="usrpWebsite"
                                    value={usrpWebsite ? usrpWebsite : supplierProfile.usrpWebsite}
                                    handleChange={handleChange}
                                    label="Website"
                                    isLoading={isLoading}
                                />
                            </Grid>
                            {/* <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpBankersName"
                                    name="usrpBankersName"
                                    value={usrpBankersName ? usrpBankersName : supplierProfile.usrpBankersName}
                                    handleChange={handleChange}
                                    label="Name of your Bankers"
                                    isLoading={isLoading}
                                />
                            </Grid> */}
                            <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpKraPinCertNo"
                                    name="usrpKraPinCertNo"
                                    value={usrpKraPinCertNo ? usrpKraPinCertNo : supplierProfile.usrpKraPinCertNo}
                                    handleChange={handleChange}
                                    label="KRA Pin Cert No#"
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpMaxBusinessValue"
                                    name="usrpMaxBusinessValue"
                                    value={usrpMaxBusinessValue ? usrpMaxBusinessValue : supplierProfile.usrpMaxBusinessValue}
                                    handleChange={handleChange}
                                    label="Max value your business can hold"
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpVatCertNo"
                                    name="usrpVatCertNo"
                                    value={usrpVatCertNo ? usrpVatCertNo : supplierProfile.usrpVatCertNo}
                                    handleChange={handleChange}
                                    label="VAT Cert No#"
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <BootstrapInput
                                    type="text"
                                    id="usrpTradeTerms"
                                    name="usrpTradeTerms"
                                    value={usrpTradeTerms ? usrpTradeTerms : supplierProfile.usrpTradeTerms}
                                    handleChange={handleChange}
                                    label="Trade Terms"
                                    isLoading={isLoading}
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant={"h6"} sx={{ my: 2, fontSize: '0.875rem' }}>
                        Attachments copies for the business <Typography variant={"span"} sx={{ my: 2, fontSize: '13px', color: '#777' }}>
                            eg (KRA pin, Business Permit )</Typography>
                    </Typography>

                    <Grid container rowSpacing={1.25} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item md={6} xs={12}>
                            <BootstrapSelectNav
                                id="description"
                                name="description"
                                selected={description}
                                handleChange={handleDocChange}
                                label="Choose what to attach*"
                                options={preqRequiredDoc}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {/* <BootstrapInput 
                            id="file1" 
                            name="file1"
                            ref={file1Ref}
                            handleChange={handleFile}
                            type='file'
                            label="Attach file*" 
                        /> */}

                            <Form.Group className='bootstrap-input  mb-1' controlId="formFile">
                                <Form.Label className="mb-0">Attach file*</Form.Label>
                                <Form.Control
                                    type="file"
                                    ref={file1Ref}
                                    onChange={handleFile}
                                />
                            </Form.Group>

                        </Grid>
                        <Grid item md={6} xs={12} >
                        </Grid>
                        <Grid item md={6} xs={12} sx={{ mt: 1 }} >
                            <FormControl fullWidth variant={"standard"}>
                                <FormLabel htmlFor="my-input" variant={"subtitle"}>NOTE: You can add as many attachments as possible*</FormLabel>
                                {!isProcessing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ textTransform: 'capitalize' }}
                                        onClick={handleDocSubmit}
                                    >
                                        Add Attachment
                                    </Button>
                                ) : (
                                    <Button disabled variant="contained" sx={{ textTransform: 'none' }}>
                                        <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                                    </Button>
                                )}
                            </FormControl>

                        </Grid>
                    </Grid>

                    {isLoadingAttachments ? (
                        <Skeleton className="my-1" count={3} height={50} />
                    ) : (
                        <>
                            {suppPreqDocs && suppPreqDocs?.length ? (
                                <>
                                    <Divider sx={{ my: 2 }} />
                                    <TableContainer >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Description</TableCell>
                                                    <TableCell align="left">File</TableCell>
                                                    <TableCell align="left">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {suppPreqDocs?.map((attachment) => (
                                                    <TableRow
                                                        key={attachment?.entryNo}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {attachment?.fileName}
                                                        </TableCell>
                                                        <TableCell align="left" onClick={()=>generateAttachment(attachment?.actualFile)}>
                                                            <Chip
                                                                label={attachment?.actualFile}
                                                                color="primary"
                                                                className="file-chip-primary"
                                                                size="small"
                                                                variant="outlined"
                                                                icon={<AttachFile />} />
                                                        </TableCell>
                                                        <TableCell align="left">

                                                            <IconButton aria-label="delete" size="small" onClick={() => setProdd(attachment)} >
                                                                <Delete fontSize="small" onClick={() => handleClickOpen()} />
                                                            </IconButton>
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            ) : (<></>)
                            }
                        </>
                    )}

                </Box>

            </ThemeProvider>
            <PreqAttachDeleteModal open={open} 
            handleClickOpen={handleClickOpen} handleClose={handleClose} prodd={prodd} 
            applicationNo={appPrequalification.applicationNo} appNo={appNo} type="1"
            />
        </React.Fragment>
    );
}

export default BusinessProfile;