import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Card, CardContent, CardMedia, Container, Skeleton, Stack, ThemeProvider } from "@mui/material";
import { AssignmentOutlined } from "@mui/icons-material";
import Chip from '@mui/material/Chip';
import { createTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import './tables.css';
import { useDispatch, useSelector } from "react-redux";
import { getMyTenders, reset } from "../../features/tender/tenderSlice";


const PendingRequests = () => {
    const { myTenders, isLoading } = useSelector(state => state.tender);
    const [newMyTenders,setNewMyTenders] = useState(myTenders[0])
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth)
    function createData(no, quoteDesc, quoteStatus, documentDate, orderDate) {
        return { no, quoteDesc, quoteStatus, documentDate, orderDate };
    }
 

    const rows = [
        createData('QUOTEN0001', 'QUOTE TO SUPPLY THE LISTED BELOW S"', 'Pending', '05/05/22"', '05/05/22'),
        createData('QUOTEN0004', 'QUOTE TO SUPPLY THE LISTED BELOW S"', 'Pending', '05/05/22"', '05/05/22'),
    ];
    useEffect(() => {
        dispatch(getMyTenders());
        // return () => {
        //     dispatch(reset())
        // }
    }, [dispatch])

   

    return (
        <>
            {
                isLoading ? (
                    <Skeleton variant="rectangular" sx={{ display: 'flex', borderRadius: '16px', height: 330 }} />
                ) : (
                   
                        <Box component={Card} sx={{ borderRadius: '15px' }}>
                            <Typography variant="subtitle1" color='#1B1C1E' component="div" sx={{ mx: 2, my: 2, fontWeight: 'bold' }}>
                                My Tenders
                            </Typography>
                            {
                                // newMyTenders?.lineNo === 0  ||  newMyTenders?.usrpCompanyName === user.usrpCompanyName? (
                                    myTenders?.length == 0  ? (
                                    <Card>
                                        <CardMedia
                                            sx={{ height: 500 }}
                                            image="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-616.jpg?w=2000"
                                            title="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
                                                No data available
                                            </Typography>

                                        </CardContent>

                                    </Card>
                                ) : (
                                    <>
                                        {
                                            myTenders?.map((tend) => (
                                                <Card>
                                                    <CardContent>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={12} md={2}>
                                                                <div className='preq-icon-wrapper'>
                                                                    <AssignmentOutlined className='preq-icon' />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} md={5}>
                                                                <Stack spacing={3}>
                                                                    <Box>
                                                                        <Typography sx={{ fontWeight: 'medium', fontSize: 15 }}>
                                                                            {tend?.documentNo}
                                                                        </Typography>
                                                                        <Typography sx={{ fontSize: 13 }}>
                                                                            {tend?.description}
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={12} md={5}>
                                                                <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>

                                                                    <Chip sx={{ color: '#1976d2', px: 1, py: 0.25, borderRadius: '2px' }}
                                                                        label={
                                                                            <span>
                                                                                {tend?.status}
                                                                            </span>
                                                                        } />

                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            ))
                                        }
                                    </>
                                )
                            }

                        </Box>
                   
                )
            }

        </>
    )
}

export default PendingRequests;