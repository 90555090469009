import React, {Component} from 'react';
import Quotationdetails from '../../components/quotationDialog/QuotationDialog'

class QuotationDetails extends Component {
    render() {
        return (
            <Quotationdetails/>
        );
    }
}

export default QuotationDetails;