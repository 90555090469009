import React, { useEffect } from "react";
import { Card, CardContent, CardMedia, IconButton, Skeleton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import { RequestQuoteOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import { getPurchase, reset } from "../../features/purchase/purchaseSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";



const QuoteCard = () => {
    const dispatch = useDispatch();
    const documenType = 0;

    const { purchase, isLoading, isError, isSuccess, message, openQuotes } = useSelector((state) => state.purchase)

    // const data = purchase.filter(res => res.quoteStatus === 'Open');
    const data = purchase.length > 0 && purchase.filter(res => res.quoteStatus === 'Open');
    const firstData = data[0];
    const navigate =  useNavigate();
    const handleQuote = () => {
        navigate('/purchase-quotes')
    }

   

    useEffect(() => {
        if (isError) {
            console.log(message)
        }

        dispatch(getPurchase(documenType));

        // return () => {
        //     dispatch(reset())
        // }
    }, [dispatch, isError, message]);


    return (
        <>
            {isLoading ? (
                <Skeleton variant="rectangular" sx={{ display: 'flex', borderRadius: '16px', height: 110  }} />
            ) : (
                <Card elevation={2} 
                sx={{ display: 'flex', borderRadius: '16px', maxHeight: 110,background:'#a1ccd6',color:'#fff' }}
                onClick={handleQuote}
                >
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Stack direction="row" spacing={2}>
                            <Box sx={{ flex: '1 0 auto' }}>
                                <Typography variant="subtitle1"  component="div">
                                    Open Quotations
                                </Typography>
                                {
                                    data === false || data === undefined || data === null ||firstData?.no?.trim() == "" ? (
                                        <>
                                            <Typography component="div" variant="h3">
                                                0
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Typography component="div" variant="h3">
                                                {data.length}
                                            </Typography>
                                        </>
                                    )
                                }
                                {/* <Typography component="div" variant="h3">
                                    {data.length}
                                </Typography> */}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <RequestQuoteOutlined sx={{ fontSize: 60 }} />
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
            )
            }
        </>
    )
}

export default QuoteCard;