import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import './App.css';
import Profile from './components/profile/Profile';
import Orders from './components/profile/Orders';
import FillQuotes from './components/fillQuotation/fillQuotation';
import SignIn from "./pages/signin/Signin";
import SignUp from "./pages/signup/Signup";
import Prequalifications from './pages/prequalifications/Prequalifications';
import ViewPrequalification from './components/ view_prequalification/ViewPrequalification';
import ApplyPrequalification from "./pages/applyPrequalification/ApplyPrequalification";
import CompanyDetails from "./pages/signup/CompanyDetails";
import QuotationRequests from "./pages/quotationRequests/QuotationRequests";
import QuotationDetails from "./pages/quotationDetails/quotationDetails";
import ViewPurchaseQuote from "./components/PurchaseQuote/PurchaseQuote";
import ViewRFQ from "./components/requestForQuotation/RequestForQuotation";
import WithoutNav from "./layout/WithoutNav/WithoutNav";
import WithNav from "./layout/WithNav/WithNav";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import User from "./pages/profile/User"
import { setCurrentUser } from './redux/auth/auth.actions'
import NewPassword from "./pages/newPassword/NewPassword";
import {ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import RequestForProposals from "./pages/requestForProposals/RequestForProposals";
import Page from "./pages/profile/Tabs/Page";
import AllProfileInfo from "./pages/profile/Tabs/AllProfileInfo/AllProfileInfo";
import EditPersonalDetails from "./pages/profile/Tabs/EditPersonalDetails/EditPersonalDetails";
import Home from "./pages/home/Home";
import Tenders from "./pages/tenders/Tenders";
import Attachments from "./pages/profile/Tabs/Attachments/Attachments";
 
// import Attachments from "./components/Download/Attachments";
import MyPrequalifications from './pages/prequalifications/MyPrequalifications';
import MyTenders from "./pages/tenders/MyTenders";
import MyRequestForProposals from "./pages/requestForProposals/MyRequestForProposals";
import Sidebar from "./components/sidebar/Sidebar";
 




function App() {


  // useEffect(() => {
  //   setCurrentUser({
  //     id: 1,
  //     name: 'Joesoft Ways'
  //   })
  // }, [setCurrentUser]);

  return (
     <div class="max-h-screen max-w-screen overflow-x-hidden">
    <Router>
      <Routes>
        <Route element={<WithNav />} >

          {/* <Route path='/prof' element={<Profile />} /> */}
          <Route path='/orders' element={<Orders />} />
          <Route path='/companyDetails' element={<CompanyDetails />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/prequalifications' element={<Prequalifications />} />
          <Route path='/view-prequalification' element={<ViewPrequalification />} />
          <Route path='/apply-prequalification' element={<ApplyPrequalification />} />
          <Route path='/view-request-lines' element={<FillQuotes />} />
          <Route path='/purchase-quotes' element={<QuotationRequests />} />
          <Route path='/quotation-details' element={<QuotationDetails />} />
          <Route path='/view-purchase-quote/:id' element={<ViewPurchaseQuote />} />
          <Route path='/view-rfq/:id/:id' element={<ViewRFQ />} />
          <Route path='/tenders' element={<Tenders />} />
          <Route path='/home' element={<Home />} />
          <Route path='/user' element={<User/>} />
          <Route path="/profile" element={<Page />}>
            <Route path="step1" element={<AllProfileInfo/>} />
            <Route path="step2" element={<EditPersonalDetails/>} />
            <Route path="step3" element={<Attachments/>} />
          </Route>
            <Route path='/my-prequalifications' element={<MyPrequalifications />} />
            <Route path='/my-tenders' element={<MyTenders />} />
            <Route path='/my-rfps' element={<MyRequestForProposals />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/orders' element={<Orders />} />
            <Route path='/companyDetails' element={<CompanyDetails />} />
            <Route path='/orders' element={<Orders />} />
            <Route path='/prequalifications' element={<Prequalifications />} />
            <Route path='/apply-prequalification' element={<ApplyPrequalification />} />
            <Route path='/view-request-lines' element={<FillQuotes />} />
            <Route path='/quotation-requests' element={<QuotationRequests />} />   
            <Route path='/rfp' element={<RequestForProposals />} />
          
 
            
 
       
        </Route>
        {/* <Route path='/header' element={<Header />} /> */}


        <Route element={<WithoutNav />}>
          <Route path='/' element={<SignIn />} />
          <Route path='/login' element={<SignIn />} />
          <Route path='/register' element={<SignUp />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/sidebar' element={<Sidebar />} />
        </Route>
      </Routes>

    </Router>
      <ToastContainer/>
      </div>
  );
}

// const mapStateToProps = ({auth}) => ({
//   currentUser: auth.currentUser
// })
//
// const mapDispatchToProps = (dispatch) => ({
//   setCurrentUser: (user) => dispatch(setCurrentUser(user))
// })
//
// export default connect(mapStateToProps, mapDispatchToProps)(App);

export default App;
