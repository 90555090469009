import React, { useEffect, useState } from 'react';
import { Box, Button, CssBaseline, FormControl, FormLabel, Grid, Typography } from '@mui/material';
import BootstrapInput from '../../components/bootstrap/BootstrapInput';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestions, setQuestions, preSaveQuestionAnswers, reset, saveQuestionAnswer, fetchTenderAnswers } from '../../features/preqs/preqSlice';
import { formDataFromObject } from '../../utils';

const Competence = ({submitSupplierCompetency}) => {
    const { appPrequalification, questions, isLoading,competenceAnswers, 
        appNo,viewMode,
        isProcessing, isError, message } = useSelector(state => state.preq);
    const dispatch = useDispatch()
    const initValue = {filters:[]}
    const [formData, setFormData] = useState(questions);


    console.log("questions",questions)
    const handleChange = (e, i) => {
        let newformData = questions.map((p) => {
            if (p.lineNo == e.target.name) {
                return { ...p, value: e.target.value };
            }
        return p
        });
        dispatch(setQuestions(newformData))
    }

    const handleSubmit  = () => {
        let answer = {}
        let tempAnswers = [];

        for (let i = 0; i < questions.length; i++) {
            if (questions[i].value != "") {
                answer = {
                    lineNo: 0,
                    questionId: questions[i].lineNo,
                    answer: questions[i].value,
                    applicationNo: !viewMode ? appPrequalification.applicationNo  : appNo,
                };

                tempAnswers.unshift(answer)

                let _answer = formDataFromObject(answer);
                dispatch(saveQuestionAnswer(_answer))

            }
        }


        dispatch(preSaveQuestionAnswers({
            quizs: questions,
            ans: tempAnswers
        }))
    }

    console.log("competenceAnswers",competenceAnswers)
    useEffect(() => {
        // if (!questions.length) {
            dispatch(getQuestions());
            dispatch(fetchTenderAnswers({
                no:'SR-APP0002',
                questionId:1
            }));
        // }

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

  return (
    <>
        <CssBaseline/>
        <Box sx={{fontWeight: 'bold', my: 0, mx: 2, boxShadow: 1, p: 2, pt: 0, borderRadius: '5px',}}>
            <Typography variant={"h6"} sx={{ my: 2, fontSize: '0.875rem' }}>
                Capability & Competence to Deliver Goods & Services
            </Typography>
            {!isLoading ? (
                <Grid container rowSpacing={1.25} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    { questions.length && questions?.map((question, index) =>(
                        <Grid item md={6} xs={12} key={question.lineNo}>
                            <BootstrapInput
                                type="text"
                                id={question.lineNo}
                                name={question.lineNo}
                                value={question.value}
                                handleChange={(event) => handleChange(event, index)}
                                multiline 
                                rows={2} 
                                maxRows={2} 
                                label={question.question}
                            />
                        </Grid>
                    )) } 
    
                    <Grid item md={6} xs={12}>
                    </Grid>
                </Grid>
                
            ) : (

                // Custom Skeleton: leveraging Bootsrap input(multiline)
                <Grid container rowSpacing={1.25} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput multiline isLoading={isLoading} rows={2} label=""/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput multiline isLoading={isLoading} rows={2} label=""/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput multiline isLoading={isLoading} rows={2} label=""/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput multiline isLoading={isLoading} rows={2} label=""/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput multiline isLoading={isLoading} rows={2} label=""/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput multiline isLoading={isLoading} rows={2} label=""/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput multiline isLoading={isLoading} rows={2} label=""/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput multiline isLoading={isLoading} rows={2} label=""/>
                    </Grid>
                </Grid>
                
            )}
        </Box>
    </>
  )
}

export default Competence