import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleFailure } from '../utils';
import preqService from './preqService';
import Personnel from '../../pages/applyPrequalification/Personnel';
import { arrCleanUp, formatPreqQuestions } from "../../utils";

const initialState = {
    prequalifications: [],
    filteredPreqs: [],
    isLoading: false,
    isProcessing: false,
    isSuccess: false,
    isError: false,
    isSuccessDeleteAtt: false,
    message: '',
    supplierProfile: {},
    appPrequalification: {},
    bankers: [],
    directors: [],
    questions: [],
    answers: undefined,
    personnelList: [],
    experiences: [],
    financialInfoList: [],
    litigationQuestions: [],
    litigationAnswers: [],
    litigationHistory: [],
    suppPreqDocs: [],
    myPrequalifications: [],
    isCleanupFormPreq: false,
    preqRequiredDoc:[],
    isLoadingAttachments:false,
    competenceAnswers:[],
    appNo:'',
    viewMode:false,
    docNo:'',
}

// get prequalifications
export const getPrequalifications = createAsyncThunk(
    'prequalifications',
    async (_, thunkAPI) => {
        try {
            return await preqService.getPrequalifications()
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

// save prequalification
export const savePrequalification = createAsyncThunk(
    'prequalification/save',
    async (prequalification, thunkAPI) => {
        try {
            return await preqService.savePrequalification(prequalification)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

// get supplier profile
export const getSupplierProfile = createAsyncThunk(
    'prequalification/supplier',
    async (_, thunkAPI) => {
        try {
            const usrId = thunkAPI.getState().auth.user.usrId;
            return await preqService.getSupplierProfile(usrId);
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getBankers = createAsyncThunk(
    'prequalification/bankers',
    async (_, thunkAPI) => {
        try {
            const no = thunkAPI.getState().preq.appPrequalification.applicationNo;
            return await preqService.getBankers(no)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const saveBanker = createAsyncThunk(
    'prequalification/banker/save',
    async (banker, thunkAPI) => {
        try {
            return await preqService.saveBanker(banker)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getDirectors = createAsyncThunk(
    'prequalification/directors',
    async (_, thunkAPI) => {
        try {
            const no = thunkAPI.getState().preq.appPrequalification.applicationNo;
            return await preqService.getDirectors(no)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const saveDirector = createAsyncThunk(
    'prequalification/director/save',
    async (director, thunkAPI) => {
        try {
            return await preqService.saveDirector(director)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getQuestions = createAsyncThunk(
    'prequalification/questions',
    async (_, thunkAPI) => {
        try {
            const ans = thunkAPI.getState().preq.answers;
            const no = thunkAPI.getState().preq.appPrequalification.applicationNo;
            return await preqService.getQuestions(no, ans)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getLitigationQuestions = createAsyncThunk(
    'prequalification/litigation-questions',
    async (_, thunkAPI) => {
        try {
            const ans = thunkAPI.getState().preq.litigationAnswers;
            return await preqService.getLitigationQuestions(0, ans)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)


export const saveQuestionAnswer = createAsyncThunk(
    'prequalification/question/save-answer',
    async (answer, thunkAPI) => {
        try {
            // const no = thunkAPI.getState().preq.appPrequalification.applicationNo;
            return await preqService.saveQuestionAnswer(answer)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)


export const fetchTenderAnswers = createAsyncThunk(
    'prequalification/tender-answers',
    async (questionId, thunkAPI) => {
        try {
            const no = thunkAPI.getState().preq.appPrequalification.applicationNo;
            return await preqService.fetchTenderAnswers(no,questionId)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)


export const getPersonnelList = createAsyncThunk(
    'prequalification/personnel-list',
    async (_, thunkAPI) => {
        try {
            const no = thunkAPI.getState().preq.appPrequalification.applicationNo;
            return await preqService.getPersonnelList(no)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const savePersonnel = createAsyncThunk(
    'prequalification/personnel/save',
    async (personnel, thunkAPI) => {
        try {
            return await preqService.savePersonnel(personnel)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getExperiences = createAsyncThunk(
    'prequalification/experiences',
    async (_, thunkAPI) => {
        try {
            const no = thunkAPI.getState().preq.appPrequalification.applicationNo;
            return await preqService.getExperiences(no)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const saveExperience = createAsyncThunk(
    'prequalification/experience/save',
    async (experience, thunkAPI) => {
        try {
            return await preqService.saveExperience(experience)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getFinancialInfoList = createAsyncThunk(
    'prequalification/financial-info-list',
    async (_, thunkAPI) => {
        try {
            const no = thunkAPI.getState().preq.appPrequalification.applicationNo;
            return await preqService.getFinancialInfoList(no)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const saveFinancialInfo = createAsyncThunk(
    'prequalification/financial-info/save',
    async (financialInfo, thunkAPI) => {
        try {
            return await preqService.saveFinancialInfo(financialInfo)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getLitigationHistory = createAsyncThunk(
    'prequalification/litigation-history',
    async (_, thunkAPI) => {
        try {
            const no = thunkAPI.getState().preq.appPrequalification.applicationNo;
            return await preqService.getLitigationHistory(no)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const saveLitigationHistory = createAsyncThunk(
    'prequalification/litigation-history/save',
    async (history, thunkAPI) => {
        try {
            return await preqService.saveLitigationHistory(history)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)


export const getSupplierAttachments = createAsyncThunk(
    'prequalification/supplier/attachments',
    async (params, thunkAPI) => {
        try {
            const no = thunkAPI.getState().preq.appPrequalification.applicationNo;
            console.log("noslice",no)
            return await preqService.getSupplierAttachments(params)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const saveSupplierAttachment = createAsyncThunk(
    'prequalification/attachment/save',
    async (attachment, thunkAPI) => {
        try {
            return await preqService.saveSupplierAttachment(attachment)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getMyPrequalifications = createAsyncThunk(
    'prequalifications/mine',
    async (_, thunkAPI) => {
        try {
            const usrId = thunkAPI.getState().auth.user.usrId;
            return await preqService.getMyPrequalifications(usrId);
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

// getApplicationRequiredDocuments
export const getApplicationRequiredDocuments = createAsyncThunk(
    'prequalifications/documents',
    async (documentNo, thunkAPI) => {
        try {
            return await preqService.getApplicationRequiredDocuments(documentNo)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

// deleteSupplierProfileAttachment
export const deleteSupplierProfileAttachment = createAsyncThunk(
    'prequalifications/delete',
    async (entryNo, thunkAPI) => {
        try {
            return await preqService.deleteSupplierProfileAttachment(entryNo)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

// deleteRegDetailsBank
export const deleteRegDetailsBank = createAsyncThunk(
    'prequalifications/deleteRegDetailsBank',
    async (formData, thunkAPI) => {
        try {
            return await preqService.deleteRegDetails(formData)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)
// deleteRegDetailsDirector
export const deleteRegDetailsDirector = createAsyncThunk(
    'prequalifications/deleteRegDetailsDirector',
    async (formData, thunkAPI) => {
        try {
            return await preqService.deleteRegDetails(formData)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

// deleteRegDetailsPersonel
export const deleteRegDetailsPersonel = createAsyncThunk(
    'prequalifications/deleteRegDetailsPersonel',
    async (formData, thunkAPI) => {
        try {
            return await preqService.deleteRegDetails(formData)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

// deleteRegDetailsExperience
export const deleteRegDetailsExperience = createAsyncThunk(
    'prequalifications/deleteRegDetailsExperience',
    async (formData, thunkAPI) => {
        try {
            return await preqService.deleteRegDetails(formData)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)


// deleteRegDetailsFinancial
export const deleteRegDetailsFinancial = createAsyncThunk(
    'prequalifications/deleteRegDetailsFinancial',
    async (formData, thunkAPI) => {
        try {
            return await preqService.deleteRegDetails(formData)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)


export const preqSlice = createSlice({
    name: 'preq',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isLoadingAttachments= false
            state.isProcessing = false
            state.isError = false
            state.isSuccess = false
            state.isSuccessDeleteAtt= false
            state.message = ''
            state.supplierProfile = {}
            state.isCleanupFormPreq = false
        },
        setSupplierProfile: (state, action) => {
            state.supplierProfile = action.payload
        },
        setQuestions: (state, action) => {
            state.questions = action.payload
        },
        setAppNo: (state, action) =>{
            state.appNo = action.payload
        },
        setDocNo:(state, action)=>{
            state.docNo = action.payload
        },
        setViewMode:(state, action) =>{
            state.viewMode = action.payload
        },
        setLitigationQuestions: (state, action) => {
            state.litigationQuestions = action.payload
        },
        preSaveQuestionAnswers: (state, action) => {
            state.questions = action.payload.quizs
            state.answers = action.payload.ans
        },
        preSaveLitigationQuestionAnswers: (state, action) => {
            state.litigationQuestions = action.payload.quizs
            state.litigationAnswers = action.payload.ans
        },
        setfilteredPreqs: (state, action) => {
            state.filteredPreqs = action.payload
        },
        cleanupFormPreq: (state) => {
            state.prequalifications = initialState.prequalifications
            state.filteredPreqs = initialState.filteredPreqs
            state.supplierProfile = initialState.supplierProfile
            state.appPrequalification = initialState.appPrequalification
            state.bankers = initialState.bankers
            state.directors = initialState.directors
            state.questions = initialState.questions
            state.personnelList = initialState.personnelList
            state.experiences = initialState.experiences
            state.financialInfoList = initialState.financialInfoList
            state.litigationQuestions = initialState.litigationQuestions
            state.litigationAnswers = initialState.litigationAnswers
            state.litigationHistory = initialState.litigationHistory
            state.suppPreqDocs = initialState.suppPreqDocs
            state.myPrequalifications = initialState.myPrequalifications
            state.preqRequiredDoc = initialState.preqRequiredDoc
            state.viewMode =  initialState.viewMode
            state.appNo =  initialState.appNo
            state.docNo =  initialState.docNo
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getPrequalifications.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getPrequalifications.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.prequalifications = action.payload
                    state.filteredPreqs = action.payload
                }
            )
            .addCase(
                getPrequalifications.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                savePrequalification.pending, (state) => {
                    state.isProcessing = true
                }
            )
            .addCase(
                savePrequalification.fulfilled, (state, action) => {
                    state.isProcessing = false
                    state.isSuccess = true
                    state.message = "Prequalification Saved successfully"
                    state.appPrequalification = action.payload
                }
            )
            .addCase(
                savePrequalification.rejected, (state, action) => {
                    state.isProcessing = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                getSupplierProfile.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getSupplierProfile.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.supplierProfile = action.payload
                }
            )
            .addCase(
                getSupplierProfile.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                getBankers.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getBankers.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.bankers = arrCleanUp(action.payload, "lineNo")
                }
            )
            .addCase(
                getBankers.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                saveBanker.pending, (state) => {
                    state.isProcessing = true
                }
            )
            .addCase(
                saveBanker.fulfilled, (state, action) => {
                    state.isProcessing = false
                    state.isSuccess = true
                    state.bankers.unshift(action.payload)
                }
            )
            .addCase(
                saveBanker.rejected, (state, action) => {
                    state.isProcessing = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                getDirectors.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getDirectors.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.directors = arrCleanUp(action.payload, "lineNo")
                }
            )
            .addCase(
                getDirectors.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                saveDirector.pending, (state) => {
                    state.isProcessing = true
                }
            )
            .addCase(
                saveDirector.fulfilled, (state, action) => {
                    state.isProcessing = false
                    state.isSuccess = true
                    state.directors.unshift(action.payload)
                }
            )
            .addCase(
                saveDirector.rejected, (state, action) => {
                    state.isProcessing = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                getQuestions.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getQuestions.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.questions = formatPreqQuestions(action.payload)
                    // state.questions = action.payload
                }
            )
            .addCase(
                getQuestions.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            .addCase(
                getLitigationQuestions.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getLitigationQuestions.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.litigationQuestions = arrCleanUp(formatPreqQuestions(action.payload), "lineNo")
                    // state.questions = action.payload
                }
            )
            .addCase(
                getLitigationQuestions.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                saveQuestionAnswer.pending, (state) => {
                    state.isProcessing = true
                }
            )
            .addCase(
                saveQuestionAnswer.fulfilled, (state, action) => {
                    state.isProcessing = false
                    state.isSuccess = true
                }
            )
            .addCase(
                saveQuestionAnswer.rejected, (state, action) => {
                    state.isProcessing = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                getPersonnelList.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getPersonnelList.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.personnelList = arrCleanUp(action.payload, "lineNo")
                }
            )
            .addCase(
                getPersonnelList.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                savePersonnel.pending, (state) => {
                    state.isProcessing = true
                }
            )
            .addCase(
                savePersonnel.fulfilled, (state, action) => {
                    state.isProcessing = false
                    state.isSuccess = true
                    state.personnelList.unshift(action.payload)
                }
            )
            .addCase(
                savePersonnel.rejected, (state, action) => {
                    state.isProcessing = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                getExperiences.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getExperiences.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.experiences = arrCleanUp(action.payload, "lineNo")
                }
            )
            .addCase(
                getExperiences.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                saveExperience.pending, (state) => {
                    state.isProcessing = true
                }
            )
            .addCase(
                saveExperience.fulfilled, (state, action) => {
                    state.isProcessing = false
                    state.isSuccess = true
                    state.experiences.unshift(action.payload)
                }
            )
            .addCase(
                saveExperience.rejected, (state, action) => {
                    state.isProcessing = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                getFinancialInfoList.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getFinancialInfoList.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.financialInfoList = arrCleanUp(action.payload, "lineNo")
                }
            )
            .addCase(
                getFinancialInfoList.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                saveFinancialInfo.pending, (state) => {
                    state.isProcessing = true
                }
            )
            .addCase(
                saveFinancialInfo.fulfilled, (state, action) => {
                    state.isProcessing = false
                    state.isSuccess = true
                    state.financialInfoList.unshift(action.payload)
                }
            )
            .addCase(
                saveFinancialInfo.rejected, (state, action) => {
                    state.isProcessing = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                getLitigationHistory.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getLitigationHistory.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.litigationHistory = arrCleanUp(action.payload, "lineNo")
                }
            )
            .addCase(
                getLitigationHistory.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                saveLitigationHistory.pending, (state) => {
                    state.isProcessing = true
                }
            )
            .addCase(
                saveLitigationHistory.fulfilled, (state, action) => {
                    state.isProcessing = false
                    state.isSuccess = true
                    state.litigationHistory.unshift(action.payload)
                }
            )
            .addCase(
                saveLitigationHistory.rejected, (state, action) => {
                    state.isProcessing = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                getSupplierAttachments.pending, (state) => {
                    state.isLoadingAttachments = true
                }
            )
            .addCase(
                getSupplierAttachments.fulfilled, (state, action) => {
                    state.isLoadingAttachments = false
                    state.isSuccess = true
                    state.suppPreqDocs = arrCleanUp(action.payload, "entryNo")
                }
            )
            .addCase(
                getSupplierAttachments.rejected, (state, action) => {
                    state.isLoadingAttachments = false
                    state.isError = true
                    state.message = action.payload
                }
            )
            .addCase(
                saveSupplierAttachment.pending, (state) => {
                    state.isProcessing = true
                }
            )
            .addCase(
                saveSupplierAttachment.fulfilled, (state, action) => {
                    state.isProcessing = false
                    state.isSuccess = true
                    state.suppPreqDocs.unshift(action.payload)
                }
            )
            .addCase(
                saveSupplierAttachment.rejected, (state, action) => {
                    state.isProcessing = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            .addCase(
                getMyPrequalifications.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getMyPrequalifications.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.myPrequalifications = action.payload
                }
            )
            .addCase(
                getMyPrequalifications.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.myPrequalifications = []
                    state.message = action.payload
                }
            )

            .addCase(
                getApplicationRequiredDocuments.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getApplicationRequiredDocuments.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.preqRequiredDoc = action.payload
                }
            )
            .addCase(
                getApplicationRequiredDocuments.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            .addCase(
                deleteSupplierProfileAttachment.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                deleteSupplierProfileAttachment.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccessDeleteAtt = true
                    state.suppPreqDocs = action.payload
                }
            )
            .addCase(
                deleteSupplierProfileAttachment.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            .addCase(
                fetchTenderAnswers.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                fetchTenderAnswers.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.competenceAnswers = action.payload
                }
            )
            .addCase(
                fetchTenderAnswers.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            .addCase(
                deleteRegDetailsBank.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                deleteRegDetailsBank.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccessDeleteAtt = true
                    state.bankers = action.payload
                }
            )
            .addCase(
                deleteRegDetailsBank.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            .addCase(
                deleteRegDetailsDirector.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                deleteRegDetailsDirector.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccessDeleteAtt = true
                    state.directors = action.payload
                }
            )
            .addCase(
                deleteRegDetailsDirector.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            .addCase(
                deleteRegDetailsPersonel.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                deleteRegDetailsPersonel.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccessDeleteAtt = true
                    state.personnelList = action.payload
                }
            )
            .addCase(
                deleteRegDetailsPersonel.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            .addCase(
                deleteRegDetailsExperience.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                deleteRegDetailsExperience.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccessDeleteAtt = true
                    state.experiences = action.payload
                }
            )
            .addCase(
                deleteRegDetailsExperience.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )


            .addCase(
                deleteRegDetailsFinancial.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                deleteRegDetailsFinancial.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccessDeleteAtt = true
                    state.financialInfoList = action.payload
                }
            )
            .addCase(
                deleteRegDetailsFinancial.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )


                 
            
    }
})

export const {
    reset,
    setSupplierProfile,
    setQuestions,
    setLitigationQuestions,
    preSaveQuestionAnswers,
    preSaveLitigationQuestionAnswers,
    setfilteredPreqs,
    cleanupFormPreq,
    setAppNo,
    setViewMode,
    setDocNo,
} = preqSlice.actions;
export default preqSlice.reducer