import React, { useEffect, useState } from "react";

import {
  Typography,
  Grid,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
} from "@mui/material";

import BootstrapInput from "../../bootstrap/BootstrapInput";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";

import {
  getQuotationLines,
  getViewRequestQuotationLines,
  reset,
} from "../../../features/requestForQuotation/Lines/ViewRequestQuotationLinesSlice";
import Skeleton from "react-loading-skeleton";
import Paper from "@mui/material/Paper";
import { ArrowForwardIos } from "@mui/icons-material";
import SpecificationsModal from "./SpecificationsModal";

const RfqLines = ({ id }) => {
  const lines_id = id;


  const dispatch = useDispatch();

  const { viewRequestQuotationLines, isLoading, isError, isSuccess, message } =
    useSelector((state) => state.viewRequestQuotationLines);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [prodd, setProdd] = useState()



  useEffect(() => {
    if (isError) {
      console.log(message);
    }
    dispatch(getQuotationLines(lines_id));

    if (!isError) {
      dispatch(reset());
    }
  }, [isError, message, dispatch]);

  return (
    <>
      <Typography variant={"h6"} sx={{ my: 2, fontSize: "0.875rem" }}>
        Request For Quotation Lines
      </Typography>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell align="left">Number</TableCell>
                <TableCell align="left">Name&nbsp;</TableCell>
                <TableCell align="left">Description&nbsp;</TableCell>
                <TableCell align="left">Actions&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {viewRequestQuotationLines.map((row) => (
                <TableRow
                  key={row.type}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {row.type === "G/L Account" ? (
                    <TableCell component="th" scope="row">
                      Service
                    </TableCell>
                  ) : (
                    <TableCell component="th" scope="row">
                      {row.type}
                    </TableCell>
                  )}

                  <TableCell align="left">{row.no}</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.rfqDesc}</TableCell>
                  <TableCell align="left">
                    <IconButton aria-label="delete" size="small" onClick={() => setProdd(row)} >
                      <Button
                        sx={{ p: 1, textTransform: 'capitalize', borderRadius: '.75rem', borderColor: '#e7e7e7' }}
                        className="fs-12" variant="outlined" color="inherit"
                        onClick={handleClickOpen}
                      >
                        View More
                      </Button>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
     
      <SpecificationsModal
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        prodd={prodd}
      />
    </>
  );
};

export default RfqLines;
