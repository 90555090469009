import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { reset, getMyPrequalifications } from '../../features/preqs/preqSlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Table, TableBody, TableCell, TableFooter, TableRow, TablePagination, TableContainer, Grid } from '@mui/material';
import MyPrequalification from '../../components/prequalification/MyPrequalification';
import TablePaginationActions from '../../components/pagination/TablePaginationActions';
import ViewMyPrequalification from '../../components/view_myPrequalifications/ViewMyPrequalification';

const MyPrequalifications = () => {
    const dispatch = useDispatch();
    const { myPrequalifications, isLoading } = useSelector(state => state.preq);
    const [newPreq, setNewPreq] = useState(myPrequalifications[0])
    const [open, setOpen] = useState(false);
    const [selectedPreq, setSelectedPreq] = useState({});
    const [value, setValue] = useState(0);
    const handleClickOpen = (prequalification) => {
        setOpen(true);
        setSelectedPreq(prequalification);
    };
    const handleClose = () => {
        setOpen(false);
    };


    /**
     * :: Initialize pagination - starts here
     */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - myPrequalifications.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(() => {

        dispatch(getMyPrequalifications());

        // return () => {
        //     dispatch(reset())
        // }
    }, [dispatch])

    return (
        <div className='container my-2'>

            <TableContainer>

                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    {isLoading ? (
                        <Skeleton
                            className="my-2"
                            count={6}
                            height={70}
                            inline={false}
                        />
                    ) : (
                        <>
                            {
                                newPreq?.lineNo === 0 ? (
                                    <h2>No data available</h2>

                                ) : (
                                    <>
                                        <Grid container spacing={2} sx={{ color: '#1976D2' }} >
                                            <Grid item xs={5}><h6 style={{ paddingLeft: '20px' }}>Title </h6> </Grid>
                                            <Grid item xs={4}><h6 >Submitted Status</h6> </Grid>
                                            <Grid item xs={2}><h6 >Actions</h6></Grid>
                                        </Grid>

                                        <TableBody>
                                            {myPrequalifications && (
                                                (rowsPerPage > 0 ? myPrequalifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : myPrequalifications).map((prequalification) => (
                                                    /*  my prequalification */
                                                    <MyPrequalification
                                                        prequalification={prequalification}
                                                        handleOpenPreq={() => handleClickOpen(prequalification)}
                                                    />
                                                ))
                                            )}

                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>


                                        <TableFooter>
                                            {
                                                Object.keys(myPrequalifications).length > 0 && (
                                                    <TableRow>
                                                        {isLoading ? (
                                                            <Skeleton className="my-2" height={25} inline={false} />
                                                        ) : (
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 15, { label: 'All', value: -1 }]}
                                                                colSpan={3}
                                                                count={myPrequalifications.length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                // SelectProps={{ inputProps: { 'aria-label': 'rows per page', }, native: true, }}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                                ActionsComponent={TablePaginationActions}
                                                            />
                                                        )}
                                                    </TableRow>
                                                )
                                            }
                                        </TableFooter>
                                    </>
                                )
                            }
                        </>

                    )}

                </Table>

            </TableContainer>
            <ViewMyPrequalification openPreq={open} handleOpenPreq={handleClickOpen} handleClosePreq={handleClose} preq={selectedPreq} />
        </div>
    )
}

export default MyPrequalifications