import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../../utils';
import purchaseAttachmentService from './ViewPurchaseAttachmentServices';

const initialState = {
    attachments: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: '',
    isCleanupFormPurchaseAtt: false,
}

//save Purchase Attachment
export const savePurchaseAttachment =createAsyncThunk('profile/attach',async(userDataProfile,thunkAPI)=>{
    try{
        return await purchaseAttachmentService.savePurchaseAttachment(userDataProfile)
    }catch(error){
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});


export const purchaseAttachmentSlice = createSlice({
    name: 'purchaseAttachment',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.message = ''
            state.isCleanupFormPurchaseAtt = false
        },
        cleanupFormPurchaseAtt: (state) => {
            state.attachments = initialState.attachments
        }
    },
    extraReducers:(builder)=>{
        builder
            .addCase(savePurchaseAttachment.pending,(state)=>{
                state.isLoading = true;
            })
            .addCase(savePurchaseAttachment.fulfilled,(state,action)=>{
                state.isLoading = false;
                state.isSuccess=true;
                state.attachments = action.payload;
            })
            .addCase(savePurchaseAttachment.rejected,(state,action)=>{
                state.isLoading = false
                state.isError = true
                state.message=action.payload
            })
    }
})

export const { reset,cleanupFormPurchaseAtt } = purchaseAttachmentSlice.actions
export default purchaseAttachmentSlice.reducer