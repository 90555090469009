import * as React from 'react';
import { useState } from 'react';
import Prequalification from '../../components/prequalification/Prequalification';
import './prequalifications.css';
import ViewPrequalification from '../../components/ view_prequalification/ViewPrequalification';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TablePaginationActions from '../../components/pagination/TablePaginationActions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrequalifications, reset, savePrequalification, setViewMode, setfilteredPreqs } from '../../features/preqs/preqSlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import TabPanel from '../../components/tab/TabPanel';
import { useNavigate } from 'react-router-dom';
import { formDataFromObject } from '../../utils';
import { Grid } from '@mui/material';



const Prequalifications = () => {

  const { prequalifications, filteredPreqs, appPrequalification, isLoading, isProcessing, isSuccess, isError, message } = useSelector(state => state.preq);
  const { profile } = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  const [open, setOpen] = useState(false);
  const [selectedPreq, setSelectedPreq] = useState({});
  const [value, setValue] = useState(0);

  const handleClickOpen = (prequalification) => {
    setOpen(true);
    setSelectedPreq(prequalification);
    dispatch(setViewMode(false));
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log("selectedPreq.no",selectedPreq.no)
  const handleApply = () => {
    const preq = {
      tenderNo: selectedPreq.no,
      createdBy: profile.usrpUsrId,
      updatedBy: profile.usrpUsrId,
      //  status: 'OPEN',
      status: 'DRAFT',
      description: selectedPreq.tenderDescription,
      supplierProfileID: profile.usrpUsrId,
    }

    const _preq = formDataFromObject(preq);

    dispatch(savePrequalification(_preq));

    // if (!isLoading) {

    // }

  }

  const handleChange = (e, val) => {
    setValue(val);
    switch (val) {
      case 1:
        dispatch(setfilteredPreqs(prequalifications.filter(preq => preq.typeOfSupply == "Supply of Goods")));
        break;
      case 2:
        dispatch(setfilteredPreqs(prequalifications.filter(preq => preq.typeOfSupply == "Provision of Services")));
        break;
      case 3:
        dispatch(setfilteredPreqs(prequalifications.filter(preq => preq.typeOfSupply == "Provisions of Works")));
        break;
      default:
        dispatch(setfilteredPreqs(prequalifications));
        break;
    }
  };

  /**
   * :: Initialize pagination - starts here
   */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredPreqs.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /** ::  Initialize pagination - ends here */

  useEffect(() => {
    dispatch(getPrequalifications())

    if (isSuccess && message) {
      navigate('/apply-prequalification')
    }

    // return () => {
    //   dispatch(reset())
    // }
  }, [navigate, message, dispatch]);

  return (
    <>
      {/*  prequalifications */}
      <div className='container my-2'>

        {isLoading ? (
          <Skeleton
            className="my-0"
            height={50}
            inline={false}
          />
        ) : (
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} className="tab-header" aria-label="basic tabs example">
              <Tab label="All" />
              <Tab label="Supply of Goods" />
              <Tab label="Provision of Services" />
              <Tab label="Provisions of Works" />
            </Tabs>
          </Box>
        )}
        <TabPanel value={value} index={value}>

          <TableContainer>

            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              {isLoading ? (
                <Skeleton
                  className="my-2"
                  count={6}
                  height={70}
                  inline={false}
                />
              ) : (
                <>
                  {/* {
                    Object.keys(filteredPreqs).length > 0 ? (
                      <Grid container spacing={2} sx={{ color: '#1976D2' }}>
                      <Grid item xs={4}><h6 style={{ paddingLeft: '20px' }}>Title </h6> </Grid>
                      <Grid item xs={3}><h6 >Eligibility</h6> </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={2}><h6 >Closing Date</h6></Grid>
                      <Grid item xs={2}><h6 >Actions</h6></Grid>
                    </Grid>
                ):(
                  <h2>No data available</h2>
                )
                  } */}

                  <Grid container spacing={2} sx={{ color: '#1976D2' }}>
                    <Grid item xs={3}><h6 style={{ paddingLeft: '20px' }}>Title </h6> </Grid>
                    <Grid item xs={3}><h6 >Eligibility</h6> </Grid>
                    <Grid item xs={2}>Registration Status</Grid>
                    <Grid item xs={2}><h6 >Registration Period</h6></Grid>
                    <Grid item xs={2}><h6 >Actions</h6></Grid>
                  </Grid>

                  <TableBody>
                    {filteredPreqs && (
                      (rowsPerPage > 0 ? Array.from(filteredPreqs).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredPreqs).map((prequalification) => (
                        /*  prequalification - 1 unit */
                        <Prequalification
                          prequalification={prequalification}
                          openPreq={open}
                          handleOpenPreq={() => handleClickOpen(prequalification)}
                          handleClosePreq={handleClose}
                        />

                      ))
                    )}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </>
              )}

              <TableFooter>
                {
                  Object.keys(filteredPreqs).length > 0 ? (
                    <TableRow>
                      {isLoading ? (
                        <Skeleton className="my-2" height={25} inline={false} />
                      ) : (
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 15, { label: 'All', value: -1 }]}
                          colSpan={3}
                          count={filteredPreqs.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          // SelectProps={{ inputProps: { 'aria-label': 'rows per page', }, native: true, }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      )}
                    </TableRow>
                  ) : (
                    null
                  )
                }

              </TableFooter>
            </Table>
          </TableContainer>

        </TabPanel>
      </div>

      {/* view prequalification */}
      <ViewPrequalification openPreq={open} handleOpenPreq={handleClickOpen} handleClosePreq={handleClose} handleApply={handleApply} isProcessing={isProcessing} prequalification={selectedPreq} />

    </>
  )
}

export default Prequalifications