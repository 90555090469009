import axios from "axios";

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;

// get View Request Quotation Lines
const getQuotationLines = async (lines_id) => {
    const response = await axios.get(`${API_URL}/supplier/fetchRequestForQuotationLineList.action?no=${lines_id}`)
    const result = response.data.data.result;
    response.data.data.result = [];
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : result;
}

// get RfqLineSpecification
const getRfqLineSpecification = async ({documentNo,itemNo}) => {
    const response = await axios.get(`${API_URL}/supplier/fetchRfqLineSpecification.action?documentNo=${documentNo}&itemNo=${itemNo}`)
    const result = response.data.data.result;
    response.data.data.result = [];
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : result;
}




const ViewRequestQuotationLinesService={
    getQuotationLines,
    getRfqLineSpecification,
}
export default ViewRequestQuotationLinesService