import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, CssBaseline, Divider, FormControl, FormLabel, Grid, NativeSelect, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, CircularProgress, Chip } from '@mui/material';
import BootstrapInput from '../../components/bootstrap/BootstrapInput';
import BootstrapSelect from '../../components/bootstrap/BootstrapSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getFinancialInfoList, reset, saveFinancialInfo } from '../../features/preqs/preqSlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AttachFile, Create, Delete } from '@mui/icons-material';
import { formatPath, formDataFromObject } from '../../utils';
import { Form } from 'react-bootstrap';
import PreqDetailsDeleteModal from '../../components/prequalification/PreqDetailsDeleteModal';
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';

const Financial = () => {
    const { appPrequalification, financialInfoList, isLoading, isProcessing,
        appNo,viewMode,
         isSuccessDeleteAtt, isError, message } = useSelector(state => state.preq);
    const fileRef = useRef(null);
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        lineNo: 0,
        financialYear: '',
        currentAssets: '',
        totalAssets: '',
        currentLiabilities: '',
        totalLiabilities: '',
        applicationNo: '',
        file: null,
    });
    const [selectedFile, setSelectedFile] = useState();

    const { lineNo, financialYear, currentAssets, totalAssets, currentLiabilities, totalLiabilities, applicationNo, file } = formData;

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleFile = (e) => {
        console.log(e.target.files[0]);
        setSelectedFile(e.target.files[0]);
    }

    const handleSubmit = () => {
        const financialInfo = {
            ...formData,
            file: selectedFile,
            applicationNo:!viewMode ? appPrequalification.applicationNo  : appNo,
        }
        const _financialInfo = formDataFromObject(financialInfo);
        if (!financialInfo.financialYear || !financialInfo.currentAssets || !financialInfo.totalAssets ||
            !financialInfo.currentLiabilities || !financialInfo.totalLiabilities || !financialInfo.file) {
            toast.warning("Please fill all required information")
        } else {
            dispatch(saveFinancialInfo(_financialInfo));
            setFormData({ lineNo: 0, financialYear: '', currentAssets: '', totalAssets: '', currentLiabilities: '', totalLiabilities: '', applicationNo: '', file: null, })
            fileRef.current.value = null;
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [prodd, setProdd] = useState()

    useEffect(() => {
        dispatch(getFinancialInfoList());
        return () => {
            dispatch(reset())
        }
    }, [dispatch])



    return (
        <>
            <CssBaseline />
            <Box sx={{ fontWeight: 'bold', my: 0, mx: 2, boxShadow: 1, p: 2, pt: 0, borderRadius: '5px', }}>
                <Typography variant={"h6"} sx={{ mt: 2, mb: 1, fontSize: '0.875rem' }}>
                    Financial Capability (information in Kshs)
                </Typography>
                <Typography variant={"h6"} sx={{ mt: 0, fontSize: '0.775rem' }} color="error.main" >
                    NOTE: Attach a copy of firm's two most recent Audited Accounts/certified financial statements giving summary of assets and current liabilities/or any other financial support.
                </Typography>

                <Grid container rowSpacing={1.25} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput
                            type="text"
                            id="financialYear"
                            name="financialYear"
                            value={financialYear}
                            handleChange={handleChange}
                            label="Financial Year*"
                            format="true"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput
                            type="number"
                            id="totalAssets"
                            name="totalAssets"
                            value={totalAssets}
                            handleChange={handleChange}
                            label="Total assets*"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput
                            type="number"
                            id="currentAssets"
                            name="currentAssets"
                            value={currentAssets}
                            handleChange={handleChange}
                            label="Current assets*"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput
                            type="number"
                            id="totalLiabilities"
                            name="totalLiabilities"
                            value={totalLiabilities}
                            handleChange={handleChange}
                            label="Total liabilities*"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput
                            type="number"
                            id="currentLiabilities"
                            name="currentLiabilities"
                            value={currentLiabilities}
                            handleChange={handleChange}
                            label="Current liabilities*"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Form.Group className='bootstrap-input  mb-1' controlId="formFile">
                            <Form.Label className="mb-0">Attach financial Statement*</Form.Label>
                            <Form.Control
                                type="file"
                                ref={fileRef}
                                onChange={handleFile}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item md={6} xs={12}>
                    </Grid>
                    <Grid item md={6} xs={12} sx={{ mt: 1 }}>
                        <FormControl fullWidth variant={"standard"}>
                            {!isProcessing ? (
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    sx={{ textTransform: 'capitalize' }}
                                    onClick={handleSubmit}
                                >
                                    Add Financial Information
                                </Button>
                            ) : (
                                <Button fullWidth disabled variant="contained" sx={{ textTransform: 'none' }}>
                                    <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                                </Button>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>

                {isLoading ? (
                    <Skeleton className="my-1" count={3} height={50} />
                ) : (
                    <>
                        {financialInfoList?.length ? (
                            <>
                                <Divider sx={{ my: 2 }} />
                                <TableContainer >
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Financial Year</TableCell>
                                                <TableCell align="left">Total Assets</TableCell>
                                                <TableCell align="left">Current Assets</TableCell>
                                                <TableCell align="left">Total liabilities</TableCell>
                                                <TableCell align="left">Current liabilities</TableCell>
                                                <TableCell align="left">Attached file</TableCell>
                                                <TableCell align="left">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {financialInfoList?.map((financialInfo) => (
                                                <TableRow
                                                    key={financialInfo.lineNo}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {financialInfo.financialYear}
                                                    </TableCell>
                                                    <TableCell align="left">{financialInfo.totalAssets}</TableCell>
                                                    <TableCell align="left">{financialInfo.currentAssets}</TableCell>
                                                    <TableCell align="left">{financialInfo.totalLiabilities}</TableCell>
                                                    <TableCell align="left">{financialInfo.currentLiabilities}</TableCell>
                                                    <TableCell align="left">
                                                        <Chip
                                                            label={formatPath(financialInfo.attachUrl)}
                                                            onClick={() => { }}
                                                            color="primary"
                                                            className="file-chip-primary"
                                                            size="small"
                                                            variant="outlined"
                                                            icon={<AttachFile />} />
                                                    </TableCell>
                                                    <TableCell align="left">

                                                        <IconButton aria-label="delete" size="small" onClick={() => setProdd(financialInfo)}>
                                                            <Delete fontSize="small" onClick={() => handleClickOpen()} />
                                                        </IconButton>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        ) : (<></>)
                        }
                    </>
                )}

            </Box>
            <PreqDetailsDeleteModal
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                prodd={prodd}
                type="5"
            />
        </>
    )
}

export default Financial