import React, { useEffect, useState } from 'react';
import { Box, Button, CssBaseline, Divider, FormControl, FormLabel, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, CircularProgress } from '@mui/material';
import BootstrapInput from '../../components/bootstrap/BootstrapInput';
import { useSelector, useDispatch } from 'react-redux';
import { formDataFromObject } from '../../utils'
import { getBankers, reset, saveBanker } from '../../features/preqs/preqSlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Create, Delete } from '@mui/icons-material';
import { getProfile } from '../../features/profile/profileSlice';
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
import PreqAttachDeleteModal from '../../components/prequalification/PreqAttachDeleteModal';
import PreqDetailsDeleteModal from '../../components/prequalification/PreqDetailsDeleteModal';

const Banker = () => {

    const { appPrequalification, bankers, isLoading, isProcessing,
        appNo,viewMode,
        isSuccessDeleteAtt, isError, message } = useSelector(state => state.preq);
    const { profile } = useSelector((state) => state.profile)
    const { userDetails,user } = useSelector(state => state.auth);

    const dispatch = useDispatch()

 
    const [formData, setFormData] = useState({
        lineNo: 0,
        bank: '',
        bankAddress: '',
        bankTelephoneNo: '',
        bankEmail: '',
        bankMobileNo: '',
        supplierProfileID: '',
        applicatioNo: '',
        tenderNo: '',
        bankBranch:'',
        bankAccountNo:'',
      });

    const { bank, bankAddress, bankTelephoneNo, bankEmail,bankBranch,bankAccountNo, bankMobileNo, supplierProfileID, applicatioNo, tenderNo } = formData;

    const handleChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = () => {
        const banker = {
            ...formData, 
            bankMobileNo: bankTelephoneNo,
            supplierProfileID: profile.usrpUsrId,
            applicatioNo:!viewMode ? appPrequalification.applicationNo  : appNo,
            tenderNo: 0,
        };

        const _banker = formDataFromObject(banker);
        if(!formData.bank ||  !formData.bankAddress || !formData.bankTelephoneNo || !formData.bankEmail ||
            !formData.bankBranch || !formData.bankAccountNo
            ){
            toast.warning("select all the fields")
        }else{
            dispatch(saveBanker(_banker))
        }
      
        
        setFormData({
            lineNo: 0, bank: '', bankAddress: '', bankTelephoneNo: '', bankEmail: '',  bankMobileNo: '',
            supplierProfileID: '', applicatioNo: '', tenderNo: '',bankBranch:'',bankAccountNo:'',
        });
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [prodd, setProdd] = useState()


    useEffect(() => {
        dispatch(getBankers());
        return () => {
            dispatch(reset())
        }
    }, [])

    useEffect(()=>{
        setTimeout(()=>{
            dispatch(getProfile());
        },1000)
    },[])

 
  return (
    <>
    
        <CssBaseline/>
        <Box sx={{fontWeight: 'bold', my: 0, mx: 2, boxShadow: 1, p: 2, pt: 0, borderRadius: '5px',}}>
            <Typography variant={"h6"} sx={{ my: 2, fontSize: '0.875rem' }}>
                Banker Information
            </Typography>
            <Grid container rowSpacing={1.25} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item md={6} xs={12}>
                    <BootstrapInput
                        type="text"
                        id="bank" 
                        name="bank"
                        value={bank}
                        handleChange={handleChange}
                        label="Name of bank*"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <BootstrapInput
                        type="text"
                        id="bankAddress" 
                        name="bankAddress"
                        value={bankAddress}
                        handleChange={handleChange}
                        label="Address*"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <BootstrapInput
                        type="text"
                        id="bankTelephoneNo" 
                        name="bankTelephoneNo"
                        value={bankTelephoneNo}
                        handleChange={handleChange}
                        label="Telephone*"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <BootstrapInput
                        type="text"
                        id="bankEmail" 
                        name="bankEmail"
                        value={bankEmail}
                        handleChange={handleChange}
                        label="Email*"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <BootstrapInput
                        type="text"
                        id="bankBranch" 
                        name="bankBranch"
                        value={bankBranch}
                        handleChange={handleChange}
                        label="Branch Name*"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <BootstrapInput
                        type="text"
                        id="bankAccountNo" 
                        name="bankAccountNo"
                        value={bankAccountNo}
                        handleChange={handleChange}
                        label="Account Number*"
                    />
                </Grid>
               
                <Grid item md={6} xs={12}>
                </Grid>
                <Grid item md={6} xs={12}>
                <FormLabel htmlFor="my-input" variant={"subtitle"}>NOTE: You can add more than one banker info*</FormLabel>
                    { !isProcessing ? (
                        <Button 
                            fullWidth
                            variant="outlined" 
                            color="primary" 
                            sx={{ textTransform: 'capitalize' }}
                            onClick={handleSubmit}
                        >
                            Add Banker Information
                        </Button>
                    ) : (
                        <Button fullWidth disabled variant="contained" sx={{ textTransform: 'none' }}>
                            <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                        </Button>
                    )}

                </Grid>
            </Grid>

    

            {isLoading ? (
                <Skeleton className="my-1" count={3} height={50}/>
            ) : (
                <>
                {bankers?.length ? (
                    <>
                        <Divider sx={{my: 2}}/>
                        <TableContainer >
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Bank</TableCell>
                                        <TableCell align="left">Address</TableCell>
                                        <TableCell align="left">Telephone</TableCell>
                                        <TableCell align="left">Email</TableCell>
                                        <TableCell align="left">Branch Name</TableCell>
                                        <TableCell align="left">Account Number</TableCell>
                                        <TableCell align="left">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        bankers && bankers?.length >= 0 ? (
                                            bankers?.map((banker) =>
                                                banker ? (
                                                    <TableRow
                                                        key={banker.lineNo}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {banker.bank}
                                                        </TableCell>
                                                        <TableCell align="left">{banker.bankAddress}</TableCell>
                                                        <TableCell align="left">{banker.bankTelephoneNo}</TableCell>
                                                        <TableCell align="left">{banker.bankEmail}</TableCell>
                                                        <TableCell align="left">{banker.bankBranch}</TableCell>
                                                        <TableCell align="left">{banker.bankAccountNo}</TableCell>
                                                        <TableCell align="left">
                                                            <IconButton aria-label="delete" size="small" onClick={()=> setProdd(banker)} >
                                                                <Delete fontSize="small" onClick={() => handleClickOpen()} />
                                                            </IconButton>
                                                        </TableCell>

                                                    </TableRow>
                                                ) : ( <Typography variant="h6">Please reload again, network issues </Typography> )
                                            )
                                        ) : (
                                            <Typography variant="h6">You have not added any bank details</Typography>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (<></>)
                }
                </> 
            )}
        </Box>
        <PreqDetailsDeleteModal 
        open={open} 
        handleClickOpen={handleClickOpen} 
        handleClose={handleClose} 
        prodd={prodd} 
        type="1"
        />
    </>
  )
}

export default Banker