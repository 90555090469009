import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import {Typography, TextField, Modal} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import {useDispatch, useSelector} from "react-redux";

import {getPurchaseLines, reset, savePurchaseLines, updatePurchaseLines} from "../../../features/purchase/Lines/ViewPurchaseLinesSlice";
import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PurchaseLines = ({id}) => {

    const [unit, setUnit] = useState(
        ''
    );

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        elevation: 2,
        border: '0px',
        boxShadow: 30,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [double, setDouble] = useState(false);
    // const [data, setData] = useState([]);

    const [isUpdating, setIsUpdating] = useState(false );
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const Lines_id = id;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {viewPurchaseLines, isLoading, isError, isSuccess, message} = useSelector((state) => state.viewPurchaseLines)

            const columns = [
            {
                field: 'purchaseQuoteType',
                headerName: 'Type',
                width: 100,
                renderCell:(params) => {
                    if(params?.row?.purchaseQuoteType === 'G/L Account'){
                        return 'Service';
                    }
                    return params?.row?.purchaseQuoteType;
                }
            },
            {
                field: 'documentType',
                headerName: 'Document Type',
                width: 120,
            },
            {
                field: 'no',
                headerName: 'Number',
                type: 'number',
                width: 120,
            },
            {
                field: 'description',
                headerName: 'Description',
                width: 300,
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                width: 150,
                editable: false,
            },
            {
                field: 'unitCost',
                headerName: 'Unit Cost',
                width: 100,
                editable: true,
                renderCell: (params) => {
                    return (
                    <TextField
                        id="standard-basic"
                        type="number"
                        variant="standard"
                        value={params.row.unitCost}
                    />
                    )
                }
            },
            {
                field: 'amount',
                headerName: 'Amount',
                width: 150,
                type: 'number'
            },
            {
                field: 'remarks',
                headerName: 'Remarks',
                width: 350,
                editable: true,
                renderCell: (params) => {
                    return (
                        <TextField
                            id="standard-basic"
                            type="text"
                            variant="standard"
                            value={params.row.remarks}
                        />
                    )
                }
            },

    ];

 

    const processRowUpdate = async(newRow: any) => {
        const updatedRow = { ...newRow };
        const cost = updatedRow.unitCost;
 
        const remarks = updatedRow.remarks ? updatedRow.remarks : "No remarks";
 

        setIsUpdating(true);

        const formdata = new  FormData();
        formdata.append("lineNo", updatedRow.lineNo);
        formdata.append("documentNo", Lines_id);
        formdata.append("unitCost", updatedRow.unitCost);
        formdata.append("remarks",remarks);
 


        const res =  await dispatch(updatePurchaseLines(formdata));
         

        if(res?.type == "viewPurchaseLines/update/fulfilled"){
            dispatch(getPurchaseLines(Lines_id))
        }
        

        // console.log(cost, "Value Changed");
        // console.log(remarks, "Remarks Changed");
        // console.log(updatedRow, 'Updated Row');
        // toast.success('Saved!', {
        //     theme: "colored",
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        // });
        // setSnackbar({ children: 'successfully modified', severity: 'success' });
        setIsUpdating(false);

        //handle send data to api
        return updatedRow;
    };

    const handleProcessRowUpdateError = React.useCallback((error) => {
        console.log(error, 'Row Update Error')
        // toast.error('Row Update Error!', {
        //     theme: "colored",
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        // });
        // setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const handleSubmit = async (event) => {
        let error = event.preventDefault()
        const formdata1 = new  FormData();
        formdata1.append("quoteNo", Lines_id);

        dispatch(savePurchaseLines(formdata1));

        if (error) {
            notNotify()
            setDouble(false);
        }
        else {
            notify();
            setDouble(true);
        }

        handleClose()

        dispatch(getPurchaseLines(Lines_id));

        navigate(`/purchase-quotes`);

    };

    const notify = () => {
        toast.success('Purchase Quote Saved!', {
            theme: "colored",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const notNotify = () => {
        toast.error('Purchase Quote not Saved!', {
            theme: "colored",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };


    useEffect(() => {
        if (isError) {
            console.log(message)
        }
        dispatch(getPurchaseLines(Lines_id))

        return () => {
            dispatch(reset())
        }

    }, [navigate, isUpdating, isError, message, dispatch])

    return (
        <>
            <Typography
                variant={"h6"}
                sx={{ mt: 2, mb: 1, fontSize: '0.875rem' }}
            >
                Schedule of Requirements
            </Typography>
            <Typography
                variant={"subtitle1"}
                sx={{ mb: 1, fontSize: '0.875rem' }}
            >
                Please double click to access the unit cost area. Press ENTER to save the value
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                {viewPurchaseLines && <DataGrid
                    autoHeight
                    rows={viewPurchaseLines}
                    getRowId={(row) => row?.id}
                    columns={columns}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    pageSize={5}
                    loading={isLoading}
                    experimentalFeatures={{newEditingApi: true}}
                />}

            </Paper>

        </>

    )
}

export default PurchaseLines







