import React, {useEffect, useState} from 'react';

import {Typography, Grid, Card} from '@mui/material';

import BootstrapInput from '../../bootstrap/BootstrapInput';
import 'react-loading-skeleton/dist/skeleton.css';
import {useDispatch, useSelector} from "react-redux";

import {getViewPurchase, reset} from "../../../features/purchase/Header/ViewPurchaseSlice";
import Skeleton from "react-loading-skeleton";



const PurchaseHeader = ({id}) => {
 
    const header_id = id;
    const documenType = 0;
    const variables = [header_id, documenType];
    // console.log(header_id, '---------First ID')
    // console.log(documenType, 'Document')
    const dispatch = useDispatch();

    const {viewPurchase, isLoading, isError, isSuccess, message,quoteDetails} = useSelector((state) => state.viewPurchase)

    console.log("quoteDetailsreceived", quoteDetails)

    useEffect(() => {

        if (isError) {
            console.log(message)
        }

        dispatch(getViewPurchase(header_id));

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    return (
        <>
            <Typography
                variant={"h6"}
                sx={{ my: 2, fontSize: '0.875rem' }}
            >
                General
            </Typography>



            {isLoading ? (
                <Grid
                    container
                    rowSpacing={1.25}
                    columnSpacing={{xs: 1, sm: 2, md: 3}}
                >
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                </Grid>
            ) : (
                <Card sx={{padding: 2, borderRadius: 2}}>
                     
                            <Grid
                                container
                                rowSpacing={1.25}
                                columnSpacing={{xs: 1, sm: 2, md: 3}}
                            >

                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        type="text"
                                        id="vendorName"
                                        name="vendorName"
                                        value={quoteDetails?.vendorName}
                                        label="Name of Vendor"
                                        disabled={{ readOnly: true, }}
                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={quoteDetails?.rfqCode}
                                        id={"bootstrap-input"}
                                        label="Request For Quotation Code"
                                        disabled={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={quoteDetails?.orderDate}
                                        id={"bootstrap-input"}
                                        label="Order date"
                                        disabled={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={quoteDetails?.requestedReceiptDate}
                                        id={"bootstrap-input"}
                                        label="Requested Receipt Date"
                                        disabled={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={quoteDetails?.documentType}
                                        id={"bootstrap-input"}
                                        label="Type of Document "
                                        disabled={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={quoteDetails?.documentDate}
                                        id={"bootstrap-input"}
                                        label="Date of the Document"
                                        disabled={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>  
                            </Grid>
                     
                </Card>
                )
            }
        </>

    )
}

export default PurchaseHeader



