import { configureStore, combineReducers} from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import purchaseReducer from '../features/purchase/purchaseSlice';
import viewPurchaseReducer from "../features/purchase/Header/ViewPurchaseSlice";
import viewPurchaselineReducer from "../features/purchase/Lines/ViewPurchaseLinesSlice";
import purchaseAttachmentReducer from "../features/purchase/Attachment/ViewPurchaseAttachmentSlice";

import tenderReducer from "../features/tender/tenderSlice";
import quotationReducer from "../features/requestForQuotation/Header/ViewRequestQuotationSlice";
import profileReducer from "../features/profile/profileSlice";

import viewRequestQuotationLinesReducer from "../features/requestForQuotation/Lines/ViewRequestQuotationLinesSlice";
import preqReducer from '../features/preqs/preqSlice'
import rfpReducer from '../features/requestForProposal/rfpSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';


const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
    auth: authReducer,
    preq: preqReducer,
    purchase: purchaseReducer,
    viewPurchase: viewPurchaseReducer,
    viewPurchaseLines: viewPurchaselineReducer,
    purchaseAttachment: purchaseAttachmentReducer,
    quotation: quotationReducer,
    viewRequestQuotationLines: viewRequestQuotationLinesReducer,
    tender: tenderReducer,
    profile: profileReducer, 
    rfp: rfpReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)


// export const store = configureStore({
//   reducer: {
//     auth:authReducer,
//     preq: preqReducer
//   },
// });

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})

export const persistor = persistStore(store)

