import axios from "axios";

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;

// get View Purchase
const getViewPurchase = async ( header_id ) => {
    console.log("headerReduxid",header_id);
    const documenType = 0;
    const response = await axios.get(`${API_URL}/supplier/fetchPurchaseQuoteHeaderList.action?documenType=${documenType}`)
    const result = response.data.data.result;
    response.data.data.result = [];
    const data = result.filter(res => res.no === header_id)
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : data;
}



const viewPurchaseService={
    getViewPurchase,
}
export default viewPurchaseService