import React, { useEffect } from "react";
import { Card, CardContent, Skeleton, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DescriptionSharp } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getRfp, reset } from "../../features/requestForProposal/rfpSlice";
import { useNavigate } from "react-router-dom";

const RfpCard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { requestForProposals, isLoading, isError, message, openRfp } = useSelector((state) => state.rfp);
    // const data = requestForProposals.filter(res => res.eligibility === 'Open');
    const data = requestForProposals.length > 0 && requestForProposals.filter(res => res.eligibility === 'Open');
    const firstData = data[0];
    const handleRfp = () => {
        navigate('/rfp')
    }
 
    useEffect(() => {
        if (isError) {
            console.log(message)
        }

        dispatch(getRfp());

        // return () => {
        //     dispatch(reset())
        // }
    }, [dispatch, isError, message]);


    return (
        <>
            {
                openRfp === undefined && null ? (
                    <Card elevation={2} sx={{ display: 'flex', borderRadius: '16px', maxHeight: 110 }} onClick={handleRfp} >
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Stack direction="row" spacing={2}>
                                <Box sx={{ flex: '1 0 auto' }}>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Open proposals
                                    </Typography>
                                    <Typography component="div" variant="h3">
                                        0
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <DescriptionSharp sx={{ fontSize: 60, color: '#2962ff' }} />
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                ) : (
                    isLoading ? (
                        <Skeleton variant="rectangular" sx={{ display: 'flex', borderRadius: '16px', height: 110 }} />
                    ) : (
                        <Card elevation={2}
                            sx={{ display: 'flex', borderRadius: '16px', maxHeight: 110, background: '#2e971e', color: '#fff' }}
                            onClick={handleRfp}
                        >
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Stack direction="row" spacing={2}>
                                    <Box sx={{ flex: '1 0 auto' }}>
                                        <Typography variant="subtitle1" component="div">
                                            Open proposals
                                        </Typography>
                                        {
                                            data === false || data === undefined || data === null || firstData?.tenderNo?.trim() == "" ? (
                                                <>
                                                    <Typography component="div" variant="h3">
                                                        0
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography component="div" variant="h3">
                                                        {data.length}
                                                    </Typography>
                                                </>
                                            )
                                        }
                                        {/* <Typography component="div" variant="h3">
                                            {data.length}
                                        </Typography> */}
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <DescriptionSharp sx={{ fontSize: 60 }} />
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                    )
                )
            }

        </>
    )
}

export default RfpCard;