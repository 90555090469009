import React, { useState } from 'react'
import { 
    Card,
    Button,
    Dialog, 
    DialogContent,
    DialogActions, 
    DialogTitle, 
    DialogContentText } from '@mui/material';
import { 
    Article, 
    Circle, 
    Today, 
    InsertInvitation, 
    Download, 
    Assignment, 
    ArrowForwardIosSharp, 
    NewspaperOutlined} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { dateForHumans, formDataFromObject, previewPaper } from '../../utils';
import ApplyTender from '../apply_tender/ApplyTender';
import { useDispatch, useSelector } from 'react-redux';
import { saveTenderLineApplication } from '../../features/tender/tenderSlice';
import { saveAs } from 'file-saver';


const ViewTender = ({ openTender, handleOpenTender, handleCloseTender, tender }) => {
    const {user} = useSelector(state=>state.auth);
    const  navigate = useNavigate();
    const dispatch =  useDispatch();
    const [open, setOpen] = useState(false);
    const [close, setClose] = useState(false);
    const [selectedTender, setSelectedTender] = useState({});
    
    const handleClickOpen = () => {
        setOpen(true);
        setSelectedTender(tender);
        const line = {
            createdBy:user.usrId,
            updatedBy: user.usrId,
            status: tender.status,
            description: tender.tenderDescription,
            tenderNo: tender.no,
            supplierProfileID: user.usrId, 
        }
        const _line = formDataFromObject(line)
       dispatch(saveTenderLineApplication(_line));
      };
    
      const handleClose = () => {
        setOpen(false);
      };
 

   
      
    
    return (
        <>
        <Dialog
            open={openTender}
            onClose={handleCloseTender}
            fullWidth={true}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ borderRadius: 5 }}
        >
            {/* <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
            </DialogTitle> */}

            <DialogContent>

                <div className='container my-2'>
                    <div className='d-flex justify-content-start'>
                        <div className='preq-icon-wrapper'>
                            <NewspaperOutlined className='preq-icon'/>
                        </div>
                        <div className='view-preq-text-wrapper w-100'>
                            <h6 className='fs-15'>{tender.no}</h6>
                            <h6 className='fs-15'>Eligibility</h6>
                            <div className='d-flex justify-content-start'>
                            <Circle className={`status-icon ${tender.eligibility == 'Open' ? 'text-green' : 'text-purple'}`} />
                                <p className='fs-14 mb-2 px-1'>{tender.eligibility}</p>
                            </div>

                            <div className='row mb-2'>
                            <div className='col-md-6'>
                                <h6 className='fs-15'>Opening Date</h6>
                                    <div className='d-flex justify-content-start mb-2'>
                                        <Today className='fs-20' color="primary" />
                                        <p className='fs-14 mb-1 px-1'>{dateForHumans(`${tender.tenderOpeningDate} ${tender.tenderOpeningTime}`)}</p>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                <h6 className='fs-15'>Closing Date</h6>
                                    <div className='d-flex justify-content-start'>
                                        <InsertInvitation className='fs-20' color='error'/>
                                        <p className='fs-14 mb-1 px-1'>{dateForHumans(`${tender.tenderClosingDate} ${tender.tenderClosingTime}`)}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='mb-2'>
                                <h6 className='fs-15'>Tender description</h6>
                                <p className='fs-15'>{ tender.tenderDescription }</p>
                            </div>

                            <div className='mb-3'>
                                <h6 className='fs-15'>Special condition</h6>
                                <p className='fs-15'>{tender.specialCondition}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </DialogContent>
            <DialogActions sx={{ mx:3, mb:2 }}>
           
                <Button 
                    sx={{  textTransform: 'capitalize', position: 'absolute', left: '6.75rem',backgroundColor: '#E6FAFD',color:'#00BABA'}} 
                    color="inherit"
                    startIcon={<Download />}
                    // onClick={()=>previewPaper()}
                >
                    Download {tender.no}
                </Button>
                <Button 
                    sx={{  textTransform: 'capitalize' }}
                    onClick={handleCloseTender} 
                    variant="contained" 
                    disableElevation
                    color="inherit"
                >
                    Cancel
                </Button>

                <Button 
                    sx={{  textTransform: 'capitalize' }}
                    variant="contained"
                    // onClick={() => navigate(`/apply-tender`)}
                    disableElevation
                    endIcon={<ArrowForwardIosSharp sx={{ fontSize:"14px !important"}} />}
                    onClick={handleClickOpen}
                >

                    Apply
                </Button>
            </DialogActions>
        </Dialog>


           {/* applytender */}
      <ApplyTender openTender={open} handleOpenTender={handleClickOpen} handleCloseTender={handleClose} tenderer={selectedTender} handleClose={handleClose} handleCloseTenderAll={handleCloseTender}/>
        </>
    )
}

export default ViewTender