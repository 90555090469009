import React, { useEffect, useState } from 'react';
import './signup.css';
import './CompanyDetails';
import './UserDetails';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CompanyDetails from "./CompanyDetails";
import UserDetails from "./UserDetails";
import Link from "@mui/material/Link";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { cleanupForm, logout, register, reset } from '../../features/auth/authSlice';
import { CircularProgress } from '@mui/material';

const steps = ['Company Details', 'User Details'];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function getStepContent(step) {
  switch (step) {
    case 0:
      return <CompanyDetails />;
    case 1:
      return <UserDetails />
    default:
      throw new Error('Unknown step');
  }
}

const Signup = () => {

  const { company, userDetails} = useSelector(state => state.auth);

  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  var generalData = JSON.parse(localStorage.getItem(`generalinfo`))
  var generalData2 = JSON.parse(localStorage.getItem(`generalinfoy`))
  var combined = { ...generalData, ...generalData2 }

 
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)
  
 
  const handleRemoveLocalUser = () => {
        dispatch(logout());
        dispatch(reset());
    }

 

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("usrFirstName", userDetails.usrFirstName);
    formData.append("usrMobileNumber", userDetails.usrMobileNumber);
    formData.append("usrEmail", userDetails.usrEmail);
    formData.append("usrEncryptedPassword", userDetails.usrEncryptedPassword);
    formData.append("usrEncryptedPasswordAlt", userDetails.usrEncryptedPasswordAlt);

    formData.append("usrpCompanyName", company.usrpCompanyName)
    formData.append("usrpContactPerson", userDetails.usrpContactPerson)
    formData.append("usrpCountry", company.usrpCountry)
    formData.append("usrpCounty", company.usrpCounty)
    formData.append("usrpKraPinCertNo", company.usrpKraPinCertNo)
    if (userDetails.usrEncryptedPassword !== userDetails.usrEncryptedPasswordAlt) {
      toast.error("passwords do not match")
    } else {
      dispatch(register(formData));
    }
  }



  const handleNext = (e) => {

    setActiveStep(activeStep + 1);
    setOpen(true);
    e.preventDefault();

  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const theme = createTheme();

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      navigate('/login')
      toast.success(message);
      dispatch(cleanupForm())
      localStorage.clear();
    }
    dispatch(reset())

  }, [user, isError, isSuccess, message, dispatch])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            e-Procurement
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 1, my: { xs: 3, md: 6 } }}>

        <Typography component="h1" variant="h4" align="center">
          Sign Up
        </Typography>

        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    This is a success message!
                  </Alert>
                </Snackbar>
                <Typography variant="h5" gutterBottom>
                  Thank you for Sign In, Please log in.
                </Typography>
                <Link
                  href="/"
                  variant="body2"
                >
                  {"Sign In"}
                </Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </Button>
                  )}


                  {activeStep === steps.length - 1 ? (
                    <Button variant="contained"
                      type="button"
                      onClick={handleSubmit}
                      sx={{ mt: 3, ml: 1 }}
                    > Save </Button>
                  ) :
                  activeStep === steps.length - 1 && isLoading ?(
                    <Button
                    type="button"
                    onClick={handleSubmit}
                    sx={{ mt: 3, ml: 1 }}
  
                  >
                    <CircularProgress
                      size={30}
                      color="secondary"
                      style={{ position: "absolute" }}
                    />
                  Save
                  </Button>
                  )
                  : (
                    <Button variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 3, ml: 1 }}
                    > Next </Button>
                  )}

                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </Container>
    </ThemeProvider>
  )
}

export default Signup