import React, { useEffect, useState } from 'react';
import './signin.css';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { cleanupForm, login, reset } from '../../features/auth/authSlice';
import { Card, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import storage from 'redux-persist/lib/storage';
import { cleanupFormPreq } from '../../features/preqs/preqSlice';
import { cleanupFormProfile } from '../../features/profile/profileSlice';
import { cleanupFormPurchaseAtt } from '../../features/purchase/Attachment/ViewPurchaseAttachmentSlice';
import { cleanupFormPurchaseHeader } from '../../features/purchase/Header/ViewPurchaseSlice';
import { cleanupFormPurchaseLine } from '../../features/purchase/Lines/ViewPurchaseLinesSlice';
import { cleanupFormPurchase } from '../../features/purchase/purchaseSlice';
import { cleanupFormRfp } from '../../features/requestForProposal/rfpSlice';
import { cleanupFormRfqHeader } from '../../features/requestForQuotation/Header/ViewRequestQuotationSlice';
import { cleanupFormRfqLine } from '../../features/requestForQuotation/Lines/ViewRequestQuotationLinesSlice';
import { cleanupFormTender } from '../../features/tender/tenderSlice';
 

const theme = createTheme();

// const handleSubmit = (event) => {
//   event.preventDefault();
//   const data = new FormData(event.currentTarget);
//   console.log({
//     email: data.get('email'),
//     password: data.get('password'),
//   });
// }

const Signin = () => {
  const { register, formState: { errors }, } = useForm();
  const [loading, setLoading] = useState(false);


  const [formData, setFormData] = useState({

    j_username: '',
    j_password: '',

  })
  const { j_username, j_password } = formData


  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth)


  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess) {
      navigate('/home')
      setLoading(false)
    }
    
    dispatch(reset())

  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(cleanupFormPreq());
    dispatch(cleanupFormProfile());
    dispatch(cleanupFormPurchaseAtt());
    dispatch(cleanupFormPurchaseHeader());
    dispatch(cleanupFormPurchaseLine());
    dispatch(cleanupFormPurchase());
    dispatch(cleanupFormRfp());
    dispatch(cleanupFormRfqHeader());
    dispatch(cleanupFormRfqLine());
    dispatch(cleanupFormTender());
    storage.removeItem('persist:root');



    const userData = new URLSearchParams();
    userData.append('j_username', j_username);
    userData.append("requestTransportType", "json");
    userData.append("j_password", j_password);

    dispatch(login(userData));

  }

  const handleRegister=()=>{
    navigate('/register');
    dispatch(cleanupForm())
  }


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            e-Procurement
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
           
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={onSubmit} sx={{ mt: 1 }}>


            <TextField
              margin="normal"
              required
              fullWidth
              id="j_username"
              label="Email Address"
              name="j_username"
              autoComplete="email"
              value={j_username}
              // value={email}
              onChange={onChange}
              autoFocus
            />
            <TextField
            hiddenLabel
              margin="normal"
              required
              fullWidth
              name="j_password"
              label="Password"
              type="password"
              id="j_password"
              value={j_password}
              // value={password}
              onChange={onChange}
              autoComplete="current-password"
            />


            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
         

            {
              isLoading ? (
                <Button
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}

                >
                  <CircularProgress
                    size={30}
                    color="secondary"
                    style={{ position: "absolute" }}
                  />
                Sign In
                </Button>

              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
              )
            }

            <Grid container>
              <Grid item xs>
                <Link
                  href="/forgotpassword"
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>

                <Link
                style={{cursor:'pointer'}}
                onClick={handleRegister}
                  // href="/register"
                  variant="body2"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

      </Container>
    </ThemeProvider>
  )
}

export default Signin