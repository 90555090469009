import { useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { useNavigate } from "react-router-dom";
import './requestForProposal.css';
import { Article, Circle, MoreVert, Download, Assignment, DescriptionOutlined } from '@mui/icons-material';
import { Card, Button, Menu, MenuItem, IconButton, Chip, createTheme } from '@mui/material';
import { dateForHumans, subString } from '../../utils';
import { Box } from '@mui/system';


const MyRequestForProposal = ({rfp,handleOpenRfp}) => {
   
  return (
    <>
        <Card className='prequalification br-10'>
                <div className='row'>
                    <div className='col-sm-5 d-flex justify-content-start'>
                        <div className='preq-icon-wrapper'>
                            <DescriptionOutlined className='preq-icon' />
                        </div>
                        <div className='preq-text-wrapper'>
                            <h6 className='fs-13'>{rfp.applicationNo}</h6>
                            <p className='fs-12'>{subString(rfp.description, 60)} </p>
                        </div>
                    </div>

                    <div className='col-sm-4'>
                        
                        <Box sx={{display: 'flex', alignItems: 'end', justifyContent: 'start'}}>
                            <Chip 
                           sx={{ color: '#1976d2', px: 1,py: 0.25,borderRadius: '2px'}}
                            label={
                                <span>
                                Pending
                                </span>
                            }  />
                        </Box>
                    </div>
                    
                   
                    <div className='col-sm-2'>
                        <Button
                            sx={{ p: 1, textTransform: 'capitalize', borderRadius: '.75rem', borderColor: '#e7e7e7' }}
                            className="fs-12" variant="outlined" color="inherit"
                            onClick={handleOpenRfp}
                        >
                           View More
                        </Button>

                        

                    </div>
                </div>
            </Card>
    </>
  )
}

export default MyRequestForProposal