import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import '../../user.css'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileAttachments, saveAttachment } from '../../../../features/profile/profileSlice';
import DefaultImage from './upload.png'

export const Attachments = () => {
    const [kraFile, setKraFile] = useState();

    const [file, setFile] = useState('');
    const [vatFile, setVatFile] = useState('');
    const [regFile, setRegFile] = useState('');
    const [othersFile, setOthersFile] = useState('');
    const [applicationNo, setApplicationNo] = useState('1906');
    const [descriptionVat, setDescriptionVat] = useState('VAT');
    const [descriptionKra, setDescriptionKra] = useState('KRA');
    const [descriptionRegCert, setDescriptionRegCert] = useState('Reg File');
    const [descriptionOthers, setDescriptionOthers] = useState('Others File');

    const [type, setType] = useState('0');
    const [supplierDetails, setSupplierDetails] = useState([])
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [attachment, setAttachment] = useState('')
    const { profile, suppProfileDocs, isLoading, isError, isSuccess, message } = useSelector((state) => state.profile)

    const {user} = useSelector(state=>state.auth);


    const setImageToRespectveBox = () => {
        if (!suppProfileDocs.length) return;
        for (let i = 0; i < suppProfileDocs.length; i++) {
            switch (suppProfileDocs[i].fileName) {
                case 'KRA':
                    setKraFile(suppProfileDocs[i])
                    break;
                case 'VAT':
                    setVatFile(suppProfileDocs[i])
                    break;
                case 'Reg File':
                    setRegFile(suppProfileDocs[i])
                    break;

                case 'Others File':
                    setOthersFile(suppProfileDocs[i])
                    break;
                default:
                    break;
            }

        }
    }

    useEffect(() => {
        if (isError) {
            console.log(message)
        }

        dispatch(getProfileAttachments({
            no: user.usrId,
            type: 0
        }))

        setImageToRespectveBox();

        setLoading(false);

    }, [navigate, isError, message, dispatch])

  


    const handleChangeKraFile = (e) => {
 
        setKraFile(e.target.files[0]);
    };

    const handleChangeVatFile = (e) => {
  
        // setVatFile(URL.createObjectURL(e.target.files[0]));
        setVatFile(e.target.files[0]);
    };

    const handleChangeRegFile = (e) => {

        setRegFile(e.target.files[0]);
    };

    const handleChangeOthersFile = (e) => {

        setOthersFile(e.target.files[0]);
    };


    const handleChange = (e) => {
        const value = e.target.value;
        setSupplierDetails({
            ...supplierDetails,
            [e.target.name]: value
        })
    }

    const handleSubmitKra = async (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("file1", vatFile);
        formData.append("applicationNo", applicationNo);
        formData.append("description", descriptionKra);
        formData.append("type", type)

        dispatch(saveAttachment(formData))
    }
    const handleSubmitVat = async (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("file1", vatFile);
        formData.append("applicationNo", applicationNo);
        formData.append("description", descriptionVat);
        formData.append("type", type)

        dispatch(saveAttachment(formData))
    }

    const handleSubmitRegCert = async (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("file1", vatFile);
        formData.append("applicationNo", applicationNo);
        formData.append("description", descriptionRegCert);
        formData.append("type", type)

        dispatch(saveAttachment(formData))
    }
    const handleSubmitOthers = async (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("file1", vatFile);
        formData.append("applicationNo", applicationNo);
        formData.append("description", descriptionOthers);
        formData.append("type", type)

        dispatch(saveAttachment(formData))
    }


    const removeSelectedImage = () => {
        setFile();
    }
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }
    console.log("krafile",kraFile)
    console.log("vatfile",vatFile)
   
   
    return (
        <div>

            <Card>
                <div className="attachmentContainer">
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <Card >

                                <CardContent>
                                    <h6 style={{ fontSize: '18px', color: '#444444' }}>
                                        KRA PIN Certificate
                                    </h6>
                                    <hr></hr>
                                    <div className="attachmentUpload">

                                        <input type="file" onChange={handleChangeKraFile}
                                            accept=".png, .jpg, .jpeg, .pdf"
                                        />
                                        {
                                            kraFile ? (
                                                <>
                                                    <>
                                                        <p>{kraFile.actualFile}</p>
                                                    </>

                                                    {/* <Document
                                                        file={kraFile}
                                                        className="img-thumbnail"
                                                        onLoadSuccess={onDocumentLoadSuccess}
                                                    >
                                                        <Page pageNumber={pageNumber} />
                                                    </Document> */}
                                                </>

                                            )
                                                : (
                                                    <div>
                                                        <img src={DefaultImage}
                                                            className="img-thumbnail"
                                                            style={{ objectFit: 'cover', marginTop: '16px', borderRadius: '10px' }}
                                                        />
                                                    </div>
                                                )
                                        }
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        onClick={handleSubmitKra}
                                        className="block">
                                        Save
                                    </Button>
                                </CardActions>

                            </Card>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Card>
                                <form onSubmit={handleSubmitVat}>
                                    <CardContent>

                                        <h6 style={{ fontSize: '18px', color: '#444444' }}>
                                            VAT Certificate
                                        </h6>
                                        <hr></hr>
                                        <div className="attachmentUpload">
                                            <input type="file" onChange={handleChangeVatFile}
                                                accept=".png, .jpg, .jpeg, .pdf"
                                            />
                                            {
                                                vatFile ? (
                                                    <>

                                                        <>
                                                            <p>{vatFile.actualFile}</p>
                                                        </>
                                                        <Document
                                                            file={vatFile}
                                                            className="img-thumbnail"
                                                            onLoadSuccess={onDocumentLoadSuccess}
                                                        >
                                                            <Page pageNumber={pageNumber} />
                                                        </Document>
                                                    </>

                                                )
                                                    : (
                                                        <div>
                                                            <img src={DefaultImage}
                                                                className="img-thumbnail"
                                                                style={{ objectFit: 'cover', marginTop: '16px', borderRadius: '10px' }}
                                                            />
                                                        </div>
                                                    )
                                            }

                                        </div>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            className="block"
                                        //  onClick={handleSubmitVat}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </form>
                            </Card>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Card>
                                <CardContent>
                                    <h6 style={{ fontSize: '18px', color: '#444444' }}>
                                        Company Reg Certificate
                                    </h6>
                                    <hr></hr>
                                    <div className="attachmentUpload">
                                        <input type="file" onChange={handleChangeRegFile}
                                            accept=".png, .jpg, .jpeg, .pdf"
                                        />

                                        {
                                            regFile ? (
                                                <Card sx={{ mt: 2 }} style={{ border: "none", boxShadow: "none" }}>
                                                    <Document
                                                        file={regFile}
                                                        className="img-thumbnail"
                                                        onLoadSuccess={onDocumentLoadSuccess}
                                                    >
                                                        <Page pageNumber={pageNumber} />
                                                    </Document>
                                                </Card>
                                            )
                                                :
                                                (
                                                    <div>
                                                        <img src={DefaultImage}
                                                            className="img-thumbnail"
                                                            style={{ objectFit: 'cover', marginTop: '16px', borderRadius: '10px' }}
                                                        />
                                                    </div>
                                                )
                                        }

                                    </div>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        className="block"
                                        onClick={handleSubmitRegCert}
                                    >
                                        Save
                                    </Button>
                                </CardActions>
                            </Card>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Card>
                                <CardContent>
                                    <h6 style={{ fontSize: '18px', color: '#444444' }}>
                                        Others
                                    </h6>
                                    <hr></hr>
                                    <div className="attachmentUpload">
                                        <input type="file" onChange={handleChangeOthersFile}
                                            accept=".png, .jpg, .jpeg, .pdf"
                                        />
                                        {
                                            othersFile ? (
                                                <Card sx={{ mt: 2, border: 'none' }}>
                                                    <Document
                                                        file={othersFile}
                                                        className="img-thumbnail"
                                                        onLoadSuccess={onDocumentLoadSuccess}
                                                    >
                                                        <Page pageNumber={pageNumber} />
                                                    </Document>
                                                </Card>
                                            ) :
                                                (
                                                    <div style={{ mt: 2 }}>
                                                        <img src={DefaultImage}
                                                            className="img-thumbnail"
                                                            style={{ objectFit: 'cover', marginTop: '16px', borderRadius: '10px' }}
                                                        />
                                                    </div>

                                                )
                                        }

                                    </div>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        className="block"
                                        onClick={handleSubmitOthers}
                                    >
                                        Save
                                    </Button>
                                </CardActions>
                            </Card>
                        </div>
                    </div>

                </div>
            </Card>
        </div>
    )
}

export default Attachments