import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, CssBaseline, Divider, FormControl, FormLabel, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import BootstrapInput from '../../components/bootstrap/BootstrapInput';
import { useDispatch, useSelector } from 'react-redux';
import { formDataFromObject } from '../../utils';
import { getDirectors, reset, saveDirector } from '../../features/preqs/preqSlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Create, Delete } from '@mui/icons-material';
import BootstrapSelect from '../../components/bootstrap/BootstrapSelect';
import { TitleData } from '../../CountyData'
import PreqDetailsDeleteModal from '../../components/prequalification/PreqDetailsDeleteModal';


const Directors = () => {

    const { appPrequalification, directors, isLoading, isProcessing, 
        appNo,viewMode,
        isSuccessDeleteAtt, isError, message } = useSelector(state => state.preq);
    const dispatch = useDispatch()

    const [salutation, setSalutation] = useState(TitleData)
    const [formData, setFormData] = useState({
        lineNo: 0,
        directorName: '',
        directorNationality: '',
        directorShares: '',
        applicationNo: '',
    });

    const { lineNo, directorName, directorNationality, directorShares, applicationNo } = formData;

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async() => {
        const director = {
            ...formData,
            applicationNo: !viewMode ? appPrequalification.applicationNo  : appNo,
        };
        const _director = formDataFromObject(director);
       const res =  await dispatch(saveDirector(_director));
        setFormData({ lineNo: 0, directorName: '', directorNationality: '', directorShares: '', applicationNo: '', })
        if (res?.payload?.id){
            dispatch(getDirectors());
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [prodd, setProdd] = useState()


    useEffect(() => {
        dispatch(getDirectors());

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

   

    return (
        <>
            <CssBaseline />
            <Box sx={{ fontWeight: 'bold', my: 0, mx: 2, boxShadow: 1, p: 2, pt: 0, borderRadius: '5px', }}>
                <Typography variant={"h6"} sx={{ my: 2, fontSize: '0.875rem' }}>
                    Details Of Directors/Partners
                </Typography>
                <Grid container rowSpacing={1.25} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                    <Grid item md={6} xs={12}>
                        <input type="hidden" name="lineNo" value={lineNo} />
                        <BootstrapInput
                            type="text"
                            id="directorName"
                            name="directorName"
                            value={directorName}
                            handleChange={handleChange}
                            label="Full Name*"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput
                            type="text"
                            id="directorNationality"
                            name="directorNationality"
                            value={directorNationality}
                            handleChange={handleChange}
                            label="Nationality*"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BootstrapInput
                            type="text"
                            id="directorShares"
                            name="directorShares"
                            value={directorShares}
                            handleChange={handleChange}
                            label="Percent(%) of Shares*"
                        />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{ mt: .65 }}>
                        <FormControl fullWidth variant={"standard"}>
                            <FormLabel htmlFor="my-input" variant={"subtitle"}>NOTE: You can add as many directors/Partners as possible</FormLabel>
                            {!isProcessing ? (
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    sx={{ textTransform: 'capitalize' }}
                                    onClick={handleSubmit}
                                >
                                    Add Director/partner
                                </Button>
                            ) : (
                                <Button fullWidth disabled variant="contained" sx={{ textTransform: 'none' }}>
                                    <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                                </Button>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>

                {isLoading ? (
                    <Skeleton className="my-1" count={3} height={50} />
                ) : (
                    <>
                        {directors?.length ? (
                            <>
                                <Divider sx={{ my: 2 }} />
                                <TableContainer >
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Full Name</TableCell>
                                                {/* <TableCell align="left">Salutation</TableCell> */}
                                                <TableCell align="left">Nationality</TableCell>
                                                <TableCell align="left">Percent(%) of Shares</TableCell>
                                                <TableCell align="left">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {directors.map((director) => (
                                                <TableRow
                                                    key={director.lineNo}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {director.directorName}
                                                    </TableCell>
                                                    {/* <TableCell component="th" scope="row">
                                                        {director.directorTitle}
                                                    </TableCell> */}
                                                    <TableCell align="left">
                                                        {director.directorNationality}
                                                    </TableCell>
                                                    <TableCell align="left">{director.directorShares}</TableCell>
                                                    <TableCell align="left">
                                                        <IconButton aria-label="delete" size="small" onClick={() => setProdd(director)}>
                                                            <Delete fontSize="small" onClick={() => handleClickOpen()} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        ) : (<></>)
                        }
                    </>
                )}

            </Box>
            <PreqDetailsDeleteModal
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                prodd={prodd}
                type="2"
            />
        </>
    )
}

export default Directors