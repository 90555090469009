export const handleFailure = (error) => {
    let message = "";
    if (error.response) {
        message = error.response.data.messages.mainMessage
    } else if (error.request) {
        message = error.request
    } else {
        message = error.message || error.toString()
    }
    return message
}

export const clearObject = (obj) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            obj[key] = '';
        }
    }
}