import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTenders, reset, setFilteredTenders } from '../../features/tender/tenderSlice';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '../../components/tab/TabPanel';
import TablePaginationActions from '../../components/pagination/TablePaginationActions';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './tender.css';
import Tender from '../../components/tender/Tender';
import ViewTender from '../../components/view_tender/ViewTender';
import { Grid } from '@mui/material';

const Tenders = () => {
  const { tenders, filteredTenders, isLoading, isError, message } = useSelector(state => state.tender);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedTender, setSelectedTender] = useState({});
  const [value, setValue] = useState(0);

  const firstTender = filteredTenders[0];
 
  const handleClickOpen = (tender) => {
    setOpen(true);
    setSelectedTender(tender);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e, val) => {
    setValue(val);
    switch (val) {
      case 1:
        dispatch(setFilteredTenders(tenders.filter(tender => tender.typeOfSupply == "Supply of Goods")));
        break;
      case 2:
        dispatch(setFilteredTenders(tenders.filter(tender => tender.typeOfSupply == "Provision of Services")));
        break;
      case 3:
        dispatch(setFilteredTenders(tenders.filter(tender => tender.typeOfSupply == "Provisions of Works")));
        break;
      default:
        dispatch(setFilteredTenders(tenders));
        break;
    }
  };

  /**
   * :: Initialize pagination - starts here
   */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredTenders.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /** ::  Initialize pagination - ends here */



  useEffect(() => {
    dispatch(getTenders())

    // return () => {
    //   dispatch(reset())
    // }
  }, [dispatch]);



  return (
    <>
      <div className='container my-2'>
        {isLoading ? (
          <Skeleton
            className="my-0"
            height={50}
            inline={false}
          />
        ) : (
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} className="tab-header" aria-label="basic tabs example">
              <Tab label="All" />
              <Tab label="Supply of Goods" />
              <Tab label="Provision of Services" />
              <Tab label="Provisions of Works" />
            </Tabs>
          </Box>
        )}
        <TabPanel value={value} index={value}>

          <TableContainer>

            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              {isLoading ? (
                <Skeleton
                  className="my-2"
                  count={6}
                  height={70}
                  inline={false}
                />
              ) : (
                <>
                {
                  Object.keys(filteredTenders).length > 0 && firstTender?.tenderNo?.trim() != '' ? (
                    <Grid container spacing={2} sx={{color:'#1976D2'}}>
                    <Grid item xs={4}><h6 style={{ paddingLeft: '20px' }}>Title </h6> </Grid>
                    <Grid item xs={3}><h6 >Eligibility</h6> </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}><h6 >Closing Date</h6></Grid>
                    <Grid item xs={2}><h6 >Actions</h6></Grid>
                  </Grid>
                  ):(
                    <h2>No data available</h2>
                  )
                }
                 
                  <TableBody>
                    {(filteredTenders && firstTender?.tenderNo?.trim() != '') && (rowsPerPage > 0
                      ? Array.from(filteredTenders).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : filteredTenders
                    ).map((tender) => (
                      /*  tender - 1 unit */
                      <Tender
                        tender={tender}
                        handleOpenTender={() => handleClickOpen(tender)}
                      />
                    ))}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </>
              )}

              <TableFooter>
                {
                   (Object.keys(filteredTenders).length > 0 && firstTender?.tenderNo?.trim() != '') && (
                    <TableRow>
                    {isLoading ? (
                      <Skeleton className="my-2" height={25} inline={false} />
                    ) : (
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 15, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={filteredTenders.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        // SelectProps={{ inputProps: { 'aria-label': 'rows per page', }, native: true, }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    )}
                  </TableRow>
                   )
                }
               
              </TableFooter>
            </Table>
          </TableContainer>

        </TabPanel>
      </div>

      {/* view tender */}
      <ViewTender openTender={open} handleOpenTender={handleClickOpen} handleCloseTender={handleClose} tender={selectedTender} />
    </>
  )
}

export default Tenders