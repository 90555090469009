import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { deleteRegDetailsBank, deleteRegDetailsDirector, deleteRegDetailsExperience, deleteRegDetailsFinancial, deleteRegDetailsPersonel, getBankers, getDirectors, getExperiences, getFinancialInfoList, getPersonnelList } from '../../features/preqs/preqSlice';

export default function PreqDetailsDeleteModal({ open, handleClose, prodd, type }) {
  const { isSuccessDeleteAtt } = useSelector((state) => state.preq)

  const dispatch = useDispatch();

  //   const handleFileDelete=async()=>{
  //     if (type == 1){
  //       const res = await dispatch(deleteRegDetailsBank({
  //         lineNo: prodd.lineNo,
  //         type:1
  //     }));
  //     }

  //     if(res.type != 'error'){
  //       toast.success("Details deleted successfully")
  //       handleClose();
  //       if(type == 1){
  //         dispatch(getBankers());
  //       }else if(type == 2){
  //         dispatch(getDirectors());
  //       }else if (type == 3){
  //         dispatch(getPersonnelList());
  //       }else if (type == 4){
  //         dispatch(getExperiences());
  //       }else if (type == 5){
  //         dispatch(getFinancialInfoList());
  //       }else{
  //       }

  //     }else{
  //       toast.warning("Details not deleted successfully")
  //     }
  // }

  const handleFileDelete = async () => {
    if (type == 1) {
      const res = await dispatch(deleteRegDetailsBank({
        lineNo: prodd.lineNo,
        type: type
      }));
      if (res.type != 'error') {
        toast.success("Details deleted successfully")
        handleClose();
        dispatch(getBankers());
      }
    }
    else if (type == 2) {
      const res = await dispatch(deleteRegDetailsDirector({
        lineNo: prodd.lineNo,
        type: type
      }));
      if (res.type != 'error') {
        toast.success("Details deleted successfully")
        handleClose();
        dispatch(getDirectors());
      }
    }

    else if (type == 3) {
      const res = await dispatch(deleteRegDetailsPersonel({
        lineNo: prodd.lineNo,
        type: type
      }));
      if (res.type != 'error') {
        toast.success("Details deleted successfully")
        handleClose();
        dispatch(getPersonnelList());
      }
    }
    else if (type == 4) {
      const res = await dispatch(deleteRegDetailsExperience({
        lineNo: prodd.lineNo,
        type: type
      }));
      if (res.type != 'error') {
        toast.success("Details deleted successfully")
        handleClose();
        dispatch(getExperiences());
      }
    }

    else if (type == 5) {
      const res = await dispatch(deleteRegDetailsFinancial({
        lineNo: prodd.lineNo,
        type: type
      }));
      if (res.type != 'error') {
        toast.success("Details deleted successfully")
        handleClose();
        dispatch(getFinancialInfoList());
      }
    }

    

    



    // if(res.type != 'error'){
    //   toast.success("Details deleted successfully")
    //   handleClose();
    //   if(type == 1){
    //     dispatch(getBankers());
    //   }else if(type == 2){
    //     dispatch(getDirectors());
    //   }else if (type == 3){
    //     dispatch(getPersonnelList());
    //   }else if (type == 4){
    //     dispatch(getExperiences());
    //   }else if (type == 5){
    //     dispatch(getFinancialInfoList());
    //   }else{
    //   }

    // }else{
    //   toast.warning("Details not deleted successfully")
    // }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You won't be able to revert this!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => { handleFileDelete() }} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}