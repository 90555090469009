import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button, CssBaseline, Box, TextField, Toolbar, AppBar, Typography, Container, Avatar, FormControlLabel, Grid, Link, Checkbox, Card } from '@mui/material';
import { Form } from 'react-bootstrap';
import { ArrowForwardSharp, LockOutlined } from '@mui/icons-material';


import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';



function NewPassword() {


    const theme = createTheme();
    const [user, setUser] = useState({
        reset: "",
        password: "",
        confirmPassword: ""
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const value = e.target.value;
        setUser({
            ...user,
            [e.target.name]: value
        })
    };


    return (

        <div>

            <ThemeProvider theme={theme}>
                <CssBaseline />

                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Card sx={{ padding: '15px', marginTop: 8 }}>
                        <Box
                            sx={{
                                marginTop: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlined />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Set Your New Password
                            </Typography>

                            <Box component="form" sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="reset"
                                    label="Reset CODE"
                                    name="reset"
                                    autoComplete="reset"
                                    value={user.reset}
                                    onChange={handleChange}
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    value={user.password}
                                    onChange={handleChange}
                                    autoComplete="current-password"
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm password"
                                    type="password"
                                    id="confirm password"
                                    value={user.confirmPassword}
                                    onChange={handleChange}
                                    autoComplete="current-confirm password"
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >Submit
                                </Button>

                            </Box>

                        </Box>
                    </Card>
                </Container>
            </ThemeProvider>
        </div>


    )
}

export default NewPassword