 
import React, { useState } from 'react';
 
 
// import Sidebar from '../../components/sidebar/Sidebar2';
import SideMenu from '../../container/SideMenu';
import './profile.css';
import { ArrowForwardSharp } from '@mui/icons-material';
import { Button,TextField } from '@mui/material';
import { Form } from 'react-bootstrap';
 
 
 
 
function Profile() {
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('P.O box 1234 Nairobi');
 

    // const companyChangeHandler = (event) => {
    //     if (event.target.value.trim().length > 0) {
    //         setIsValid(true)
    //     }
    //     setCompany(event.target.value)
    // };
    // const addressChangeHandler = (event) => {
    //     if (event.target.value.trim().length > 0) {
    //         setIsValid(true)
    //     }
    //     setAddress(event.target.value)
    // };
    const handleChange =(event)=>{
        
    }
    const formSubmitHandler = (event) => {
        event.preventDefault();
        if (company.trim().length === 0) {
            // setIsValid(false)

            return;
        };
        console.log(company)
    }
    return (
          
          <div>
            {/* <div className="container mt-5" style={{marginLeft:260}}> */}
            <div className="container mt-5 profile">
                <div className="row">
                    <SideMenu />
                    {/* Profile Settings */}
                    <div className="col-lg-8 pb-5">

                                <Form className="row">
                                    <div className="col-md-6">
                                    <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="outlined-basic"
                                            name="company"
                                            label="company"
                                            type="text"
                                            fullWidth="true"
                                            // value={company}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Physical Address"
                                            name="Physical Address"
                                            label="Physical Address"
                                            type="text"
                                            fullWidth="true"
                                            // value={physicalAddress}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Tel No"
                                            name="Tel No"
                                            label="Tel No"
                                            type="number"
                                            fullWidth="true"
                                            // value={telNo}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="County"
                                            name="County"
                                            label="County"
                                            type="text"
                                            fullWidth="true"
                                            // value={County}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Contact Person"
                                            name="Contact Person"
                                            label="Contact Person"
                                            type="text"
                                            fullWidth="true"
                                            // value={contactPerson}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Nature of Bussiness"
                                            name="Nature of Bussiness"
                                            label="Nature of Bussiness"
                                            type="text"
                                            fullWidth="true"
                                            // value={natureOfBussiness}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Email"
                                            name="Email"
                                            label="Email"
                                            type="Email"
                                            fullWidth="true"
                                            // value={email}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Legal Status"
                                            name="Legal Status"
                                            label="Legal Status"
                                            type="text"
                                            fullWidth="true"
                                            // value={legalStatus}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Postal Address"
                                            name="Postal Address"
                                            label="Postal Address"
                                            type="text"
                                            fullWidth="true"
                                            // value={postalAddress}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Name of bankers"
                                            name="Name of bankers"
                                            label="Name of bankers"
                                            type="text"
                                            fullWidth="true"
                                            // value={bankers}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Web"
                                            name="Web"
                                            label="Web"
                                            type="text"
                                            fullWidth="true"
                                            // value={Web}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Maps Value"
                                            name="Maps Value"
                                            label="Maps Value"
                                            type="text"
                                            fullWidth="true"
                                            // value={mapsValue}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="KRA PIN"
                                            name="KRA PIN"
                                            label="KRA PIN"
                                            type="text"
                                            fullWidth="true"
                                            // value={kRAPIN}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Trade Terms"
                                            name="Trade Terms"
                                            label="Trade Terms"
                                            type="text"
                                            fullWidth="true"
                                            // value={tradeTerms}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Vat(CRT)"
                                            name="Vat(CRT)"
                                            label="Vat(CRT)"
                                            type="text"
                                            fullWidth="true"
                                            // value={vat}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <TextField  
                                            required
                                            margin="normal"
                                            id="Company Reg"
                                            name="Company Reg"
                                            label="Company Reg"
                                            type="text"
                                            fullWidth="true"
                                            // value={companyReg}
                                            onChange={handleChange} 
                                            sx={{mb:2}}
                                            />
                                        </div>
                                    </div>




                                    <div className="col-12">
                                        <hr className="mt-2 mb-3" />
                                        <div className="text-end">


                                            {/* <button type="submit">Add Goal</button> */}
                                            <Button
                                                variant="contained"
                                                endIcon={<ArrowForwardSharp />}
                                                
                                            >
                                                Add Goal
                                            </Button>

                                        </div>
                                    </div>
                                </Form>
                          
                    </div>
                </div>
            </div>
        
        </div> 
 
        

    )
}

export default Profile