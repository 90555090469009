import React, { useEffect } from "react";
import './home.css';
import TenderCard from "../../components/Cards/TenderCard";
import RfpCard from "../../components/Cards/RfpCard";
import QuoteCard from "../../components/Cards/QuoteCard";
import Box from "@mui/material/Box";
import PendingRequests from "../../components/Tables/PendingRequests";
import PendingRegistation from "../../components/Tables/PendingRegistation";
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, reset } from "../../features/profile/profileSlice";
import { useNavigate } from 'react-router-dom';
import { Grid } from "@mui/material";

const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth)


    useEffect(() => {
        if (!user) {
            navigate('/login')
        }
        dispatch(getProfile());

        return () => {
            dispatch(reset())
        }
    }, [dispatch, navigate, user])

    return (
        <>
            <div className='container'>
                <Box>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={4} className='dashCard'>
                            <TenderCard />
                        </Grid>
                        <Grid item xs={12} md={4} className='dashCard'>
                            <RfpCard />
                        </Grid>
                        <Grid item xs={12} md={4} className='dashCard'>
                            <QuoteCard />
                        </Grid>
                    </Grid>
                    {/* <div className='row my-sm-2'>
                        <div className='dashCard col-sm-4 mb-sm-2 '>
                            <TenderCard />
                        </div>
                        <div className='dashCard col-sm-4 mb-sm-2'>
                            <RfpCard />
                        </div>
                        <div className='dashCard col-sm-4 mb-sm-2'>
                            <QuoteCard />
                        </div>
                    </div> */}
                </Box>
                <Box sx={{ borderRadius: '16px', my: 3 }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <PendingRegistation />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PendingRequests />
                        </Grid>
                    </Grid>

                    {/* <div className="row">
                        <div className="col-sm-6">
                            <PendingRegistation />
                        </div>
                        <div className="col-sm-6" >
                            <PendingRequests />
                        </div>
                    </div> */}
                </Box>
            </div>
        </>
    )
}

export default Home;