export const CountyData = [
    {
        value: 'Mombasa',
        label: 'Mombasa',
    },
    {
        value: 'Kwale',
        label: 'Kwale',
    },
    {
        value: 'Kilifi',
        label: 'Kilifi',
    },
    {
        value: 'Tana River',
        label: 'Tana River',
    },
    {
        value: 'Lamu',
        label: 'Lamu',
    },
    {
        value: 'Taita Mak Taveta',
        label: 'Taita Mak Taveta',
    },
    {
        value: 'Garissa',
        label: 'Garissa',
    },
    {
        value: 'Wajir',
        label: 'Wajir',
    },
    {
        value: 'Mandera',
        label: 'Mandera',
    },
    {
        value: 'Marsabit',
        label: 'Marsabit',
    },
    {
        value: 'Isiolo',
        label: 'Isiolo',
    },
    {
        value: 'Meru',
        label: 'Meru',
    },
    {
        value: 'Tharaka-Nithi',
        label: 'Tharaka-Nithi',
    },
    {
        value: 'Embu',
        label: 'Embu',
    },
    {
        value: 'Kitui',
        label: 'Kitui',
    },
    {
        value: 'Machakos',
        label: 'Machakos',
    },
    {
        value: 'Makueni',
        label: 'Makueni',
    },
    {
        value: 'Nyandarua',
        label: 'Nyandarua',
    },
    {
        value: 'Nyeri',
        label: 'Nyeri',
    },
    {
        value: 'Kirinyaga',
        label: 'Kirinyaga',
    },
    {
        value: 'Murang’a',
        label: 'Murang’a',
    },
    {
        value: 'Kiambu ',
        label: 'Kiambu ',
    },
    {
        value: 'Turkana',
        label: 'Turkana',
    },
    {
        value: 'West Pokot',
        label: 'West Pokot',
    },
    {
        value: 'Samburu',
        label: 'Samburu',
    },
    {
        value: 'Trans-Nzoia',
        label: 'Trans-Nzoia',
    },
    {
        value: 'Uasin Gishu',
        label: 'Uasin Gishu',
    },
    {
        value: 'Elgeyo-Marakwet',
        label: 'Elgeyo-Marakwet',
    },
    {
        value: 'Nandi',
        label: 'Nandi',
    },
    {
        value: 'Baringo',
        label: 'Baringo',
    },
    {
        value: 'Laikipia',
        label: 'Laikipia',
    },
    {
        value: 'Nakuru',
        label: 'Nakuru',
    },
    {
        value: 'Narok',
        label: 'Narok',
    },
    {
        value: 'Kajiado',
        label: 'Kajiado',
    },
    {
        value: 'Kericho',
        label: 'Kericho',
    },
    {
        value: 'Bomet',
        label: 'Bomet',
    },
    {
        value: 'Kakamega',
        label: 'Kakamega',
    },
    {
        value: 'Vihiga',
        label: 'Vihiga',
    },
    {
        value: 'Bungoma',
        label: 'Bungoma',
    },
    {
        value: 'Busia',
        label: 'Busia',
    },
    {
        value: 'Siaya',
        label: 'Siaya',
    },
    {
        value: 'Kisumu',
        label: 'Kisumu',
    },
    {
        value: 'Homa Bay',
        label: 'Homa Bay',
    },
    {
        value: 'Migori',
        label: 'Migori',
    },
    {
        value: 'Kisii',
        label: 'Kisii',
    },
    {
        value: 'Nyamira',
        label: 'Nyamira',
    },
    {
        value: 'Nairobi',
        label: 'Nairobi',
    },

]
export const TitleData = [
    {
        value: 'Mr',
        label: 'Mr',
    },
    {
        value: 'Miss',
        label: 'Miss',
    },
    {
        value: 'Major',
        label: 'Major',
    },
    {
        value: 'Prof',
        label: 'Prof',
    },
    {
        value: 'Rev',
        label: 'Rev',
    },
    {
        value: 'Sir',
        label: 'Sir',
    },
    {
        value: 'Commander',
        label: 'Commander',
    },
    {
        value: 'Miss',
        label: 'Miss',
    },
    {
        value: 'Dkt',
        label: 'Dkt',
    },
    {
        value: 'Captain',
        label: 'Captain',
    },
    {
        value: 'Eng',
        label: 'Eng',
    },
    {
        value: 'Lord',
        label: 'Lord',
    },
    {
        value: 'Judge',
        label: 'Judge',
    },
    {
        value: 'Justice',
        label: 'Justice',
    },
    {
        value: 'Hon',
        label: 'Hon',
    },
]







