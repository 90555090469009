import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 

// getProfile
  const getProfile = async (usrId) => {
    const response = await axios.get(`${API_URL}/supplier/fetchSupplierProfileInfo.action?usrId=${usrId}`)
    const result = response.data.data.result;
    response.data.data.result = [];
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : result;
}

// updateProfile
const updateProfile =async(userData)=>{
 
  const response=await axios.post(`${API_URL}/usr/postUpdateSupplier.action`,userData)

  if (!response.data.success) {
      return Promise.reject(response.data.messages.mainMessage) 
  }
  
  if (response.data){
      localStorage.setItem('user',JSON.stringify(response.data))
  }
  return response.data
}


// saveAttachment
const saveAttachment =async(userDataProfile)=>{
  const config={
    headers: { 'Accept': 'application/json',}
}
  // console.log("userDataProfile",userDataProfile)
  const response = await axios.post(`${API_URL}/supplier/saveSupplierProfileAttachment.action`,userDataProfile,config)

  if (!response.data.success) {
      return Promise.reject(response.data.messages.mainMessage) 
  }
  
  if (response.data){
      localStorage.setItem('user',JSON.stringify(response.data))
  }
  return response.data
}

// getProfileAttachment
const getProfileAttachments = async ({no, type}) => {
  const response = await axios.get(`${API_URL}/supplier/fetchSupplierProfileAttachment.action?no=${no}&type=${type}`, {
      headers: {
          'Accept': 'application/json',
      }    
  })
 
  return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : response.data.data.result;
 
}

const profileService={
  getProfile,
  updateProfile,
  saveAttachment,
  getProfileAttachments,
 
}
export default profileService