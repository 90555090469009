import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../../utils';

import ViewRequestQuotationService from './ViewRequestQuotationService';


const initialState = {
    quotation: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: '',
    isCleanupFormRfqHeader: false,
}


// get  Profile
export const getQuotationHeader = createAsyncThunk('quotation/getAll',async (
    header_id, thunkApi) =>{
    try{
        // const token = thunkApi.getState().auth.user.token;
        return await ViewRequestQuotationService.getQuotationHeader(header_id)

    }catch(error){
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})


export const quotationSlice = createSlice({
    name: 'quotation',
    initialState,
    reducers: {
        reset: (state) => initialState,
        cleanupFormRfqHeader: (state) => {
            state.quotation = initialState.quotation
        }
    },
    extraReducers:(builder)=>{
        builder
            .addCase(getQuotationHeader.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(getQuotationHeader.fulfilled, (state,action) =>{
                state.isLoading=false
                state.isSuccess=true
                state.quotation = action.payload
            })
            .addCase(getQuotationHeader.rejected, (state,action) =>{
                state.isLoading = false
                state.isError=true
                state.message = action.payload
            })
    }
})

export const { reset,cleanupFormRfqHeader } = quotationSlice.actions
export default quotationSlice.reducer