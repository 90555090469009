import axios from "axios";
// import {newDate, newTends} from "../../utils";

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;

// getPurchase
const getPurchase = async ({documenType,supplierProfileID}) => {
   console.log("supplierProfileID",supplierProfileID)
    const response = await axios.get(`${API_URL}/supplier/fetchPurchaseQuoteHeaderList.action?documenType=${documenType}&supplierProfileID=${supplierProfileID}`)

    // const result = newTends(newDate(response.data.data.result));
    const result =response.data.data.result;
    response.data.data.result = [];


    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : result;
}

const getOpenQuotations = async () => {
    const response = await axios.get(`${API_URL}/supplier/fetchPurchaseQuoteHeaderList.action?documenType=0`, {
        headers: {
            'Accept': 'application/json',
        }
    })

    const data = response.data.jsonData.filter(res => res.quoteStatus === 'Open');
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : data
}



const getPurchaseGeneral = async (headerId) => {
    const headerRequest =  axios.get(`${API_URL}/supplier/fetchPurchaseQuoteHeaderList.action?documenType=${0}`);
    const linesRequest =  axios.get(`${API_URL}/supplier/fetchPurchaseQuoteLineList.action?no=${headerId}`);

    const [ headerResponse, linesResponse ] = await Promise.all([
        axios.get(`${API_URL}/supplier/fetchPurchaseQuoteHeaderList.action?documenType=${0}`),
        axios.get(`${API_URL}/supplier/fetchPurchaseQuoteLineList.action?no=${headerId}`)
    ])

    if (!headerResponse.data.success || !linesResponse.data.success) {
        return Promise.reject(headerResponse.data.messages.mainMessage) 
    }
   
    return {
        header: headerResponse.data.data.result,
        lines: linesResponse.data.data.result
    };
}



const purchaseService={
    getPurchase,
    getPurchaseGeneral,
    getOpenQuotations
}
export default purchaseService