import "./viewPrequalfication.css";
import {
  Card,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  CircularProgress,
} from "@mui/material";
import {
  Article,
  Circle,
  Today,
  InsertInvitation,
  Download,
  Assignment,
  ArrowForwardIosSharp,
  AssignmentOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { dateForHumans, generateAttachment, previewPaper } from "../../utils";

const ViewPrequalification = ({
  openPreq,
  handleOpenPreq,
  handleClosePreq,
  isProcessing,
  handleApply,
  prequalification,
}) => {
  return (
    <>
      <Dialog
        open={openPreq}
        onClose={handleClosePreq}
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: 5 }}
      >
        {/* <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
            </DialogTitle> */}

        <DialogContent>
          <div className="container my-2">
            <div className="d-flex justify-content-start">
              <div className="preq-icon-wrapper">
                <AssignmentOutlined className="preq-icon" />
              </div>
              <div className="view-preq-text-wrapper w-100">
                <h6 className="fs-15">{prequalification.tenderNo}</h6>
                {/* <div className='d-flex justify-content-start'>
                            <Circle className={`status-icon ${prequalification.eligibility == 'Open' ? 'text-green' : 'text-purple'}`} />
                                <p className='fs-14 mb-2 px-1'>{prequalification.eligibility}</p>
                            </div> */}

                <div className="row mb-2" style={{ marginTop: "1rem" }}>
                  <div className="col-md-6">
                    <h6 className="fs-15">Eligibility</h6>
                    <div className="d-flex justify-content-start">
                      <Circle
                        className={`status-icon ${
                          prequalification.eligibility == "Open"
                            ? "text-green"
                            : "text-purple"
                        }`}
                      />
                      <p className="fs-14 mb-2 px-1">
                        {prequalification.eligibility}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <h6 className="fs-15">Closing Date</h6>
                    <div className="d-flex justify-content-start">
                      <Today className="fs-20" color="primary" />
                      <p className="fs-14 mb-1 px-1">
                        {dateForHumans(prequalification.tenderClosingDate)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <h6 className="fs-15">Tender description</h6>
                  <p className="fs-15">{prequalification.tenderDescription}</p>
                </div>

                <div className="mb-3">
                  <h6 className="fs-15">Special condition</h6>
                  <p className="fs-15">{prequalification.specialCondition}</p>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ mx: 3, mb: 2 }}>
          <Button
            sx={{
              textTransform: "capitalize",
              position: "absolute",
              left: "6.75rem",
              backgroundColor: "#E6FAFD",
              color: "#00BABA",
            }}
            onClick={()=>generateAttachment('REGISTRATION.doc')}
            color="inherit"
            startIcon={<Download />}
          >
            Download
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleClosePreq}
            variant="contained"
            disableElevation
            color="inherit"
          >
            Cancel
          </Button>

          {!isProcessing ? (
            <>
              <Button
                sx={{ textTransform: "capitalize" }}
                disabled={
                  prequalification?.registrationStatus == "SUBMITTED" ||
                  prequalification?.registrationStatus == "REGISTERED" ||
                  prequalification?.registrationStatus == "NOT REGISTERED"
                    ? true
                    : false
                }
                variant="contained"
                onClick={handleApply}
                disableElevation
                endIcon={
                  <ArrowForwardIosSharp sx={{ fontSize: "14px !important" }} />
                }
              >
                {prequalification?.registrationStatus == "OPEN" ||
                prequalification?.registrationStatus == "DRAFT"
                  ? "Apply"
                  : "Already submitted"}
              </Button>
            </>
          ) : (
            <Button disabled variant="contained" sx={{ textTransform: "none" }}>
              <CircularProgress size={17} color="inherit" />
              &nbsp;Appling..
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewPrequalification;
