import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BootstrapSelect from "../../bootstrap/BootstrapSelect";
import { useDispatch, useSelector } from "react-redux";
import { formDataFromObject, generateAttachment } from "../../../utils";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import {
  getSupplierAttachments,
  saveSupplierAttachment,
} from "../../../features/preqs/preqSlice";
import { AttachFile, Delete } from "@mui/icons-material";
import PreqAttachDeleteModal from "../../prequalification/PreqAttachDeleteModal";

function AttachemntsTable({
    isProcessing,
    suppPreqDocs,
    isLoadingAttachments, id }) {
  const [options, setOptions] = useState([
    { id: "Valid Trading License", value: "Valid Trading License" },
    { id: "Certificate of Registration", value: "Certificate of Registration" },
    { id: "Tax Compliance Certificate", value: "Tax Compliance Certificate" },
    {
      id: "Valid AGPO Certificate",
      value: "Valid AGPO Certificate (where applicable)",
    },
  ]);
 
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [prodd, setProdd] = useState();

  const [selectedFile, setSelectedFile] = useState();
  const [docFormData, setDocFormData] = useState({
    entryNo: 0,
    description: "",
    applicationNo: "",
    file1: null,
  });
  const file1Ref = useRef(null);

  const handleDocChange = (e) => {
    setDocFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleFile = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const { entryNo, description, applicationNo, file1 } = docFormData;

  const handleDocSubmit = async () => {
    const document = {
      ...docFormData,
      file1: selectedFile,
      applicationNo: id,
      type: 4,
    };
    const _document = formDataFromObject(document);

    if (!document.description) {
      toast.warning("select what to attach");
    } else {
      const res = await dispatch(saveSupplierAttachment(_document));
      if (res.type == "prequalification/attachment/save/rejected") {
        toast.warning("Record already exists");
      }
    }

    setDocFormData({
      entryNo: 0,
      description: "",
      applicationNo: "",
      file1: null,
    });
    file1Ref.current.value = null;
  };

  const newSuppPreqDocs =  suppPreqDocs?.filter((item)=>item.fileName !=='Form for disclosure of interest' &&
  item.fileName !=='Certificate of independent quotation determination' &&
  item.fileName !=='Self-declaration form'
  
  )



  return (
    <>
      <Typography variant={"h6"} sx={{ my: 2, fontSize: "0.875rem" }}>
        Attachments copies for the quotation{" "}
        <Typography
          variant={"span"}
          sx={{ my: 2, fontSize: "13px", color: "#777" }}
        >
          AGPO Certiﬁcate (where applicable)
        </Typography>
      </Typography>

      <Grid container rowSpacing={1.25} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={6} xs={12}>
          <BootstrapSelect
            id="description"
            name="description"
            selected={description}
            handleChange={handleDocChange}
            label="Choose what to attach*"
            options={options}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Form.Group className="bootstrap-input  mb-1" controlId="formFile">
            <Form.Label className="mb-0">Attach file*</Form.Label>
            <Form.Control type="file" ref={file1Ref} onChange={handleFile} />
          </Form.Group>
        </Grid>
        <Grid item md={6} xs={12}></Grid>
        <Grid item md={6} xs={12} sx={{ mt: 1 }}>
          <FormControl fullWidth variant={"standard"}>
            <FormLabel htmlFor="my-input" variant={"subtitle"}>
              NOTE: You can add as many attachments as possible*
            </FormLabel>
            {!isProcessing ? (
              <Button
                variant="outlined"
                color="primary"
                sx={{ textTransform: "capitalize" }}
                onClick={handleDocSubmit}
              >
                Add Attachment
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                <CircularProgress size={17} color="inherit" />
                &nbsp;Adding...
              </Button>
            )}
          </FormControl>
        </Grid>
      </Grid>

      {isLoadingAttachments ? (
        <Skeleton className="my-1" count={3} height={50} />
      ) : (
        <>
          {newSuppPreqDocs && newSuppPreqDocs?.length ? (
            <>
              <Divider sx={{ my: 2 }} />
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell align="left">File</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newSuppPreqDocs?.map((attachment,index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {attachment?.fileName}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() =>
                            generateAttachment(attachment?.actualFile)
                          }
                        >
                          <Chip
                            label={attachment?.actualFile}
                            color="primary"
                            className="file-chip-primary"
                            size="small"
                            variant="outlined"
                            icon={<AttachFile />}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => setProdd(attachment)}
                          >
                            <Delete
                              fontSize="small"
                              onClick={() => handleClickOpen()}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <PreqAttachDeleteModal
        open={open}
        handleClose={handleClose}
        prodd={prodd}
        applicationNo={id}
        type="4"
      />
    </>
  );
}

export default AttachemntsTable;
