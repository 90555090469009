import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import tenderService from './tenderService';

const initialState = {
    tenders: [],
    filteredTenders: [],
    openTenders: [],
    tenderLine: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    isSuccessOpenTender: false,
    isErrorOpenTender: false,
    isLoadingOpenTender: false,
    isSuccessSaveTender: false,
    isErrorSaveTender: false,
    isLoadingSaveTender: false,
    isSuccessTenderLineApplication: false,
    isErrorTenderLineApplication: false,
    isLoadingTenderLineApplication: false,
    message: '',
    suppTenderDocs: [],
    myTenders: [],
    isCleanupFormTender: false,
}

//get getOpenTenders
export const getOpenTenders = createAsyncThunk(
    'tendersa/getAll', async (
        _, thunkApi) => {
    try {
        // const token = thunkApi.getState().auth.user.token;
        return await tenderService.getOpenTenders()

    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})
//get Tenders
export const getTenders = createAsyncThunk(
    'tenders/getAll', async (
        _, thunkApi) => {
    try {
        // const token = thunkApi.getState().auth.user.token;
        return await tenderService.getTenders()

    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})



// saveTenderAttachment
export const saveTenderAttachment = createAsyncThunk('profile/attach', async (userDataProfile, thunkAPI) => {
    try {
        return await tenderService.saveTenderAttachment(userDataProfile)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});


export const getMyTenders = createAsyncThunk(
    'tenders/getMine', async (
        _, thunkApi) => {
    try {
        const usrId = thunkApi.getState().auth.user.usrId;
        return await tenderService.getMyTenders(usrId)

    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

// save TenderLineApplication
export const saveTenderLineApplication = createAsyncThunk(
    'tenderLineApplication/save',
    async (prequalification, thunkAPI) => {
        try {
            return await tenderService.saveTenderLineApplication(prequalification)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)
// get Open Tenders
// export const getOpenTenders = createAsyncThunk(
//     'Opentenders/getAll',async (
//         _, thunkApi) => {
//     try{
//         // const token = thunkApi.getState().auth.user.token;
//         return await tenderService.getOpenTenders()

//     }catch(error){
//         const message = handleFailure(error)
//         return thunkApi.rejectWithValue(message)
//     }
// })




export const tenderSlice = createSlice({
    name: 'tender',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.isSuccessSaveTender = false
            state.isErrorSaveTender = false
            state.isLoadingSaveTender = false
            state.isSuccessTenderLineApplication = false
            state.isErrorTenderLineApplication = false
            state.isLoadingTenderLineApplication = false
            state.isSuccessOpenTender = false
            state.isErrorOpenTender = false
            state.isLoadingOpenTender = false
            state.message = ''
            state.isCleanupFormTender = false
        },
        setFilteredTenders: (state, action) => {
            state.filteredTenders = action.payload
        },
        cleanupFormTender: (state) => {
            state.tenders = initialState.tenders
            state.filteredTenders = initialState.filteredTenders
            state.openTenders = initialState.openTenders
            state.tenderLine = initialState.tenderLine
            state.suppTenderDocs = initialState.suppTenderDocs
            state.myTenders = initialState.myTenders
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOpenTenders.pending, (state) => {
                state.isLoadingOpenTender = true
            })
            .addCase(getOpenTenders.fulfilled, (state, action) => {
                state.isLoadingOpenTender = false
                state.isSuccessOpenTender = true
                state.openTenders = action.payload
                state.filteredTenders = action.payload
            })

            .addCase(getOpenTenders.rejected, (state, action) => {
                state.isLoadingOpenTender = false
                state.isErrorOpenTender = true
                state.message = action.payload
            })

            .addCase(getTenders.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTenders.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.tenders = action.payload
                state.filteredTenders = action.payload
            })

            .addCase(getTenders.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(saveTenderAttachment.pending, (state) => {
                state.isLoadingSaveTender = true;
            })
            .addCase(saveTenderAttachment.fulfilled, (state, action) => {
                state.isLoadingSaveTender = false;
                state.isSuccessSaveTender = true;
                state.suppTenderDocs = action.payload;
            })
            .addCase(saveTenderAttachment.rejected, (state, action) => {
                state.isLoadingSaveTender = false
                state.isErrorSaveTender = true
                state.message = action.payload
            })

            .addCase(getMyTenders.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMyTenders.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.myTenders = action.payload
            })
            .addCase(getMyTenders.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.myTenders = []
            })

            .addCase(
                saveTenderLineApplication.pending, (state) => {
                    state.isLoadingTenderLineApplication = true
                }
            )
            .addCase(
                saveTenderLineApplication.fulfilled, (state, action) => {
                    state.isLoadingTenderLineApplication = false
                    state.isSuccessTenderLineApplication = true
                    state.message = "Tender Line Application Saved successfully"
                    state.tenderLine = action.payload
                }
            )
            .addCase(
                saveTenderLineApplication.rejected, (state, action) => {
                    state.isLoadingTenderLineApplication = false
                    state.isErrorTenderLineApplication = true
                    state.message = action.payload
                }
            )


        // .addCase(getOpenTenders.pending, (state) =>{
        //     state.isLoadingOpenTender = true
        // })
        // .addCase(getOpenTenders.fulfilled, (state,action) =>{
        //     state.isLoadingOpenTender=false
        //     state.isSuccessOpenTender=true
        //     state.openTenders = action.payload
        // })

        // .addCase(getOpenTenders.rejected, (state,action) =>{
        //     state.isLoadingOpenTender = false
        //     state.isErrorOpenTender=true
        //     state.message = action.payload
        // })


    }
})

export const { reset, setFilteredTenders, cleanupFormTender } = tenderSlice.actions
export default tenderSlice.reducer