import React from 'react';
import {
    InputBase,
    styled,
    FormControl, FormHelperText,
    FormLabel,
    Typography,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { grey, purple, blue } from "@mui/material/colors";
import Form from 'react-bootstrap/Form';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



const BootstrapInput = ({ id, name, key, value, ref, handleChange, type, label,
    multiline, rows, maxRows, placeholder, disabled, isLoading, sub, format }) => {

    const theme = createTheme({
        palette: {
            primary: {
                main: blue[700],
            },
            secondary: {
                main: purple[700],
            },
        },
    });

    const Input = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(0),
            fontSize: '10px !important'
        },
        '& .MuiInputBase-input': {
            borderRadius: 2,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '5px 20px 5px 10px',
            height: '1.5675rem',
            //borderRadius: 5,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderColor: '#1976d2',
                // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
            '&::placeholder': {
                fontWeight: 800,
                fontSize: '14px !important',
                primary: grey,
            },
        },
    }));
    const handleChangefORM = (e) => {
        handleChange(e);
    }
    return (
        <>
            {/* <FormControl fullWidth variant="standard">
            <FormLabel htmlFor="my-input">{label}</FormLabel>
            <Input
                id={id}
                name={name} 
                key={key} 
                value={value} 
                type={type} 
                onChange={handleChange} 
                multiline={multiline} 
                rows={rows} 
                // maxRows={maxRows}
            />
            <FormHelperText id="my-helper-text">{text}</FormHelperText> 
        </FormControl> */}

            <Form.Group className="bootstrap-input mb-1" controlId="formPlaintextPassword">
                {isLoading ? (<Skeleton className="" height={10} width={175} />) : (
                    <Form.Label className="mb-0">
                        {label}
                        {
                            sub ? (
                                <Typography variant={"span"} sx={{ mx: 1, fontSize: '13px', color: '#777' }}>
                                    (optional)</Typography>
                            ) :
                                format ? (
                                    <Typography variant={"span"} sx={{ mx: 1, fontSize: '13px', color: '#777' }}>
                                        eg. 2022-2023</Typography>
                                ) : (
                                    <></>
                                )
                        }

                    </Form.Label>
                )}

                {isLoading ? (<Skeleton height={!multiline ? 35 : 50} />) : (
                    <Form.Control
                        // id={id}
                        placeholder={placeholder}
                        name={name}
                        key={key}
                        value={value}
                        ref={ref}
                        type={type}
                        as={`${!multiline ? 'input' : 'textarea'}`}
                        onChange={handleChange}
                        rows={rows}
                        disabled={disabled}
                    />
                )}

            </Form.Group>

        </>
    )
}

export default BootstrapInput