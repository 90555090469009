import React from 'react';
import './sidemenu.css';

function SideMenu() {
    return (
        <div className="col-lg-4 pb-5">

            {/* Account Sidebar */}
            <div class="author-card pb-3">
                <div class="author-card-cover"> </div>
                <div class="author-card-profile">
                    <div class="author-card-avatar"><img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Daniel Adams" />
                    </div>
                    <div class="author-card-details">
                        <h5 class="author-card-name text-lg">Daniel Adams</h5><span class="author-card-position">Joined February 06, 2017</span>
                    </div>
                </div>
            </div>
            <div className="wizard">
                <nav className="list-group list-group-flush">

                    <a className="list-group-item" href="/profile">
                        <i className="fe-icon-user text-muted">
                        </i>Profile Settings</a>

                    <a className="list-group-item" href="/orders">
                        <div className="d-flex justify-content-between align-items-center">
                            <div><i className="fe-icon-tag mr-1 text-muted"></i>
                                <div className="d-inline-block font-weight-medium text-uppercase">My Orders</div>
                            </div><span className="badge badge-secondary">4</span>
                        </div>
                    </a>
                </nav>
            </div>
        </div>

    )
}

export default SideMenu