import axios from "axios";
import {newDate, newTends} from "../../utils";

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;

// get opentenders
const getOpenTenders = async () => {
    const response = await axios.get(`${API_URL}/supplier/fetchOpenTender.action`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    console.log("res1",response.data.jsonData);
    const data = newDate(response.data.jsonData);
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : response.data.jsonData;
}
// get tenders
const getTenders = async () => {
    const response = await axios.get(`${API_URL}/supplier/fetchOpenTender.action`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    console.log("res1",response.data.jsonData);
    const data = newDate(response.data.jsonData);
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : response.data.jsonData;
}



// const getOpenTenders = async () => {
//     const response = await axios.get(`${API_URL}/supplier/fetchOpenTender.action`, {
//         headers: {
//             'Accept': 'application/json',
//         }
//     })
//     console.log("res",response.data.jsonData);
//     const data = response.data.jsonData;
//     // const data = response.data.jsonData.filter(res => res.eligibility === 'Open');
//     return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : data
// }
 

// saveTenderAttachment
const saveTenderAttachment =async(userDataProfile)=>{
    const config={
      headers: {
                'Accept': 'application/json',}
  }
    const response=await axios.post(`${API_URL}/supplier/saveSupplierProfileAttachment.action`,userDataProfile,config)
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : response.data;
}

  const getMyTenders = async (usrId) => {
    const response = await axios.get(`${API_URL}/supplier/fetchAllAppliedPrequalificationsList.action?supplierId=${usrId}&type=${1}`, {
        headers: {
            'Accept': 'application/json',
        }
    })

    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : response.data.data.result;
}


// save TenderLineApplication
const saveTenderLineApplication = async (prequalification) => {
    const response = await  axios.post(`${API_URL}/supplier/saveTenderLineApplication.action`, prequalification, {
        headers: {
            'Accept': 'application/json',
        }
    })
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
}



const tenderService={
    getTenders,
    saveTenderAttachment,
    getMyTenders,
    saveTenderLineApplication,
    getOpenTenders
}
export default tenderService