import QuotationRequest from '../../components/quotationRequest/QuotationRequest';
import './quotationRequests.css';

const QuotationRequests = () => {
  return (
      
    <div className='container my-2'>
        <div className='row'>
            <QuotationRequest />
        </div>
    </div>
  )
}

export default QuotationRequests