import React, { useEffect } from 'react';

import {Typography, Grid, Card} from '@mui/material';

import BootstrapInput from '../../bootstrap/BootstrapInput';
import 'react-loading-skeleton/dist/skeleton.css';
import {useDispatch, useSelector} from "react-redux";

import {getQuotationHeader, reset} from "../../../features/requestForQuotation/Header/ViewRequestQuotationSlice";
import Skeleton from "react-loading-skeleton";



const RfqHeader = ({id}) => {

    const header_id = id
    console.log(header_id, '---------First RFQ ID')

    const dispatch = useDispatch();

    const {quotation, isLoading, isError, isSuccess, message} = useSelector((state) => state.quotation)

    console.log("view_RFQ_Header", quotation)

    useEffect(() => {
        if (isError) {
            console.log(message)
        }
        dispatch(getQuotationHeader(header_id))

        return () => {
            dispatch(reset())
        }
    }, [isError, message, dispatch])

    return (
        <>
            <Typography
                variant={"h6"}
                sx={{ my: 2, fontSize: '0.875rem' }}
            >
                General
            </Typography>

            {isLoading ? (
                <Grid
                    container
                    rowSpacing={1.25}
                    columnSpacing={{xs: 1, sm: 2, md: 3}}
                >
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BootstrapInput isLoading={isLoading} label=""/>
                    </Grid>
                </Grid>
            ) : (
                <Card sx={{padding: 2, borderRadius: 2}}>
                    {
                        quotation.map((req) =>
                            <Grid
                                container
                                rowSpacing={1.25}
                                columnSpacing={{xs: 1, sm: 2, md: 3}}
                            >
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={req.no}
                                        id={"bootstrap-input"}
                                        label="Number"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={req.rfqType}
                                        id={"bootstrap-input"}
                                        label="Type"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={req.rfqDocDate}
                                        id={"bootstrap-input"}
                                        label="Date of Document"
                                        disabled={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={req.closingDate}
                                        id={"bootstrap-input"}
                                        label="Closing Date"
                                        disabled={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={req.issueDate}
                                        id={"bootstrap-input"}
                                        label="Issue Date"
                                        disabled={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={req.amount}
                                        id={"bootstrap-input"}
                                        label="Amount"
                                        disabled={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BootstrapInput
                                        placeholder={req.rfqDesc}
                                        id={"bootstrap-input"}
                                        label="Description"
                                        disabled={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )
                    }
                </Card>
            )
            }
        </>
    )
}

export default RfqHeader



