import React, { useState } from 'react'
import { ThemeProvider } from '@emotion/react';
import { 
    Article,
    Circle,
    MoreVert,
    Download, 
    Assignment, 
    AssignmentOutlined, 
    NewspaperOutlined} from '@mui/icons-material';
import { 
    Card, 
    Button, 
    Menu, 
    MenuItem, 
    IconButton, 
    Box,
    Chip, createTheme} from '@mui/material';
 
import { dateForHumans, subString } from '../../utils';
import { useNavigate } from 'react-router-dom';

const MyTender = ({tender,handleOpenTender}) => {
    const  navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const finalTheme = createTheme({
    //     components: {
    //         MuiChip: {
    //             styleOverrides: {
    //                 root: sx({
    //                     // https://mui.com/system/getting-started/the-sx-prop/#spacing
    //                     px: 1,
    //                     py: 0.25,
    //                     // https://mui.com/system/borders/#border-radius
    //                     borderRadius: 2, // 4px as default.
    //                 }),
    //                 label: {
    //                     padding: 'initial',
    //                     color: '#1976d2',
    //                 },
    //             },
    //         },
    //     },
    // });

  return (
    <>
        <Card className='prequalification br-10'>
            <div className='row'>
                <div className='col-sm-5 d-flex justify-content-start'>
                    <div className='preq-icon-wrapper'>
                        <NewspaperOutlined className='preq-icon'/>
                    </div>
                    <div className='preq-text-wrapper'>
                        <h6 className='fs-13'>{tender.applicationNo}</h6>
                        {/* <p className='fs-12'>Supply delivery of computer hardware...</p> */}
                        <p className='fs-12'>{subString(tender.description, 60) }</p>
                    </div>
                </div>

                <div className='col-sm-4'>
                    {/* <div className='status d-flex justify-content-start'>
                        <Circle className={`status-icon ${prequalification.eligibility == 'Open' ? 'text-green' : 'text-purple'}`} />
                        <p className='fs-12 px-1'>{prequalification.eligibility}</p>
                    </div> */}
                    <Box sx={{display: 'flex', alignItems: 'end', justifyContent: 'start'}}>
                        <Chip 
                        sx={{ color: '#1976d2', px: 1,py: 0.25,  padding: 'initial',borderRadius: '2px'}}
                        label={
                            <span>
                            Pending
                        </span>
                        }  />
                    </Box>
                </div>

                <div className='col-sm-1'>
                   <Button 
                        sx={{ p: 1, textTransform: 'capitalize', borderRadius: '.75rem', borderColor: '#e7e7e7' }} 
                        className="fs-12" variant="outlined" color="inherit"
                        onClick={handleOpenTender}
                    >
                       View More
                    </Button>
        
                </div>
            </div>
        </Card>
    </>
  )
}

export default MyTender