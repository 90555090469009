import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { Card, CardContent, CardHeader, CircularProgress, FormControl, FormLabel, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Article, CloudUploadOutlined, Download, PictureAsPdf } from "@mui/icons-material";
import { formDataFromObject, generateAttachment, subString } from "../../../utils";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { savePurchaseAttachment } from "../../../features/purchase/Attachment/ViewPurchaseAttachmentSlice";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { getSupplierAttachments, saveSupplierAttachment } from '../../../features/preqs/preqSlice';
import PreqAttachDeleteModal from '../../prequalification/PreqAttachDeleteModal';

const Attachments = ({
    id,
    suppPreqDocs,
    isProcessing,
    isLoadingAttachments
}) => {

    const Lines_id = id;
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const newAttachDoc = suppPreqDocs?.find((item) => item.fileName === 'Form for disclosure of interest')
    const [attachmentDoc, setAttachmentDoc] = useState();

    const newIndpQuotation = suppPreqDocs?.find((item) => item.fileName === 'Certificate of independent quotation determination')
    const [taxDoc, setTaxDoc] = useState();

    const newDeclaration = suppPreqDocs?.find((item) => item.fileName === 'Self-declaration form')
    const [businessDoc, setBusinessDoc] = useState();


    const handleAttachmentDoc = async (e) => {
        setAttachmentDoc(e.target.files[0]);
        const document = {
            entryNo: 0,
            description: "Form for disclosure of interest",
            file1: e.target.files[0],
            applicationNo: id,
            type: 4,
        };
        const _document = formDataFromObject(document);
        const res = await dispatch(saveSupplierAttachment(_document));
        if (res.type == "prequalification/attachment/save/rejected") {
            toast.warning("Error occurred, Try upload again");
        }
    }

    const handleTaxDoc = async (e) => {
        setTaxDoc(e.target.files[0]);
        const document = {
            entryNo: 0,
            description: "Certificate of independent quotation determination",
            file1: e.target.files[0],
            applicationNo: id,
            type: 4,
        };
        const _document = formDataFromObject(document);
        const res = await dispatch(saveSupplierAttachment(_document));
        if (res.type == "prequalification/attachment/save/rejected") {
            toast.warning("Error occurred, Try upload again");
        }
    }

    const handleBusinessDoc = async (e) => {
        setBusinessDoc(e.target.files[0]);
        const document = {
            entryNo: 0,
            description: "Self-declaration form",
            file1: e.target.files[0],
            applicationNo: id,
            type: 4,
        };
        const _document = formDataFromObject(document);
        const res = await dispatch(saveSupplierAttachment(_document));
        if (res.type == "prequalification/attachment/save/rejected") {
            toast.warning("Error occurred, Try upload again");
        }
    }

    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    // const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const date = `${month} ${day},${year}`;

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [prodd, setProdd] = useState();

    useEffect(() => {
        setAttachmentDoc(newAttachDoc ? newAttachDoc : null);
        setTaxDoc(newIndpQuotation ? newIndpQuotation : null);
        setBusinessDoc(newDeclaration ? newDeclaration : null)
    }, [
        newAttachDoc,
        newIndpQuotation,
        newDeclaration
    ])


    return (
        <>
            <Typography
                variant={"h6"}
                sx={{ mt: 2, mb: 1, fontSize: '0.875rem' }}
            >
                Attachments
            </Typography>
            <Typography
                variant={"subtitle1"}
                sx={{ mb: 2, fontSize: '0.875rem' }}
            >
                Please attach the necessary files needed
            </Typography>

            {
                isLoadingAttachments ? (
                    <Skeleton className="my-1" count={3} height={50} />
                ) : (

                    <Paper sx={{ pl: 4, pr: 4, pb: 4, pt: 1, my: 0, }}>
                        <Grid container spacing={2} sx={{ alignItems: 'center', mb: 1 }}>
                            <Grid item md={6} xs={6} sx={{ mb: 1 }}>
                                <Typography
                                    variant={"h6"}
                                    sx={{ mb: 1, mx: 0, fontSize: '0.875rem', textTransform: 'capitalize' }}
                                >
                                    Download Form for disclosure of interest
                                </Typography>
                                <FormControl variant={"standard"}>
                                    <FormLabel htmlFor="my-input" variant={"subtitle"}>
                                        NOTE: Download fill document offline then load it *
                                    </FormLabel>
                                    <Button
                                        onClick={() => generateAttachment('REGISTRATION.doc')}
                                        sx={{
                                            textTransform: "capitalize",
                                            backgroundColor: "#e6fafd !important",
                                            color: "#00baba",
                                        }}
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<Download />}
                                    >
                                        Download form for disclosure of interest
                                    </Button>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Card elevation={0}>
                                    <Typography
                                        variant={"h6"}
                                        sx={{ mt: 2, mb: 1, mx: 1, fontSize: '0.875rem', textTransform: 'capitalize' }}
                                    >
                                        Upload Disclosure of Interest
                                    </Typography>

                                    <CardContent>
                                        <div style={{
                                            display: 'flex', justifyContent: 'center', alignItems: 'center',
                                            border: 'none',
                                            // border: '1px solid var(--color-primary-light)',
                                            width: '365px',
                                            height: '50px'
                                        }}>
                                            {
                                                !attachmentDoc ? (
                                                    <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                        <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                            <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                                <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                            </div>
                                                            <Typography variant="caption" className="upload-sub-label">
                                                                Click here to upload
                                                            </Typography>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            name='uploadimage'
                                                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            onChange={handleAttachmentDoc}
                                                            style={{ width: '0px', height: '0px' }}
                                                        />

                                                    </label>
                                                ) : (
                                                    <>
                                                        <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                            <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: '5px 0px 0px 5px' }}>
                                                                    <span style={{ margin: "0px 10px" }}>
                                                                        {
                                                                            attachmentDoc.type === "application/pdf" ? (
                                                                                <PictureAsPdf sx={{ fontSize: '40px', color: 'red' }} />
                                                                            ) : (
                                                                                <Article sx={{ fontSize: '40px', color: '#1976d2' }} />
                                                                            )
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                                                        {attachmentDoc?.name ? attachmentDoc.name : attachmentDoc.actualFile}
                                                                    </Typography>

                                                                    <Typography sx={{ fontWeight: 'lighter', fontSize: 12 }}>
                                                                        uploaded on  {date}
                                                                    </Typography>
                                                                </div>
                                                                <div className="flex align-items-end justify-content-end">
                                                                    <IconButton
                                                                        disabled={isProcessing}
                                                                        aria-label="close"
                                                                        onClick={() => setProdd(newAttachDoc)}
                                                                        sx={{
                                                                            color: (theme) => theme.palette.grey[500], mr: 5, alignItems: 'end'
                                                                        }}
                                                                    >
                                                                        {
                                                                            isProcessing ? (
                                                                                <CircularProgress color="success" />
                                                                            ) : (
                                                                                <CloseIcon
                                                                                    onClick={() => handleClickOpen()} />
                                                                            )
                                                                        }

                                                                    </IconButton>
                                                                </div>
                                                            </div>

                                                        </label>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item md={6} xs={6} sx={{ mb: 1 }}>
                                <Typography
                                    variant={"h6"}
                                    sx={{ mb: 1, mx: 0, fontSize: '0.875rem', textTransform: 'capitalize' }}
                                >
                                    Download Certificate Of Independent Quotation Determination
                                </Typography>
                                <FormControl variant={"standard"}>
                                    <FormLabel htmlFor="my-input" variant={"subtitle"}>
                                        NOTE: Download fill document offline then load it *
                                    </FormLabel>
                                    <Button
                                        onClick={() => generateAttachment('REGISTRATION.doc')}
                                        sx={{
                                            textTransform: "capitalize",
                                            backgroundColor: "#e6fafd !important",
                                            color: "#00baba",
                                        }}
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<Download />}
                                    >
                                        Download certificate of independent quotation determination
                                    </Button>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Card elevation={0}>
                                    <Typography
                                        variant={"h6"}
                                        sx={{ mt: 2, mb: 1, mx: 1, fontSize: '0.875rem', textTransform: 'capitalize' }}
                                    >
                                        Upload certificate of independent quotation determination
                                    </Typography>
                                    <CardContent>
                                        <div style={{
                                            display: 'flex', justifyContent: 'center', alignItems: 'center',
                                            border: 'none',
                                            // border: '1px solid var(--color-primary-light)',
                                            width: '365px',
                                            height: '50px'
                                        }}>
                                            {
                                                !taxDoc ? (
                                                    <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                        <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                            <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                                <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                            </div>
                                                            <Typography variant="caption" className="upload-sub-label">
                                                                Click here to upload
                                                            </Typography>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            name='file'
                                                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            onChange={handleTaxDoc}
                                                            style={{ width: '0px', height: '0px' }}
                                                        />

                                                    </label>
                                                ) : (
                                                    <>
                                                        <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                            <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: '5px 0px 0px 5px' }}>
                                                                    <span style={{ margin: "0px 10px" }}>
                                                                        {
                                                                            taxDoc.type === "application/pdf" ? (
                                                                                <PictureAsPdf sx={{ fontSize: '40px', color: 'red' }} />
                                                                            ) : (
                                                                                <Article sx={{ fontSize: '40px', color: '#1976d2' }} />
                                                                            )
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                                                        {taxDoc?.name ? taxDoc.name : taxDoc.actualFile}
                                                                    </Typography>

                                                                    <Typography sx={{ fontWeight: 'lighter', fontSize: 12 }}>
                                                                        uploaded on  {date}
                                                                    </Typography>
                                                                </div>
                                                                <div className="flex align-items-end justify-content-end">
                                                                    <IconButton
                                                                        disabled={isProcessing}
                                                                        aria-label="close"
                                                                        onClick={() => setProdd(newIndpQuotation)}
                                                                        sx={{
                                                                            color: (theme) => theme.palette.grey[500], mr: 5, alignItems: 'end'
                                                                        }}
                                                                    >
                                                                        {
                                                                            isProcessing ? (
                                                                                <CircularProgress color="success" />
                                                                            ) : (
                                                                                <CloseIcon
                                                                                    onClick={() => handleClickOpen()} />
                                                                            )
                                                                        }

                                                                    </IconButton>
                                                                </div>
                                                            </div>

                                                        </label>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item md={6} xs={6} sx={{ mb: 1 }}>
                                <Typography
                                    variant={"h6"}
                                    sx={{ mb: 1, mx: 0, fontSize: '0.875rem', textTransform: 'capitalize' }}
                                >
                                    Download Self-declaration Form
                                </Typography>
                                <FormControl variant={"standard"}>
                                    <FormLabel htmlFor="my-input" variant={"subtitle"}>
                                        NOTE: Download fill document offline then load it *
                                    </FormLabel>
                                    <Button
                                        onClick={() => generateAttachment('REGISTRATION.doc')}
                                        sx={{
                                            textTransform: "capitalize",
                                            backgroundColor: "#e6fafd !important",
                                            color: "#00baba",
                                        }}
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<Download />}
                                    >
                                        Download self-declaration form
                                    </Button>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Card elevation={0}>
                                    <Typography
                                        variant={"h6"}
                                        sx={{ mt: 2, mb: 1, mx: 1, fontSize: '0.875rem', textTransform: 'capitalize' }}
                                    >
                                        Self-declaration form
                                    </Typography>

                                    <CardContent>
                                        <div style={{
                                            display: 'flex', justifyContent: 'center', alignItems: 'center',
                                            border: 'none',
                                            width: '365px',
                                            height: '50px'
                                        }}>
                                            {
                                                !businessDoc ? (
                                                    <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                        <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                            <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                                <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                            </div>
                                                            <Typography variant="caption" className="upload-sub-label">
                                                                Click here to upload
                                                            </Typography>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            name='file'
                                                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            onChange={handleBusinessDoc}
                                                            style={{ width: '0px', height: '0px' }}
                                                        />

                                                    </label>
                                                ) : (
                                                    <>
                                                        <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                            <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: '5px 0px 0px 5px' }}>
                                                                    <span style={{ margin: "0px 10px" }}>
                                                                        {
                                                                            businessDoc.type === "application/pdf" ? (
                                                                                <PictureAsPdf sx={{ fontSize: '40px', color: 'red' }} />
                                                                            ) : (
                                                                                <Article sx={{ fontSize: '40px', color: '#1976d2' }} />
                                                                            )
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                                                        {businessDoc?.name ? businessDoc.name : businessDoc.actualFile}
                                                                    </Typography>

                                                                    <Typography sx={{ fontWeight: 'lighter', fontSize: 12 }}>
                                                                        uploaded on  {date}
                                                                    </Typography>
                                                                </div>
                                                                <div className="flex align-items-end justify-content-end">
                                                                    <IconButton
                                                                        disabled={isProcessing}
                                                                        aria-label="close"
                                                                        onClick={() => setProdd(newDeclaration)}
                                                                        sx={{
                                                                            color: (theme) => theme.palette.grey[500], mr: 5, alignItems: 'end'
                                                                        }}
                                                                    >
                                                                        {
                                                                            isProcessing ? (
                                                                                <CircularProgress color="success" />
                                                                            ) : (
                                                                                <CloseIcon
                                                                                    onClick={() => handleClickOpen()} />
                                                                            )
                                                                        }
                                                                    </IconButton>
                                                                </div>
                                                            </div>

                                                        </label>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                    </Paper>
                )
            }
            <PreqAttachDeleteModal
                open={open}
                handleClose={handleClose}
                prodd={prodd}
                applicationNo={id}
                type="4"
                setAttachmentDoc={setAttachmentDoc}
                setTaxDoc={setTaxDoc}
                setBusinessDoc={setBusinessDoc}
            />
        </>
    );
};

export default Attachments;
