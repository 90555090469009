import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../../utils';
import ViewPurchaseLines from "./ViewPurchaseLinesServices";
import {formatGridRows} from "../../../utils";



const initialState = {
    viewPurchaseLines: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: '',
    isCleanupFormPurchaseLine: false,
}

//get View Purchase Lines
export const getPurchaseLines = createAsyncThunk(
    'viewPurchaseLines/getAll',
    async (Lines_id, thunkApi) =>{
    try{
        // const token = thunkApi.getState().auth.user.token;
        return await ViewPurchaseLines.getPurchaseLines(Lines_id)

    }catch(error){
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

// update
export const updatePurchaseLines =createAsyncThunk(
    'viewPurchaseLines/update',
    async(purchaseLine,thunkAPI)=>{
    try{
        return await ViewPurchaseLines.updatePurchaseLines(purchaseLine)
    }catch(error){
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//Save
export const savePurchaseLines =createAsyncThunk(
    'viewPurchaseLines/save',
    async(quoteNo,thunkAPI)=>{
        try{
            return await ViewPurchaseLines.savePurchaseLines(quoteNo)
        }catch(error){
            const message = handleFailure(error)
            return thunkAPI.rejectWithValue(message)
        }
    });


export const ViewPurchaseLinesSlice = createSlice({
    name: 'viewPurchaseLines',
    initialState,
    reducers: {
        reset: (state) => initialState,
        cleanupFormPurchaseLine: (state) => {
            state.viewPurchaseLines = initialState.viewPurchaseLines
        }
    },
    extraReducers:(builder)=>{
        builder
            .addCase(getPurchaseLines.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(getPurchaseLines.fulfilled, (state,action) =>{
                state.isLoading=false
                state.isSuccess=true
                state.viewPurchaseLines = formatGridRows(action.payload)
            })
            .addCase(getPurchaseLines.rejected, (state,action) =>{
                state.isLoading = false
                state.isError=true
                state.message = action.payload
            })

            .addCase(updatePurchaseLines.pending,(state)=>{
                state.isLoading = true;
                state.error = false;
            })
            .addCase(updatePurchaseLines.fulfilled,(state,action)=>{
                state.viewPurchaseLines = action.payload;
                state.isLoading = false;
            })
            .addCase(updatePurchaseLines.rejected,(state)=>{
                state.isLoading = false
                state.isError = true
            })

            .addCase(savePurchaseLines.pending,(state)=>{
                state.isLoading = true;
                state.error = false;
            })
            .addCase(savePurchaseLines.fulfilled,(state,action)=>{
                state.viewPurchaseLines = action.payload;
                state.isLoading = false;
            })
            .addCase(savePurchaseLines.rejected,(state)=>{
                state.isLoading = false
                state.isError = true
            })
    }
})

export const { reset,cleanupFormPurchaseLine } = ViewPurchaseLinesSlice.actions
export default ViewPurchaseLinesSlice.reducer

