import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { deleteSupplierProfileAttachment, getSupplierAttachments } from '../../features/preqs/preqSlice';

export default function PreqAttachDeleteModal({
  open,
  handleClickOpen,
  handleClose,
  prodd,
  applicationNo,
  appNo,
  type,
  setAttachmentDoc,
  setTaxDoc,
  setBusinessDoc
}) {
  const { isSuccessDeleteAtt  } = useSelector((state) => state.preq)
  
 
  const dispatch = useDispatch();
   
  const handleFileDelete=async()=>{
    const res = await dispatch(deleteSupplierProfileAttachment({
        entryNo: prodd.entryNo,
    }));
    if (res.type == 'prequalifications/delete/fulfilled'){
      toast.success("Attachment deleted successfully")
      handleClose();
      dispatch(getSupplierAttachments({
        no: applicationNo ?applicationNo : appNo,
        type: type
    }));
    if(prodd?.fileName === "Form for disclosure of interest"){
      setAttachmentDoc(null);
      return;
    }
    if(prodd?.fileName === "Certificate of independent quotation determination"){
      setTaxDoc(null);
      return;
    }
    if(prodd?.fileName === "Self-declaration form"){
      setBusinessDoc(null);
      return;
    }
   
    }else{
      toast.warning("Failed to delete attachment")
    }
}

  useEffect(()=>{
    if(isSuccessDeleteAtt){
      // toast.success("Attachment deleted successfully")
      // handleClose()
    } 
  },[isSuccessDeleteAtt])
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          You won't be able to revert this!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
           onClick={()=>{handleFileDelete()}} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}