import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid  from '@mui/material/Grid';

import './quotationDialog.css'
import { ArrowForwardIosSharp } from '@mui/icons-material';


const QuotationDialog = ({ open, handleClickOpen, handleClose }) => {


  return (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle color="primary.dark">Quotation [RFQ002] </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill all the required information.
          </DialogContentText>
          <TextField
            margin="normal"
            required
            autoFocus
            margin="dense"
            id="unit-price"
            label="Unit price"
            type="text"
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            margin="normal"
            required
            margin="dense"
            id="days-deliver"
            label="Days to Deliver"
            type="text"
            fullWidth
            sx={{ mb: 2 }}
          />

          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
                <TextField
                  margin="normal"
                  required
                  margin="dense"
                  id="brand"
                  label="Brand"
                  type="text"
                  fullWidth
                  sx={{ mb: 2 }}
                />
            </Grid>

            <Grid item xs={6} md={6}>
                <TextField
                  margin="normal"
                  required
                  margin="dense"
                  id="origin"
                  label="Country of Origin"
                  type="text"
                  fullWidth
                  sx={{ mb: 2 }}
                />
            </Grid>
          </Grid>

          <TextField
            id="outlined-multiline-flexible"
            label="Remarks"
            multiline
            maxRows={2}
            fullWidth
          />

          
        </DialogContent>
        <DialogActions sx={{ mx: 2 }}>
          <Button 
            onClick={handleClose} 
            color="inherit"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleClose} 
            variant="contained"
            disableElevation endIcon={<ArrowForwardIosSharp />}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default QuotationDialog