import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import axios from "axios"
import profileService from './profileService';


const initialState = {
    profile: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    isErrorUpdate: false,
    isLoadingUpdate: false,
    isSuccessUpdate: false,
    message: '',
    suppProfileDocs: [],
    isCleanupFormProfile: false,
}


// get  Profile
export const getProfile = createAsyncThunk('profile/getAll', async (_, thunkApi) => {
    try {
        const usrId = thunkApi.getState().auth.user.usrId;
        return await profileService.getProfile(usrId);

    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})



// update
export const updateProfile = createAsyncThunk('profile/update', async (userData, thunkAPI) => {
    console.log("receivedslice",userData)
    try {
        return await profileService.updateProfile(userData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});


// saveAttachment
export const saveAttachment = createAsyncThunk('profile/attach', async (userDataProfile, thunkAPI) => {
    try {
        return await profileService.saveAttachment(userDataProfile)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

// getProfileAttachment
export const getProfileAttachments = createAsyncThunk(
    'profile/supplier/attachments',
    async (params, thunkAPI) => {
        try {
            const no = thunkAPI.getState().auth.user.usrId;
            //   const no = 1906;
            return await profileService.getProfileAttachments(params)
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        // reset: (state) => initialState,
        reset: (state) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.isErrorUpdate = false
            state.isLoadingUpdate = false
            state.isSuccessUpdate = false
            state.message = ''
            state.isCleanupFormProfile = false
        },
        cleanupFormProfile: (state) => {
            state.profile = initialState.profile
            state.suppProfileDocs = initialState.suppProfileDocs
        }
    },
    extraReducers: (builder) => {
        builder

            .addCase(getProfile.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.profile = action.payload[0]

            })
            .addCase(getProfile.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })


            .addCase(updateProfile.pending, (state) => {
                state.isLoadingUpdate = true;
                state.errorUpdate = false;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.profile = action.payload;
                state.isLoadingUpdate = false;
                state.isSuccessUpdate = true
            })
            .addCase(updateProfile.rejected, (state) => {
                state.isLoadingUpdate = false
                state.isErrorUpdate = true
            })


            .addCase(saveAttachment.pending, (state) => {
                state.isLoadingAttach = true;
                state.error = false;
            })
            .addCase(saveAttachment.fulfilled, (state, action) => {
                state.profile = action.payload;
                state.isLoading = false;
            })
            .addCase(saveAttachment.rejected, (state) => {
                state.isLoading = false
                state.isError = true
            })


            .addCase(
                getProfileAttachments.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                getProfileAttachments.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.suppProfileDocs = action.payload
                }
            )
            .addCase(
                getProfileAttachments.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )

    }
})

export const { 
    reset, 
    setProfile,
    cleanupFormProfile } = profileSlice.actions
export default profileSlice.reducer