import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;

// get RFQ Header
const getQuotationHeader = async (header_id) => {
    const response = await axios.get(`${API_URL}/supplier/fetchRequestForQuotationHeaderList.action?no=${header_id}`)
    // console.log("profile",response.data.data.result[0])
    const result = response.data.data.result;
    response.data.data.result = [];
    return !response.data.success ? Promise.reject(response.data.messages.mainMessage) : result;
}



const requestQuotationHeaderService={
    getQuotationHeader,
}
export default requestQuotationHeaderService