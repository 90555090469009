import {
    ArrowForwardSharp,
    CalendarToday,
    LocationSearching,
    MailOutline,
    PermIdentity,
    PhoneAndroid,
    Publish,
} from "@mui/icons-material";
import { Button, TextField, Card, CardContent, Typography, CardActions, CardMedia } from "@mui/material";
import { useState } from "react";
import * as React from 'react';
import { Link } from "react-router-dom";
import "./user.css";
import axios from 'axios';
import pic from "../../img/151.jpeg";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



export default function User() {
    const [krafile, setKraFile] = useState('/img/151.jpeg');
    const [file, setFile] = useState('');
    const [vatfile, setVatFile] = useState('/img/151.jpeg');
    const [regfile, setRegFile] = useState('/img/151.jpeg');
    const [othersfile, setOthersFile] = useState('/img/151.jpeg');
    const [supplierDetails,setSupplierDetails] = useState([])
   
    const location = useLocation();
    const id = location.pathname.split("/")[2]
    // console.log("id",id)
    const [user, setUser] = useState({
        // address: "",
        usrpContactPerson: "",
        // usrMobileNumber: "",
        usrpMobileNumber:"",
        usrpCompanyName: "",
        physicalAddress: "",
        postalAddress: "",
        usrEmail: "",
        website: "",
        legalStatus: "",
        maxBusinessValue: "",
        businessNature: "",
        kraPinCertNo: "",
        vatCertNo: "",
        companyRegCertNo: "",
        bankersName: "",
        tradeTerms: "",
        usrpCountry: "",
        usrpCounty: "",
        usrFirstName:"",
        usrEncryptedPassword:"",
        usrEncryptedPasswordAlt:""
        // dateCreated: "",
        // dateUpdated: "",
      
   
    });

    const [loading, setLoading] = useState(true);

    const handleChangeFile = (e) => {

        setFile(URL.createObjectURL(e.target.files[0]));
    };
    const handleChange = (e) => {
        const value = e.target.value;
        setSupplierDetails({
          ...supplierDetails,
          [e.target.name]: value
        })
      }

    

    const handleSubmit1 = async (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("kraFile", file);
        // formData.append("file", this.file);
        //     formData.append("attId", this.attId);
        //     formData.append("imgDesc", this.imgDesc);

        try {
            const response = await axios({
                method: "post",
                url: "",
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });

        } catch (error) {
         
        }

    }

    const removeSelectedImage = () =>{
        setFile();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const userData1 = {
            // address: user.address,
            usrFirstName:user.usrFirstName,
            usrEncryptedPassword: user.usrEncryptedPassword,
            usrEncryptedPasswordAlt: user.usrEncryptedPasswordAlt,
            usrpContactPerson: supplierDetails.usrpContactPerson,
            usrpMobileNumber: supplierDetails.usrpMobileNumber,
            usrpCompanyName: supplierDetails.usrpCompanyName,
            usrpEmail: supplierDetails.usrpEmail,
            usrpKraPinCertNo: supplierDetails.usrpKraPinCertNo,
            usrpCountry: supplierDetails.usrpCountry,
            usrpCounty: supplierDetails.usrpCounty,
           


            // dateCreated: user.dateCreated,
            // dateUpdated: user.dateUpdated,
            // userId: user.userId,

            // physicalAddress: user.physicalAddress,
            // postalAddress: user.postalAddress,
            // website: user.website,
            // legalStatus: user.legalStatus,
            // maxBusinessValue: user.maxBusinessValue,
            // businessNature: user.businessNature,
            // vatCertNo: user.vatCertNo,
            // companyRegCertNo: user.companyRegCertNo,
            // bankersName: user.bankersName,
            // tradeTerms: user.tradeTerms,
            
        };
        

        axios.post(`http://localhost:8080/supplier/saveSupplierProfileInfo.action?usrFirstName=${user.usrFirstName}&usrEncryptedPassword=${user.usrEncryptedPassword}&usrpContactPerson=${supplierDetails.usrpContactPerson}&usrMobileNumber=${user.usrMobileNumber}&usrpCompanyName=${supplierDetails.usrpCompanyName}&usrEmail=${user.usrEmail}&usrpKraPinCertNous=${user.usrpKraPinCertNo}&usrpCountry=${user.usrpCountry}&usrpCounty=${user.usrpCounty}&usrEncryptedPasswordAlt=${user.usrEncryptedPasswordAlt}`,
        {
            
                headers: {'Accept': 'application/json'}
             
        })
            .then((response) => {

           

            }).catch((error) => {
       
            })
    }

    const getSupplierDetails = async()=>{
        try{
          const res= await axios.get(`http://localhost:8080/supplier/fetchSupplierProfileInfo.action?usrId=1864`
            // const res= await axios.get(`http://localhost:8080/supplier/fetchSupplierProfileInfo.action`
            );
            // console.log("response1",res.data.data.result[0])
            setSupplierDetails(res.data.data.result[0])
            setLoading(false);
          }catch(err){
            setLoading(false);
          }
      }
    
      useEffect(()=>{
          getSupplierDetails();
      },[]);
 
    return (
        <div>
            <div className="user mb-5">
                <div className="userTitleContainer">
                    <h4 className="userTitle">Profile Information</h4>
                    {/* <Link to="/newUser">
            <button className="userAddButton">Create</button>
          </Link> */}
                </div>
                <div className="userContainer">
                    <div className="userShow">
                        <div className="userShowTop">
                            <img
                                src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                                alt=""
                                className="userShowImg"
                            />
                            <div className="userShowTopTitle">
                                <span className="userShowUsername">Jobu</span>
                                <span className="userShowUserTitle">Software Engineer</span>
                            </div>
                        </div>
                        <div className="userShowBottom">
                            <span className="userShowTitle">Account Details</span>
                            <div className="userShowInfo">
                                <PermIdentity className="userShowIcon" />
                                <span className="userShowInfoTitle">jobu22</span>
                            </div>
                            <div className="userShowInfo">
                                <CalendarToday className="userShowIcon" />
                                <span className="userShowInfoTitle">10.12.1999</span>
                            </div>
                            <span className="userShowTitle">Contact Details</span>
                            <div className="userShowInfo">
                                <PhoneAndroid className="userShowIcon" />
                                <span className="userShowInfoTitle">+254 123 456 67</span>
                            </div>
                            <div className="userShowInfo">
                                <MailOutline className="userShowIcon" />
                                <span className="userShowInfoTitle">jobu@gmail.com</span>
                            </div>
                            <div className="userShowInfo">
                                <LocationSearching className="userShowIcon" />
                                <span className="userShowInfoTitle">NAIROBI | KENYA</span>
                            </div>
                        </div>
                    </div>
                    <div className="userUpdate">
                        <span className="userUpdateTitle">Edit</span>
                        {/* {!loading ? 
                         ( */}
                            <form className="userUpdateForm" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-xl-10 col-md-10 col-12 mt-0">
                                        <div className="userUpdateLeft">
                                            <div className="row">

                                            <div className="col-md-6">
                                                    <div className="form-group">
                                                        { loading ? ( <Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="usrFirstName"
                                                            label="usr FirstName"
                                                            name="usrFirstName"
                                                            autoComplete="usrpContactPerson"
                                                            value={'user.usrFirstName'}
                                                            onChange={handleChange}
                                                            autoFocus
                                                            // defaultValue={supplierDetails.usrpContactPerson ? supplierDetails.usrpContactPerson : "Contact person"}
                                                        />
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="telephone"
                                                            label="telephone"
                                                            name="usrpMobileNumber"
                                                            autoComplete="telephone"
                                                            value={supplierDetails.usrpMobileNumber}
                                                            onChange={handleChange}
                                                          
                                                        />
                                                    )}
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="email"
                                                            label="email"
                                                            name="usrpEmail"
                                                            autoComplete="email"
                                                            value={supplierDetails.usrpEmail}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        { loading ? ( <Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="usrEncryptedPassword"
                                                            label="usrEncryptedPassword"
                                                            name="usrEncryptedPassword"
                                                            autoComplete="usrpContactPerson"
                                                            value={user.usrEncryptedPassword}
                                                            onChange={handleChange}
                                                            autoFocus
                                                            // defaultValue={supplierDetails.usrpContactPerson ? supplierDetails.usrpContactPerson : "Contact person"}
                                                        />
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        { loading ? ( <Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="usrEncryptedPasswordAlt"
                                                            label="usrEncryptedPasswordAlt"
                                                            name="usrEncryptedPasswordAlt"
                                                            autoComplete="usrEncryptedPasswordAlt"
                                                            value={user.usrEncryptedPasswordAlt}
                                                            onChange={handleChange}
                                                            autoFocus
                                                            // defaultValue={supplierDetails.usrpContactPerson ? supplierDetails.usrpContactPerson : "Contact person"}
                                                        />
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    { loading ? ( <Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="companyName"
                                                            label="Company name"
                                                            name="usrpCompanyName"
                                                            autoComplete="companyName"
                                                            value={supplierDetails.usrpCompanyName}
                                                            onChange={handleChange}
                                                            // defaultValue={supplierDetails.usrpCompanyName}
                                                        />
                                                    )}
                                                    </div>
                                                </div>

                                                
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        { loading ? ( <Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="usrpContactPerson"
                                                            label="Contact Person"
                                                            name="usrpContactPerson"
                                                            autoComplete="usrpContactPerson"
                                                            value={supplierDetails.usrpContactPerson}
                                                            onChange={handleChange}
                                                            autoFocus
                                                            // defaultValue={supplierDetails.usrpContactPerson ? supplierDetails.usrpContactPerson : "Contact person"}
                                                        />
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="country"
                                                            label="country"
                                                            name="usrpCountry"
                                                            autoComplete="country"
                                                            value={supplierDetails.usrpCountry}
                                                            onChange={handleChange}
                                                            autoFocus
                                                        />
                                                        )}
                                                    </div>
                                                </div>

                                                
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="county"
                                                            label="county"
                                                            name="usrpCounty"
                                                            autoComplete="county"
                                                            value={supplierDetails.usrpCounty}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="kraPinCertNo"
                                                            label="kraPinCertNo"
                                                            name="usrpKraPinCertNo"
                                                            autoComplete="kraPinCertNo"
                                                            value={supplierDetails.usrpKraPinCertNo}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="maxBusinessValue"
                                                            label="maxBusinessValue"
                                                            name="maxBusinessValue"
                                                            autoComplete="maxBusinessValue"
                                                            value={user.maxBusinessValue}
                                                            onChange={handleChange}
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div> */}

                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="physicalAddress"
                                                            label="physicalAddress"
                                                            name="physicalAddress"
                                                            autoComplete="physicalAddress"
                                                            value={user.physicalAddress}
                                                            defaultValue="Hello World"
                                                            onChange={handleChange}
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div> */}

                                              


                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="businessNature"
                                                            label="businessNature"
                                                            name="businessNature"
                                                            autoComplete="businessNature"
                                                            value={user.businessNature}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div> */}
                                             
                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="legalStatus"
                                                            label="legalStatus"
                                                            name="legalStatus"
                                                            autoComplete="legalStatus"
                                                            value={user.legalStatus}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="postalAddress"
                                                            label="postalAddress"
                                                            name="postalAddress"
                                                            autoComplete="postalAddress"
                                                            value={user.postalAddress}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="bankersName"
                                                            label="bankersName"
                                                            name="bankersName"
                                                            autoComplete="bankersName"
                                                            value={user.bankersName}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="website"
                                                            label="website"
                                                            name="website"
                                                            autoComplete="website"
                                                            value={user.website}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div> */}

                                             
                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="tradeTerms"
                                                            label="tradeTerms"
                                                            name="tradeTerms"
                                                            autoComplete="tradeTerms"
                                                            value={user.tradeTerms}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="vatCertNo"
                                                            label="vatCertNo"
                                                            name="vatCertNo"
                                                            autoComplete="vatCertNo"
                                                            value={user.vatCertNo}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    {loading  ? (<Skeleton height={35} />) :(
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="companyRegCertNo"
                                                            label="companyRegCertNo"
                                                            name="companyRegCertNo"
                                                            autoComplete="companyRegCertNo"
                                                            value={user.companyRegCertNo}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    )}
                                                    </div>
                                                </div> */}

                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Basic example"
                                                                value={value}
                                                                onChange={(newValue) => {
                                                                setValue(newValue);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                            </LocalizationProvider>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Basic example"
                                                    value={value}
                                                    onChange={(newValue) => {
                                                    setValue(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                                </LocalizationProvider>
                                                    </div>
                                                </div> */}

                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="userId"
                                                            label="userId"
                                                            name="userId"
                                                            autoComplete="userId"
                                                            value={user.userId}
                                                            onChange={handleChange}
                                                            type="text"
                                                            autoFocus
                                                        />
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-2 col-md-2 col-12 userUpdateRight">
                                        {/* <div className="userUpdateRight"> */}
                                        <div className="userUpdateUpload">
                                            <img
                                                className="userUpdateImg"
                                                src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                                                alt=""
                                            />
                                            <label htmlFor="file">
                                                <Publish className="userUpdateIcon" />
                                            </label>
                                            <input type="file" id="file" style={{ display: "none" }} />
                                        </div>
                                        <button className="userUpdateButton">Update</button>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </form>
                        {/* ) :

                        (<p>loading ...</p>)

                       
                        } */}
                        
                        
                    </div>
                </div>
            </div>

            {/* <div className="attachments">
            bou
           </div> */}
            <Card>
                <div className="userTitleContainer">
                    <h4 className="userTitle">Attachments [.png .jpg .jpeg .pdf]</h4>
                </div>
                <div className="attachmentContainer">
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <Card sx={{ height: 380 }}>
                                <form onSubmit={handleSubmit1}>
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                            KRA PIN Certificate
                                        </Typography>
                                        <hr></hr>
                                        <div className="attachmentUpload">

                                            <input type="file" onChange={handleChangeFile}
                                                accept=".png, .jpg, .jpeg, .pdf"
                                            />
                                            {
                                                file && (
                                                    <div>
                                                    <img src={file}
                                                    className="attachmentUpdateImg"
                                                    alt="sde"
                                                    value={file}
                                                />
                                                {/* <button onClick={removeSelectedImage}>
                                                Remove This Image
                                              </button> */}
                                              </div>
                                                )
                                            }
                                       

                                        </div>
                                    </CardContent>
                                    <CardActions>
                                        <Button variant="contained" type="submit" value="" className="block">Save</Button>
                                    </CardActions>
                                </form>
                            </Card>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Card sx={{ height: 380 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        VAT Certificate
                                    </Typography>
                                    <hr></hr>
                                    <div className="attachmentUpload">

                                        <input type="file" onChange={handleChange} />
                                        <img src={setVatFile}
                                            className="attachmentUpdateImg"
                                        />
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" className="block">Save</Button>
                                </CardActions>
                            </Card>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Card sx={{ height: 380 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Company Reg.Certificate
                                    </Typography>
                                    <hr></hr>
                                    <div className="attachmentUpload">

                                        <input type="file" onChange={handleChange} />
                                        <img src={setRegFile}
                                            className="attachmentUpdateImg"
                                        />
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" className="block">Save</Button>
                                </CardActions>
                            </Card>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Card sx={{ height: 380 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Others
                                    </Typography>
                                    <hr></hr>
                                    <div className="attachmentUpload">

                                        <input type="file" onChange={handleChange} />
                                        <img src={setOthersFile}
                                            className="attachmentUpdateImg"
                                        />
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" className="block">Save</Button>
                                </CardActions>
                            </Card>
                        </div>
                    </div>

                </div>
            </Card>
        </div>
    );
}