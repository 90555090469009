import React, { useEffect, useState } from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setUserDetails } from '../../features/auth/authSlice';

const UserDetails = () => {
    const dispatch = useDispatch()
    const { userDetails } = useSelector(state => state.auth)
    const {register,handleSubmit,formState: { errors },} = useForm();
    const [generalData,setGeneralData] = useState({})
 
    const [formData,setFormData] = useState(userDetails)

    const {usrpContactPerson, usrFirstName,usrMobileNumber,usrEmail,usrEncryptedPassword,usrEncryptedPasswordAlt
    } = formData
    const [isUpdating, setIsUpdating] = useState(false);

    const onSubmit=(data)=> {
        console.log(data);
      }
      
   const handleChange = (e) => {
     setFormData((prevState) =>({
       ...prevState,
       [e.target.name]: e.target.value
     }))
     setIsUpdating(true);
  };

  useEffect(() => {
    if(!isUpdating) {
      // TODO::
    } else {
      dispatch(setUserDetails(formData))
    }
  }, [dispatch, isUpdating, formData]);

    return (
        <div>
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    User Details
                </Typography>
                <Box component="form"   Validate sx={{ mt: 1 }}>
                <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="usrpContactPerson"
                        label="Contact Person"
                        type="text"
                        id="usrpContactPerson"
                        autoComplete="usrpContactPerson"
                        value={usrpContactPerson}
                        onChange={handleChange}
                        // onChange={(e)=>{
                        //     setFormData((prevState) =>({
                        //         ...prevState,
                        //         [e.target.name]:e.target.value,
                        //     }))
                        //     generalData.usrpContactPerson=e.target.value
                        // }}
                        // onBlur={()=>{
                        //     localStorage.setItem(`generalinfoy`,JSON.stringify(generalData))
                        // }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="usrMobileNumber"
                        label="Phone"
                        type="phone-number"
                        id="usrMobileNumber"
                        autoComplete="phone"
                        value={usrMobileNumber}
                        onChange={handleChange}
                        // {...register("phone",{required:"Required field"})}
                        // error={!!errors?.phone}
                        // helperText={errors?.phone ? errors.phone.message :null}
                        // onChange={(e)=>{
                        //     setFormData((prevState) =>({
                        //         ...prevState,
                        //         [e.target.name]:e.target.value,
                        //     }))
                        //     generalData.usrMobileNumber=e.target.value
                            
                        // }}
                        // onBlur={()=>{
                        //     // console.log("fmobile",generalData)
                        //     localStorage.setItem(`generalinfoy`,JSON.stringify(generalData))
                        // }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="usrEmail"
                        label="Email"
                        type="email"
                        id="usrEmail"
                        autoComplete="email"
                        value={usrEmail}
                        onChange={handleChange}
                        // {...register("email", {
                        //     required: "Required field",
                        //     pattern: {
                        //       value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        //       message: "Invalid email address",
                        //     },
                        //   })}
                        //   error={!!errors?.email}
                        //   helperText={errors?.email ? errors.email.message : null}
                        // onChange={(e)=>{
                        //     setFormData((prevState) =>({
                        //         ...prevState,
                        //         [e.target.name]:e.target.value,
                        //     }))
                        //     generalData.usrEmail=e.target.value
                        // }}
                        // onBlur={()=>{
                        //     localStorage.setItem(`generalinfoy`,JSON.stringify(generalData))
                        // }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="usrEncryptedPassword"
                        label="Password"
                        type="password"
                        id="usrEncryptedPassword"
                        autoComplete="Password"
                        value={usrEncryptedPassword}
                        onChange={handleChange}
                        // {...register("Password",{required:"Required field"})}
                        // error={!!errors?.Password}
                        // helperText={errors?.Password ? errors.Password.message :null}
                        // onChange={(e)=>{
                        //     setFormData((prevState) =>({
                        //         ...prevState,
                        //         [e.target.name]:e.target.value,
                        //     }))
                        //     generalData.usrEncryptedPassword=e.target.value
                        // }}
                        // onBlur={()=>{
                        //     localStorage.setItem(`generalinfoy`,JSON.stringify(generalData))
                        // }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="usrEncryptedPasswordAlt"
                        label="Confirm Password"
                        type="password"
                        id="usrEncryptedPasswordAlt"
                        autoComplete="Password"
                        value={usrEncryptedPasswordAlt}
                        onChange={handleChange}
                        // {...register("confirmPassword",{required:"Required field"})}
                        // error={!!errors?.confirmPassword}
                        // helperText={errors?.confirmPassword ? errors.confirmPassword.message :null}
                        // onChange={(e)=>{
                        //     setFormData((prevState) =>({
                        //         ...prevState,
                        //         [e.target.name]:e.target.value,
                        //     }))
                        //     generalData.usrEncryptedPasswordAlt=e.target.value
                        // }}
                        // onBlur={()=>{
                        //     localStorage.setItem(`generalinfoy`,JSON.stringify(generalData))
                        // }}
                    />

                </Box>
            </React.Fragment>
        </div>
    );
};

export default UserDetails;